import { Decimal } from 'decimal.js';

export const calcMultiple = (num1, num2) => {

    let d1 = new Decimal(num1);
    let d2 = new Decimal(num2);

    return d1.mul(d2).toNumber();
}

export const validateAndSetNewFormValue = (key, value, formValue) => {

    //数値だとchkValue.matchでエラーとなるため
    var chkValue = value + ''

    var oldvalue = formValue[key]
    if (oldvalue == null) {
        oldvalue = ''
    }

    var newFormValue = formValue;

    if (chkValue == "" ||
        chkValue.match(/^[0-9]+$/) ||
        chkValue.match(/^[0-9]+\.[0-9]+$/) ||
        chkValue.match(/^[0-9]+\.$/)) {

        //CheckNumが符号の付いていない小数の場合の処理
        newFormValue[key] = value;
    } else {
        //許容しない
        newFormValue[key] = oldvalue;
    }

    return newFormValue

}



import React, { Component } from 'react';

import classNames from 'classnames'
import Grid from '@material-ui/core/Grid';
import { withStyles } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import * as ModelAction from '../../../store/actions/ModelAction'
import { connect } from 'react-redux';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { createMuiTheme } from '@material-ui/core'
import * as DateTool from '../../../util/DateTool';
import * as StrTool from '../../../util/StrTool';
import Pagination from '@material-ui/lab/Pagination';
import CrudModalForUserCreateStipendi from './CrudModalForUserCreateStipendi'
import CrudModalForUserUpdateStipendi from './CrudModalForUserUpdateStipendi'
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';



import { styles } from '../../css/commonstyles'

const modelProps = {
    "id": {
        "type": "string"
    },
    "UserId": {
        "type": "string"
    },
    "StaffId": {
        "type": "string"
    },
    "Dal": {
        "type": "date"
    },
    "Al": {
        "type": "date"
    },
    "hLav": {
        "type": "number"
    },
    "CostoOrario": {
        "type": "number"
    },
    "Stipendio": {
        "type": "number"
    },
    "Acconto": {
        "type": "number"
    },
    "Saldo": {
        "type": "number"
    },
    "Pagato": {
        "type": "string"
    },
    "LastModifiedBy": {
        "type": "string"
    },
    "LastModifiedAt": {
        "type": "date"
    }
}


class CrudForUserStipendi extends Component {

    constructor(props) {
        super(props);



        this.state = null;
        this.state = {
            page: 1,
            numData: 500,
            searchedNum: 0,
            searchword: ''

        };

    }

    componentDidMount() {
        this.props.getModelCount('Stipendi', this.props.logininfo.id)
        this.props.getModelList('Stipendi', this.state.page, this.state.numData, this.props.logininfo.id);
        //this.props.setupWebSocket(this.props.logininfo.id);
    }



    onChangeInputNumData = (e) => {
        e.preventDefault();

        var num = e.target.value;

        var total = this.props.modelList[this.props.modelName + "_count"]

        if (num >= total) {
            num = total;
        } else {
            //全件１ページ表示字のみ検索可
            this.setState({ searchword: '' })

        }

        this.setState({ numData: num });

        if (num == null || num == "") {
            this.props.getModelList('Stipendi', this.state.page, 1, this.props.logininfo.id);
        } else {
            this.props.getModelList('Stipendi', this.state.page, num, this.props.logininfo.id);
        }

    }

    onChangePagination = (page) => {
        this.setState({ page: page });
        this.props.getModelList('Stipendi', page, this.state.numData, this.props.logininfo.id);
    }

    onChangeSearchWord = (value) => {

        var searchword = value.trim();

        this.setState({ searchword: searchword })

        //検索ワードがある場合、全件取得 検索ワードを消した場合も全件取得
        //if (searchword != '') {
        var total = this.props.modelList["Stipendi_count"]
        this.setState({ numData: total });
        this.setState({ page: total });
        this.props.getModelList('Stipendi', 1, total, this.props.logininfo.id);
        //}
    }




    F
    render() {

        var keys = Object.keys(modelProps);

        const { classes } = this.props;

        const nums = [];
        for (let i = 1; i <= 50; i++) {
            nums.push(i);
        }

        return (
            <Grid
                container
                direction="row"
                justify="space-around"
                alignItems="center"
            >
                <Grid item xs={12}  >
                    <Typography variant="h2" component="h2">
                        <Box fontSize="h15.fontsize" m={1} fontStyle="oblique">Stipendi</Box>
                    </Typography>
                </Grid>

                <Grid item xs={12}  >
                    <Grid
                        container
                        direction="row"
                        justify="space-around"
                        alignItems="center"
                    >
                        <Grid item xs={12}  >
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="center"
                            >
                                <Grid item xs={2}  >
                                    <CrudModalForUserCreateStipendi
                                        modelProps={modelProps}
                                        modelName='Stipendi'
                                        page={this.state.page}
                                        numData={this.state.numData}

                                    />
                                </Grid>
                                <Grid item xs={4}  >
                                    <InputLabel htmlFor="input-text">cerca staff...</InputLabel>
                                    <Input
                                        id="input-text"
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        }
                                        onChange={(e) => {
                                            this.onChangeSearchWord(e.target.value)
                                        }}
                                        value={this.state.searchword}
                                        className={
                                            this.state.searchword == ''
                                                ? classNames(classes.formControlM)
                                                : classNames(classes.formControlM, classes.cell_searched)
                                        }
                                    />
                                </Grid>
                                <Grid item xs={6}  >
                                    <Grid
                                        container
                                        direction="row"
                                        justify="flex-end"
                                        alignItems="center"
                                    >
                                        <Pagination
                                            onChange={(e, page) => { e.preventDefault(); this.onChangePagination(page) }}
                                            page={this.state.page}
                                            count={this.props.modelList
                                                ? Math.ceil(this.props.modelList["Stipendi_count"] / this.state.numData)
                                                : 3}
                                        />
                                        <TextField
                                            id="standard-basic"
                                            label="number of datas"
                                            value={this.state.numData}
                                            onChange={(e) => { this.onChangeInputNumData(e) }}
                                        />
                                        <Typography variant="h6" >total:{this.props.modelList["Stipendi_count"]}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} >
                            <div >
                                <TableContainer className={classes.container} >
                                    <Table stickyHeader aria-label="a dense table"  >
                                        <TableHead className={classes.tableHead} >
                                            <TableRow >
                                                <TableCell key={'hd_staff'} align="left" style={{ backgroundColor: '#BDBDBD' }} >Staff</TableCell>
                                                <TableCell key={'hd_Dal'} align="left" style={{ backgroundColor: '#BDBDBD' }} >Dal</TableCell>
                                                <TableCell key={'hd_Al'} align="left" style={{ backgroundColor: '#BDBDBD' }} >Al</TableCell>
                                                <TableCell key={'hd_hLav'} align="left" style={{ backgroundColor: '#BDBDBD' }} >hLav</TableCell>
                                                <TableCell key={'hd_CostoOrario'} align="left" style={{ backgroundColor: '#BDBDBD' }} >CostoOrario</TableCell>
                                                <TableCell key={'hd_Stipendio'} align="left" style={{ backgroundColor: '#BDBDBD' }} >Stipendio</TableCell>
                                                <TableCell key={'hd_Acconto'} align="left" style={{ backgroundColor: '#BDBDBD' }} >Acconto</TableCell>
                                                <TableCell key={'hd_Saldo'} align="left" style={{ backgroundColor: '#BDBDBD' }} >Saldo</TableCell>
                                                <TableCell key={'hd_Pagato'} align="left" style={{ backgroundColor: '#BDBDBD' }} >Pagato</TableCell>
                                                <TableCell align="left" style={{ backgroundColor: '#BDBDBD' }} ></TableCell>
                                                <TableCell align="left" style={{ backgroundColor: '#BDBDBD' }}></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody >
                                            {this.props.modelList['Stipendi'].map((m) => {

                                                var showData = false;

                                                if (this.state.searchword.trim() == '') {
                                                    showData = true;
                                                } else {

                                                    if (m['Staff']['Nome'].toLowerCase().indexOf(this.state.searchword.trim().toLowerCase()) != -1) {
                                                        showData = true;
                                                    }

                                                    if (m['Staff']['Cognome'] != null &&
                                                        m['Staff']['Cognome'].toLowerCase().indexOf(this.state.searchword.trim().toLowerCase()) != -1) {
                                                        showData = true;
                                                    }

                                                    if (m['Staff']['Cognome'] != null &&
                                                        (m['Staff']['Nome'] + ' ' + m['Staff']['Cognome']).toLowerCase().indexOf(this.state.searchword.trim().toLowerCase()) != -1) {
                                                        showData = true;
                                                    }
                                                }


                                                if (showData) {
                                                    return (
                                                        <TableRow key={m.id} className={classes.row}>
                                                            <TableCell
                                                                align="left"
                                                                className={
                                                                    this.state.searchword == ''
                                                                        ? classNames(classes.cell)
                                                                        : classNames(classes.cell, classes.cell_searched)
                                                                }
                                                            >
                                                                {m['Staff']['Nome'] + ' ' + m['Staff']['Cognome']}
                                                            </TableCell>
                                                            <TableCell
                                                                align="left"
                                                                className={classNames(classes.cell)}
                                                            >
                                                                {StrTool.createStrDD_MM_YYYY(m['Dal'])}
                                                            </TableCell>
                                                            <TableCell
                                                                align="left"
                                                                className={classNames(classes.cell)}
                                                            >
                                                                {StrTool.createStrDD_MM_YYYY(m['Al'])}
                                                            </TableCell>
                                                            <TableCell
                                                                align="left"
                                                                className={classNames(classes.cell)}
                                                            >
                                                                {m['hLav']}
                                                            </TableCell>
                                                            <TableCell
                                                                align="left"
                                                                className={classNames(classes.cell)}
                                                            >
                                                                {m['CostoOrario']}
                                                            </TableCell>
                                                            <TableCell
                                                                align="left"
                                                                className={classNames(classes.cell)}
                                                            >
                                                                {m['Stipendio']}
                                                            </TableCell>
                                                            <TableCell
                                                                align="left"
                                                                className={classNames(classes.cell)}
                                                            >
                                                                {m['Acconto']}
                                                            </TableCell>
                                                            <TableCell
                                                                align="left"
                                                                className={classNames(classes.cell)}
                                                            >
                                                                {m['Saldo']}
                                                            </TableCell>
                                                            <TableCell
                                                                align="left"
                                                                className={classNames(classes.cell)}
                                                            >
                                                                {m['Pagato']}
                                                            </TableCell>
                                                            <TableCell
                                                                align="left"
                                                                className={classNames(classes.cell, classes.cell_short,)}
                                                            >
                                                                <CrudModalForUserUpdateStipendi
                                                                    modelProps={modelProps}
                                                                    modelName='Stipendi'
                                                                    modelValue={m}
                                                                    page={this.state.page}
                                                                    numData={this.state.numData} />
                                                            </TableCell>
                                                            <TableCell
                                                                align="right"
                                                                className={classNames(classes.cell, classes.cell_short)}
                                                                onClick={(e) => {
                                                                    e.preventDefault();

                                                                    if (window.confirm("deleting data : "
                                                                        + m['Staff']['Nome'] + ' ' + m['Staff']['Cognome'] + " "
                                                                        + "Dal " +StrTool.createStrDD_MM_YYYY(m['Dal']) + " Al " + StrTool.createStrDD_MM_YYYY(m['Al'])
                                                                        + " stipendio " + m['Stipendio'])) {

                                                                        this.props.submitDelete(
                                                                            this.props.socket,
                                                                            'Stipendi',
                                                                            this.state.page,
                                                                            this.state.numData,
                                                                            m.id,
                                                                            this.props.logininfo.id
                                                                        )
                                                                    }
                                                                }}>
                                                                <DeleteForeverIcon className={classNames(classes.cell_clickable)} />
                                                            </TableCell>
                                                        </TableRow>

                                                    )



                                                } else {

                                                    return null;
                                                }


                                            })}


                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </Grid >
                    </Grid >
                </Grid>

            </Grid >





        );
    }
}


const mapStateToProps = (state) => {
    return {
        modelList: state.model,
        socket: state.webSocket.socket,
        logininfo: state.auth.logininfo

    }
}

const mapDispatchToProps = (dispatch) => {

    return {


        getModelList: (modelName, page, numData, access_token) => {
            dispatch(ModelAction.apiGet_order(modelName, page, numData, ['Staff', 'User'], ['Dal DESC'], access_token))
        },
        getModelCount: (modelName, access_token) => {
            dispatch(ModelAction.apiGetCount(modelName, access_token))
        },
        submitDelete: (socket, modelName, page, numData, id, access_token) => {
            dispatch(ModelAction.apiDelete_arr_order(socket, modelName, page, numData, id, ['Staff', 'User'], ['Dal DESC'], access_token))
        },

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(CrudForUserStipendi));
import React, { Component } from 'react';

import classNames from 'classnames';
import { withStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import SimpleCrud from '../../common/SimpleCrud'




const styles = theme => ({

    root: {
        flexGrow: 1,
        margin: 20
    },
    paper: {
        height: 100,
        width: 800,
        margin: 10,
        padding: 10,
        '&:hover': {
            background: "#CACACA"
        }
    },
    control: {
        padding: theme.spacing(2),
    }
});

const modelProps = {
    "id": {
        "type": "string"
    },
    "Data": {
        "type": "date"
    },
    "UserId": {
        "type": "string"
    },
    "StaffId": {
        "type": "string"
    },
    "Presenza": {
        "type": "string"
    },
    "Ore": {
        "type": "number",
        "mysql": {
            "dataType": "float"
        }
    },
    "CostoOrario": {
        "type": "number",
        "mysql": {
            "dataType": "float"
        }
    },
    "Confermata": {
        "type": "Boolean"
    },
    "Descrizione": {
        "type": "string"
    },
    "LastModifiedBy": {
        "type": "string"
    },
    "LastModifiedAt": {
        "type": "date"
    }
}

class Presenze extends Component {

    constructor(props) {
        super(props);

    }



    render() {

        const { classes } = this.props;

        return (
            <SimpleCrud
                modelName="Presenze"
                modelNameSingolare="Presenze"
                modelProps={modelProps}
            />
        );
    }
}


const mapStateToProps = (state) => {
    return {

    }

}

const mapDispatchToProps = (dispatch) => {

    return {


    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Presenze));
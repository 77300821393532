export const createStrYYYYMMDDHHMMSS = (date) => {

    var d = date;
    var str = "_" + d.getFullYear();
    var arr = [(d.getMonth() + 1), d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds()]

    arr.forEach((e) => {
        if (e < 10) {
            str = str + "0" + e;
        } else {
            str = str + e;
        }
    })
    return str;
}

export const createStrYYYY_MM_DD = (date) => {

    if (null == date) {
        return ""
    } else {
        var d = new Date(date);
        var str = d.getFullYear();
        var arr = [(d.getMonth() + 1), d.getDate()]

        arr.forEach((e) => {
            if (e < 10) {
                str = str + "-0" + e;
            } else {
                str = str + "-" + e;
            }
        })
        return str;
    }


}

export const createStrDD_MM_YYYY = (date) => {

    if (null == date) {
        return ""
    } else {
        var d = new Date(date);
        var str = ""
        var arr = [d.getDate(), (d.getMonth() + 1), d.getFullYear()]

        var first = true
        arr.forEach((e) => {
            if (first) {
                first = false;
            } else {
                str = str + "-"
            }

            if (e < 10) {
                str = str + "0" + e;
            } else {
                str = str + "" + e;
            }
        })
        return str;
    }


}



const defaultState = {
    samples: [],
    Socio: [],
    Pagamento: [],
    SocioMensile: [],
    Prezzi: [],
    Esterno: [],
    Ricevuta: [],
    Staff: [],
    Presenze: [],
    Stipendi: [],
    AnnoAccademico: null
}

export const model = (state = defaultState, action) => {


    switch (action.type) {
        case 'GET_BY_ID_AnnoAccademico':
            return { ...state, AnnoAccademico: action.value.AnnoAccademico }
        case 'GET_ALL_samples':
            return { ...state, samples: action.value }
        case 'GET_ALL_Socio':
            return { ...state, Socio: action.value }
        case 'GET_ALL_Pagamento':
            return { ...state, Pagamento: action.value }
        case 'GET_ALL_Prezzi':
            return { ...state, Prezzi: action.value }
        case 'GET_ALL_Esterno':
            return { ...state, Esterno: action.value }
        case 'GET_ALL_Ricevuta':
            return { ...state, Ricevuta: action.value }
        case 'GET_ALL_SocioMensile':
            return { ...state, SocioMensile: action.value }
        case 'GET_ALL_Staff':
            return { ...state, Staff: action.value }
        case 'GET_ALL_Presenze':
            return { ...state, Presenze: action.value }
        case 'GET_ALL_Stipendi':
            return { ...state, Stipendi: action.value }
        case 'GET_COUNT_Socio':
            return { ...state, Socio_count: action.value }
        case 'GET_COUNT_Pagamento':
            return { ...state, Pagamento_count: action.value }
        case 'GET_COUNT_Prezzi':
            return { ...state, Prezzi_count: action.value }
        case 'GET_COUNT_Esterno':
            return { ...state, Esterno_count: action.value }
        case 'GET_COUNT_Ricevuta':
            return { ...state, Ricevuta_count: action.value }
        case 'GET_COUNT_SocioMensile':
            return { ...state, SocioMensile_count: action.value }
        case 'GET_COUNT_Staff':
            return { ...state, Staff_count: action.value }
        case 'GET_COUNT_Presenze':
            return { ...state, Presenze_count: action.value }
        case 'GET_COUNT_Stipendi':
            return { ...state, Stipendi_count: action.value }
        default: return state
    }
}


import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import { connect } from 'react-redux';
import classNames from 'classnames'
import { createMuiTheme } from '@material-ui/core'
import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';

import SociMensileDashboard from '../common/SociMensileDashboard';

import * as ModelAction from '../../store/actions/ModelAction'
import SociSearcher from '../common/SociSearcher'
import * as SocioMensileAction from '../../store/actions/SocioMensileAction'
import * as PagamentoAction from '../../store/actions/PagamentoAction'
import CircularProgress from '@material-ui/core/CircularProgress';
import PagamentoDetailModal from '../common/PagamentoDetailModal'
import PagamentoSocioSelected from '../common/PagamentoSocioSelected'


const styles = theme => ({

    margin: {
        margin: theme.spacing(1),
    },
    sociList: {
        width: 210
    }



});




class SociMensile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedSocioId: null,
            selectedSocio: null,
            openModal: false,
            selectedPagamentoId: null,
            openPagamentoPage: false,
            tipo: null,
            Importo: null
        };

        this.props.getPrezzi(this.props.logininfo.id);

    }

    componentDidMount() {
        this.props.getSociList(this.props.logininfo.id);
    }


    render() {

        const { classes } = this.props;


        return (

            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
            >
                <Grid xs={2} >
                    <SociSearcher
                        selectedSocioId={this.state.selectedSocioId}
                        onSelect={(id, selectedSocio) => {

                            this.props.getSociById(id, this.props.logininfo.id)
                            this.props.getSociMensiliBySocioIdAnnoAccademico(id, this.props.AnnoAccademico, this.props.logininfo.id)
                            this.props.getPagamentoBySocioId(id, this.props.logininfo.id)
                            this.setState({ selectedSocio: selectedSocio })
                            this.setState({ selectedSocioId: id })
                            this.setState({ openPagamentoPage: false })
                        }}
                    />
                </Grid>

                <Grid xs={10}>

                    {this.state.openPagamentoPage
                        ? <PagamentoSocioSelected
                            tipo={this.state.tipo}
                            selectedSocioId={this.state.selectedSocioId}
                            selectedSocio={this.state.selectedSocio}
                            Importo={this.state.Importo}
                        />
                        : this.state.selectedSocioId && this.state.selectedSocio
                            ? <SociMensileDashboard
                                openPagamentoPage={this.state.openPagamentoPage}
                                socio={this.state.selectedSocio}
                                socioId={this.state.selectedSocio.id}
                                setOpenPagamentoPageTrue={(e, tipo) => {
                                    e.preventDefault();

                                    this.setState({ openPagamentoPage: true });
                                    this.setState({ tipo: tipo });

                                    if (tipo == "Csen") {
                                        console.log("Csen!! tipo,this.state.selectedSocio.AdultoBambino", tipo, this.state.selectedSocio.AdultoBambino)
                                        console.log("this.props.Prezzi", this.props.Prezzi)

                                        this.props.Prezzi.filter((mm) => {


                                            if (mm.TipoDiMensile == tipo &&
                                                mm.AdultoBambino == this.state.selectedSocio.AdultoBambino) {
                                                console.log('set Importo', mm.Importo)
                                                this.setState({ Importo: mm.Importo })
                                            }
                                        })

                                    }

                                }}
                                setOpenModalTrue={(pagamentoId) => {
                                    this.setState({ selectedPagamentoId: pagamentoId })
                                    this.setState({ openModal: true })
                                }}
                            />
                            : <Grid
                                container
                                direction="row"
                                justify="left"
                                alignItems="center"
                                spacing={3}
                            >
                                <Grid xs={6}>
                                    <h1>Seleziona socio</h1>
                                </Grid>
                                <Grid xs={6}>
                                    <h1>Calendario Pagamenti</h1>
                                </Grid>
                            </Grid>




                    }

                </Grid>

                <PagamentoDetailModal
                    socioId={this.state.selectedSocioId}
                    openModal={this.state.openModal}
                    pagamentoId={this.state.selectedPagamentoId}
                    setOpenModalFalse={() => { this.setState({ openModal: false }) }}
                />
            </Grid >
        )
    }

}



const mapStateToProps = (state) => {
    return {
        sociList: state.model.Socio,
        sociCache: state.cache.sociCache,
        sociMensiliCache: state.cache.sociMensiliCache,
        sociPagamentoCache: state.cache.sociPagamentoCache,
        AnnoAccademico: state.model.AnnoAccademico,
        Prezzi: state.model.Prezzi,
        logininfo: state.auth.logininfo
    }
}

const mapDispatchToProps = (dispatch) => {

    return {
        getSociList: (access_token) => {
            //全件取得
            dispatch(ModelAction.apiGet('Socio', 1, 1000, [],access_token))
        },
        getSociById: (id, access_token) => {
            dispatch(ModelAction.apiGetById('Socio', id, access_token))
        },
        getSociMensiliBySocioIdAnnoAccademico: (socioId, annoAccademico, access_token) => {
            dispatch(SocioMensileAction.getSociMensiliBySocioIdAnnoAccademico(socioId, annoAccademico, access_token))
        },
        getPagamentoBySocioId: (socioId, access_token) => {
            dispatch(PagamentoAction.getPagamentoBySocioId(socioId, access_token))
        },
        getPrezzi: (access_token) => {
            //全件取得
            dispatch(ModelAction.apiGet('Prezzi', 1, 1000, [],access_token))
        }


    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(SociMensile));
import React, { Component } from 'react';

import classNames from 'classnames'
import Grid from '@material-ui/core/Grid';
import { withStyles } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import * as WebSocketAction from '../../store/actions/WebSocketAction';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import * as ModelAction from '../../store/actions/ModelAction'
import { connect } from 'react-redux';
import InputJapaneseWithRuby from './InputJapaneseWithRuby';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import RubyText from './RubyText';
import * as Ruby from '../../util/Ruby'
import AddIcon from '@material-ui/icons/Add';
import { createMuiTheme } from '@material-ui/core'
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import * as DateTool from '../../util/DateTool';
import * as StrTool from '../../util/StrTool';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';



const styles = theme => ({

    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    table: {
        minWidth: 650,
    },
    tableHead: {
        background: 'linear-gradient(45deg, #8C8C8C, #BDBDBD)'
    },

    row: {
        '&:hover': {
            background: "#CACACA"
        }
    },
    cell: {
        '&:hover': {
            background: "#b9b9b9"
        },
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalpaper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: 900,
    },
    buttonback: {
        backgroundColor: "#b9b9b9",
        border: '0px solid #000',
        boxShadow: theme.shadows[0],
        padding: theme.spacing(1.5, 1.5, 1.5),

    },
    formControlS: {
        margin: theme.spacing(1),
        width: 120,
    },
    formControlL: {
        margin: theme.spacing(1),
        width: 800
    },
    grammar: {
        width: 800
    },
    grammarPreview: {
        width: 800
    },
    cell_short: {
        fontSize: "10px",
        width: 50,
    },
    cell_medium: {
        fontSize: "10px",
        width: 100,
    },
    cell_clickable: {
        cursor: "pointer"
    },
    gridItem: {
    },
    button: {
        background: 'white',
        borderRadius: 3,
        border: 0,
        color: 'black',
        padding: '0 20px',
        '&:hover': {
            background: "#b9b9b9"
        },
    },
});

const customTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#bfbfbf',
            light: '#969696',
            dark: '#616060',
        },
        secondary: {
            main: '#616060',
        },
    },
})




var defaultFormValue = {};

class ModalDataSocio extends Component {

    constructor(props) {
        super(props);
        this.state = { openModal: false };
    }

    componentDidMount() {

        console.log(this.props.socio);

    

    }


    handlModalOpen = () => {
        this.setState({ openModal: true })
    };

    handleModalClose = () => {
        this.setState({ openModal: false })
    };



    render() {
        const { classes } = this.props;

        return (
            <List>
                <ListItem button onClick={(e) => {
                    e.preventDefault();
                    this.handlModalOpen();
                }}>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        spacing={0}>
                        <Typography variant="h3" gutterBottom>
                            {
                                this.props.socio
                                    ? this.props.socio.NomeSocio + '(' + this.props.socio.AdultoBambino +')'
                                    : null
                            }
                        </Typography>
                        <AccessibilityNewIcon />
                    </Grid>
                </ListItem>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={this.state.openModal}
                    onClose={this.handleModalClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >

                    <Fade in={this.state.openModal}>
                        <div className={classes.modalpaper}>
                            <form className={classes.root} noValidate autoComplete="on">
                                <Grid container justify="left"  >
                                    <Grid item xs={12}  >
                                        <h4 id="transition-modal-title">Data Socio : xxxxxx</h4>
                                    </Grid>
                                </Grid >
                            </form>
                        </div>
                    </Fade>
                </Modal >
            </List>





        );
    }
}


const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {

    return {
      
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(ModalDataSocio));


const defaultState = {
    GDriveFiles: false,
    GDriveFilesNextPageToken: null,
}

export const gdrivefile = (state = defaultState, action) => {

    console.log(action.type);
    switch (action.type) {
        case 'GET_ALL_GDRIVE_FILES':
            return { ...state, GDriveFiles: action.value }

        case 'GET_ALL_GDRIVE_FILES_NEXT_PAGE_TOKEN' :
            
            return { ...state, GDriveFilesNextPageToken: action.value }

        default: return state
    }
}   
import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import { connect } from 'react-redux';
import classNames from 'classnames'
import { createMuiTheme } from '@material-ui/core'
import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import GridMensile from './GridMensile';
import { addMonths } from 'date-fns';
import Typography from '@material-ui/core/Typography';
import ModalDataSocio from './ModalDataSocio'


const styles = theme => ({


});



class SociMensileDashboard extends Component {


    render() {

        const nameOfMonth = ['Ottobre', 'Novembre', 'Dicembre', 'Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio']
        //'agosto', 'settembre'

        return (<div>
            <Container maxWidth="lg">
                <Grid
                    container
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                >

                    <Grid xs={6}>
                        <ModalDataSocio
                            socio={this.props.socio}
                        />
                    </Grid>
                    <Grid xs={6}>
                        <h1>Calendario Pagamenti</h1>
                    </Grid>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justify="left"
                    alignItems="center"
                    spacing={3}
                >





                    {nameOfMonth.map((month) => {

                        var importo = month + 'Importo';
                        var descrizione = month + 'Descrizione';
                        var pagamentoId = month + 'PagamentoId';

                        if (null == this.props.sociMensiliCache[this.props.socioId] ||
                            null == this.props.sociPagamentoCache[this.props.socioId]) {
                            return <GridMensile
                                month={month}
                                setOpenPagamentoPageTrue={(e) => { this.props.setOpenPagamentoPageTrue(e, 'pacchettoSocio') }}
                            />
                        } else {
                            return <GridMensile
                                month={month}
                                importo={this.props.sociMensiliCache[this.props.socioId][importo]}
                                descrizione={this.props.sociMensiliCache[this.props.socioId][descrizione]}
                                pagamentoId={this.props.sociMensiliCache[this.props.socioId][pagamentoId]}
                                pagamento={this.props.sociPagamentoCache[this.props.socioId][this.props.sociMensiliCache[this.props.socioId][pagamentoId]]}
                                setOpenModalTrue={this.props.setOpenModalTrue}
                                setOpenPagamentoPageTrue={(e) => { this.props.setOpenPagamentoPageTrue(e, 'pacchettoSocio') }}
                            />
                        }
                    })}



                    {this.props.sociMensiliCache[this.props.socioId] &&
                        this.props.sociPagamentoCache[this.props.socioId]
                        ? <GridMensile
                            month='QuotaCsen'
                            importo={this.props.sociMensiliCache[this.props.socioId]['QuotaCsenImporto']}
                            descrizione={this.props.sociMensiliCache[this.props.socioId]['QuotaCsenDescrizione']}
                            pagamentoId={this.props.sociMensiliCache[this.props.socioId]['QuotaCsenPagamentoId']}
                            pagamento={this.props.sociPagamentoCache[this.props.socioId][this.props.sociMensiliCache[this.props.socioId]['QuotaCsenPagamentoId']]}
                            setOpenModalTrue={this.props.setOpenModalTrue}
                            setOpenPagamentoPageTrue={(e) => { this.props.setOpenPagamentoPageTrue(e, 'Csen') }}
                        />
                        : <GridMensile
                            month='QuotaCsen'
                            setOpenPagamentoPageTrue={(e) => { this.props.setOpenPagamentoPageTrue(e, 'Csen') }}
                        />
                    }


                    {this.props.sociMensiliCache[this.props.socioId] &&
                        this.props.sociPagamentoCache[this.props.socioId]
                        ? <GridMensile
                            month='QuotaAikikai'
                            importo={this.props.sociMensiliCache[this.props.socioId]['QuotaAikikaiImporto']}
                            descrizione={this.props.sociMensiliCache[this.props.socioId]['QuotaAikikaiDescrizione']}
                            pagamentoId={this.props.sociMensiliCache[this.props.socioId]['QuotaAikikaiPagamentoId']}
                            pagamento={this.props.sociPagamentoCache[this.props.socioId][this.props.sociMensiliCache[this.props.socioId]['QuotaAikikaiPagamentoId']]}
                            setOpenModalTrue={this.props.setOpenModalTrue}
                            setOpenPagamentoPageTrue={(e) => { this.props.setOpenPagamentoPageTrue(e, 'Aikikai') }}
                        />
                        : <GridMensile
                            month='QuotaAikikai'
                            setOpenPagamentoPageTrue={(e) => { this.props.setOpenPagamentoPageTrue(e, 'Aikikai') }}
                        />
                    }
                </Grid>
            </Container>
        </div>)
    }
}



const mapStateToProps = (state) => {
    return {

        sociMensiliCache: state.sociMensiliCache,
        sociPagamentoCache: state.sociPagamentoCache,


    }
}

const mapDispatchToProps = (dispatch) => {

    return {


    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(SociMensileDashboard));
import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import { connect } from 'react-redux';
import classNames from 'classnames'
import { createMuiTheme } from '@material-ui/core'
import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import GridMensile from '../common/GridMensile';
import { addMonths } from 'date-fns';
import Typography from '@material-ui/core/Typography';
import ModalDataSocio from '../common/ModalDataSocio';
import SociMensileDashboard from '../common/SociMensileDashboard';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import * as ModelAction from '../../store/actions/ModelAction'
import * as PagamentoAction from '../../store/actions/PagamentoAction'
import SociSearcher from '../common/SociSearcher'
import * as SocioMensileAction from '../../store/actions/SocioMensileAction'
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
const styles = theme => ({

    margin: {
        margin: theme.spacing(1),
    },
    sociList: {
        width: 210
    },
    th: {
        background: 'linear-gradient(45deg, #D98880, #BDBDBD)'
    },
    cell: {
        borderTop: '1px solid #BDBDBD',
        borderBottom: '1px solid #BDBDBD',
        borderRight: '1px solid #BDBDBD',
        borderLeft: '1px solid #BDBDBD',
    }



});





const columns = [
    'Data di Pagamento',
    'Category',
    'Importo',
    'Nome',
    'Sezione',
    'Descrizione1',
    'Descrizione2',
    'Descrizione3',
    'Data di EmissioneDiRicevuta',
    'RicevutaId',
    'SocioId',
    'EsternoId',

]



class PagamentoList extends Component {

    constructor(props) {
        super(props);

    }

    componentDidMount() {
        this.props.getPagamentoList(this.props.logininfo.id);

    }




    render() {

        const { classes } = this.props;

        return (
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
            >

                <Grid xs={12}>

                    <h1>total : {this.props.PagamentoList ? this.props.PagamentoList.totalAll : null}</h1>

                    {this.props.PagamentoList && this.props.PagamentoList.total
                        ? Object.keys(this.props.PagamentoList.total).map((key) => {
                            return (<h1>total {key} : {this.props.PagamentoList.total[key]} </h1>)
                        })
                        : null}

                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                            <TableHead className={classes.th}>
                                <TableRow>
                                    {columns.map((col) => {
                                        return <TableCell className={classes.cell} align="left">{col}</TableCell>
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.props.PagamentoList.list
                                    ? this.props.PagamentoList.list.length == 0
                                        ? <TableRow>
                                            <TableCell className={classes.cell} align="left">No data</TableCell>
                                        </TableRow>

                                        : this.props.PagamentoList.list.map((obj) => {

                                            //this.calc(obj);

                                            return (<TableRow>
                                                {columns.map((col) => {
                                                    return (<TableCell className={classes.cell} align="left">{obj[col]}</TableCell>)
                                                })}
                                            </TableRow>)
                                        })
                                    : <CircularProgress />
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

            </Grid >
        )
    }

}



const mapStateToProps = (state) => {
    return {
        PagamentoList: state.pagamento.pagamentoList,
        logininfo: state.auth.logininfo
    }
}

const mapDispatchToProps = (dispatch) => {

    return {
        getPagamentoList: (access_token) => {
            dispatch(PagamentoAction.getPagamentoList(access_token))
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(PagamentoList));
import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import { connect } from 'react-redux';
import classNames from 'classnames'
import { createMuiTheme } from '@material-ui/core'
import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import GridMensile from './GridMensile';
import { addMonths } from 'date-fns';
import Typography from '@material-ui/core/Typography';
import ModalDataSocio from './ModalDataSocio';
import SociMensileDashboard from './SociMensileSimple';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

import * as ModelAction from '../../store/actions/ModelAction'



const styles = theme => ({

    margin: {
        margin: theme.spacing(1),
    },
    sociList: {
        width: 210
    }



});




class SociPiuEsternoSearcher extends Component {

    constructor(props) {

        super(props);
        this.state = { searchText: "" };

    }

    componentDidMount() {
        this.props.getSociList(this.props.logininfo.id);
        this.props.getEsternoList(this.props.logininfo.id);
    }

    ////////////////////////// TODO
    selectSocioOrEsterno(e, isSocio, id) {
        e.preventDefault();


        //socioOrEsterno.NomeSocio.toLowerCase().indexOf(this.state.searchText.toLowerCase()) != -1


        var selectedSocio = this.state.selectedSocio;
        var selectedEsterno = this.state.selectedEsterno;

        if (isSocio) {
            this.props.sociList.forEach((socio) => {
                if (socio.id == id) {
                    selectedSocio = socio;
                }
            })
            selectedEsterno = null;

            console.log("selectedEsterno = null;")
        } else {
            this.props.esternoList.forEach((esterno) => {
                if (esterno.id == id) {
                    selectedEsterno = esterno;
                }
            })
            selectedSocio = null;

            console.log("selectedSocio = null;")

        }

        this.props.onSelect(selectedSocio, selectedEsterno);

        //this.setState({ searchText: selectedSocio });

    }


    render() {

        const { classes } = this.props;
        return (
            <div>
                <InputLabel htmlFor="input-text">cerca...</InputLabel>
                <Input
                    id="input-text"
                    startAdornment={
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    }
                    onChange={(e) => { this.setState({ searchText: e.target.value }) }}
                    value={this.state.searchText}
                />
                <List component="nav" >
                    {this.state.searchText == ""
                        ? //検索ワード未入力->全件表示
                        this.props.sociList.concat(this.props.esternoList).map((socioOrEsterno) => {
                            return (
                                <ListItem
                                    className={classNames(classes.sociList)}
                                    button
                                    onClick={(e) => {

                                        var isSocio = false;
                                        if (socioOrEsterno.NomeSocio != null) {
                                            console.log('socioOrEsterno.NomeSocio', socioOrEsterno.NomeSocio)
                                            isSocio = true;
                                        }
                                        if (socioOrEsterno.NomeEsterno != null) {
                                            console.log('socioOrEsterno.NomeEsterno', socioOrEsterno.NomeEsterno)
                                            isSocio = false;
                                        }
                                        this.selectSocioOrEsterno(e, isSocio, socioOrEsterno.id)
                                    }}
                                >
                                    <ListItemText primary={
                                        socioOrEsterno.NomeSocio
                                            ? socioOrEsterno.NomeSocio + "(socio)"
                                            : socioOrEsterno.NomeEsterno + "(esterno)"} />
                                </ListItem>
                            )
                        })
                        : //検索ワード入力あり->sociListとesternoListから検索して表示
                        this.props.sociList.concat(this.props.esternoList).map((socioOrEsterno) => {

                            var property = "";
                            var isSocio = false;
                            var textName= "";

                            if (socioOrEsterno.NomeSocio != null) {

                                property = "NomeSocio";
                                isSocio = true;
                                textName = socioOrEsterno.NomeSocio + "(socio)";
                            }

                            if (socioOrEsterno.NomeEsterno != null) {

                                property = "NomeEsterno";
                                isSocio = false;
                                textName = socioOrEsterno.NomeEsterno + "(esterno)";

                            }



                            if (socioOrEsterno[property].toLowerCase().indexOf(this.state.searchText.toLowerCase()) != -1) {
                                return (
                                    <ListItem
                                        className={classNames(classes.sociList)}
                                        button
                                        onClick={(e) => {
                                            this.selectSocioOrEsterno(e, isSocio, socioOrEsterno.id)
                                        }}
                                    >
                                        <ListItemText primary={textName} />
                                    </ListItem>
                                )
                            }


                        })

                    }
                </List>
            </div>

        )
    }

}



const mapStateToProps = (state) => {
    return {
        sociList: state.model.Socio,
        esternoList: state.model.Esterno,
        //socioPiuEsternoList: state.model.SocioPiuEsterno,
        logininfo: state.auth.logininfo
    }
}

const mapDispatchToProps = (dispatch) => {

    return {
        getSociList: (access_token) => {
            //全件取得
            dispatch(ModelAction.apiGet('Socio', 1, 1000, [], access_token))
        },
        getEsternoList: (access_token) => {
            //全件取得
            dispatch(ModelAction.apiGet('Esterno', 1, 1000, [], access_token))
        }

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(SociPiuEsternoSearcher));
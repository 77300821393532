import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import { connect } from 'react-redux';
import classNames from 'classnames'
import { createMuiTheme } from '@material-ui/core'
import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import GridMensile from '../common/GridMensile';
import { addMonths } from 'date-fns';
import Typography from '@material-ui/core/Typography';
import ModalDataSocio from '../common/ModalDataSocio';
import SociMensileDashboard from '../common/SociMensileDashboard';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import DraftsIcon from '@material-ui/icons/Drafts';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import AccountCircle from '@material-ui/icons/AccountCircle';
import SearchIcon from '@material-ui/icons/Search';
import * as ModelAction from '../../store/actions/ModelAction'
import * as SociAction from '../../store/actions/SociAction'
import SociSearcher from '../common/SociSearcher'
import * as SocioMensileAction from '../../store/actions/SocioMensileAction'
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
const styles = theme => ({

    margin: {
        margin: theme.spacing(1),
    },
    sociList: {
        width: 210
    },
    cell: {
        borderTop: '1px solid #BDBDBD',
        borderBottom: '1px solid #BDBDBD',
        borderRight: '1px solid #BDBDBD',
        borderLeft: '1px solid #BDBDBD',
    },
    cellHead: {
        borderTop: '1px solid #BDBDBD',
        borderBottom: '1px solid #BDBDBD',
        borderRight: '1px solid #BDBDBD',
        borderLeft: '1px solid #BDBDBD',
        background: 'linear-gradient(45deg, #FCF587, #FFF000)'
    },
    container: {
        maxHeight: 600,
      },



});


const columns = [
    "Sezione",
    "Versat in Banca",
    "Nome Socio",
    "Adulto/Bambino",
    "Data di richiesta ammissione socio",
    "Data di accettazione socio",
    "Luogo di Nascita",
    "Data di Nascita",
    "Comune di Residenza",
    "Via",
    "Numero Civico",
    "Codice Fiscale",
    "Numero di Telefono",
    "Quota CSEN",
    "Quota Aikikai",
    "Mensile Ottobre",
    "Mensile Ottobre Data",
    "Mensile Novembre",
    "Mensile Novembre Data",
    "Mensile Dicembre",
    "Mensile Dicembre Data",
    "Mensile Gennaio",
    "Mensile Gennaio Data",
    "Mensile Febbraio",
    "Mensile Febbraio Data",
    "Mensile Marzo",
    "Mensile Marzo Data",
    "Mensile Aprile",
    "Mensile Aprile Data",
    "Mensile Maggio",
    "Mensile Maggio Data",
    "Mensile Giugno",
    "Mensile Giugno Data",
    "Mensile Luglio",
    "Mensile Luglio Data"
]



class ElencoIscritti extends Component {

    constructor(props) {
        super(props);

        this.state = { selectedSocioId: null };

    }

    componentDidMount() {
        this.props.getElencoIscritti(this.props.logininfo.id);

    }


    render() {

        const { classes } = this.props;


        return (

            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
            >
                <Grid xs={12}>
                    <Button
                        variant="outlined"
                        size="medium"
                        className={classes.button}
                        onClick={(e) => {
                            e.preventDefault();
                            this.props.exportExcel(this.props.logininfo.id)
                        }}
                    >Excel</Button>
                </Grid>
                <Grid xs={12}>
                    <TableContainer className={classes.container}>
                        <Table stickyHeader size="small" aria-label="a dense table">
                            <TableHead className={classes.tableHead}>
                                <TableRow>
                                    {columns.map((col) => {
                                        return <TableCell className={classes.cellHead} align="left">{col}</TableCell>
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.props.ElencoIscritti
                                    ? this.props.ElencoIscritti.length == 0
                                        ? <TableRow>
                                            <TableCell className={classes.cell} align="left">No data</TableCell>
                                        </TableRow>

                                        : this.props.ElencoIscritti.map((obj) => {

                                            console.log('obj=>', obj);

                                            return (<TableRow>
                                                {columns.map((col) => {
                                                    return (<TableCell className={classes.cell} align="left">{obj[col]}</TableCell>)
                                                })}
                                            </TableRow>)
                                        })
                                    : <CircularProgress />
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

            </Grid >
        )
    }

}



const mapStateToProps = (state) => {
    return {
        ElencoIscritti: state.soci.ElencoIscritti,
        logininfo: state.auth.logininfo
    }
}

const mapDispatchToProps = (dispatch) => {

    return {
        getElencoIscritti: (access_token) => {
            dispatch(SociAction.getElencoIscritti(access_token))
        },
        exportExcel: (access_token) => {
            dispatch(SociAction.apiExportExcelElencoIscritti(access_token))
        },
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(ElencoIscritti));
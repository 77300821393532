

const defaultState = {
    ElencoIscritti: false
}

export const soci = (state = defaultState, action) => {

    console.log(action.type);
    switch (action.type) {
        case 'GET_ELENCOISCRITTI':
            return { ...state, ElencoIscritti: action.value }

        default: return state
    }
}   
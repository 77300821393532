import React, { Component } from 'react'
import { DropzoneArea } from 'material-ui-dropzone'
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';


import {DropzoneDialog} from 'material-ui-dropzone'

import { withStyles } from "@material-ui/core/styles";


const styles = theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalpaper: {
    background: "white",
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: 500,
    height: 330,
  },
  button: {
    background: 'white',
    borderRadius: 3,
    border: 0,
    color: 'black',
    padding: '0 30px',
    '&:hover': {
      background: "#b9b9b9"
    },
  },
  buttonImport: {
    background: "#b9b9b9",
    borderRadius: 3,
    border: 0,
    color: 'black',
    padding: '0 30px',
    '&:hover': {
      background: "white"
    },
  },

});



class DropzoneAreaModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
      open: false,
      openModal: false,
      importDisabled: true,
      files: []
    };
  }
  handleChange(files) {
    this.setState({
      files: files
    });
    if (files.length == 1) {
      this.setState({
        importDisabled: false
      });
    } else {
      this.setState({
        importDisabled: true
      });
    }
  }

  handleSave(files) {
    //Saving files to state for further use and closing Modal.
    this.setState({
        files: files,
        open: false
    });
}

handleClose() {
  this.setState({
      open: false
  });
}



  render() {

    const handleOpen = () => {
      this.setState({ openModal: true, });
    };

    const handleClose = () => {
      this.setState({ openModal: false, });
    };





    const { classes } = this.props;

    return (
      <div>
        <Button
          variant="outlined"
          size="medium"
          className={classes.button}
          onClick={handleOpen}
        >{this.props.buttonName}</Button>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={this.state.openModal}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.openModal}>

            <Grid container className={classes.modalpaper}>
              <Grid item xs={12}>
                <DropzoneArea
                  onChange={this.handleChange.bind(this)}
                  //acceptedFiles={['application/json']}
                  filesLimit={1}
                  dropzoneText="Drag & drop an file here or click"
                  showFileNamesInPreview={true}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                >
                  <Button
                    variant="outlined"
                    size="medium"
                    className={classes.buttonImport}
                    disabled={this.state.importDisabled}
                    onClick={(e) => {
                      e.preventDefault();
                      // 0 番目の File オブジェクトを取得
                      var file = this.state.files[0];
                      if (!file) return;
                      this.props.importAction(this.props.socket, this.props.modelName, file, this.props.access_token)
                    }}
                  >Import(keep ids)</Button>

                </Grid>
              </Grid>

            </Grid>
          </Fade>
        </Modal>
      </div>
    );
  }


}

export default (withStyles(styles, { withTheme: true }))(DropzoneAreaModal);
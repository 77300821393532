

const defaultState = {
    ricevuta: false
}

export const ricevuta = (state = defaultState, action) => {

    console.log(action.type);
    switch (action.type) {
    
        default: return state
    }
}   
import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import { connect } from 'react-redux';
import classNames from 'classnames'
import { createMuiTheme } from '@material-ui/core'
import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import GridMensile from './GridMensile';
import { addMonths } from 'date-fns';
import Typography from '@material-ui/core/Typography';
import ModalDataSocio from './ModalDataSocio'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';

import * as ModelAction from '../../store/actions/ModelAction'
import TextField from '@material-ui/core/TextField';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import * as PagamentoAction from '../../store/actions/PagamentoAction'
import * as DateTool from '../../util/DateTool'


const styles = theme => ({

    descrizione: { width: 310 },
    card: { width: 350, height: 630, padding: 10 },
    cardInner: { width: 330, height: 480, padding: 10 },
    buttonPagato: { width: 310, height: 50 }


});



class PagamentoAltro extends Component {

    constructor(props) {
        super(props)
        this.state = this.props.value;
    }

    handleChangeImporto = (e) => {
        e.preventDefault();
        //数値以外を不可とする
        this.props.setState('Importo', e.target.value)
    }

    handleDateChangeDataDiPagamento = (date) => {
        this.props.setState('DataDiPagamento', date)
    }






    handleChangeMetodoDiPagamento = (e) => {
        e.preventDefault();
        this.props.setState('MetodoDiPagamento', e.target.value)

    }

    handleChangeDescrizione1 = (e) => {
        e.preventDefault();
        this.props.setState('Descrizione1', e.target.value)
    }
    handleChangeDescrizione2 = (e) => {
        e.preventDefault();
        this.props.setState('Descrizione2', e.target.value)
    }
    handleChangeDescrizione3 = (e) => {
        e.preventDefault();
        this.props.setState('Descrizione3', e.target.value)
    }


    render() {
        const { classes } = this.props;



        return (
            <div>
                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                >

                    <Grid xs={10}>

                        <Grid
                            container
                            direction="column"
                            justify="flex-start"
                            alignItems="flex-start"
                        >
                            <h1>{this.props.isSocio ? this.props.socio.NomeSocio : this.props.esterno.NomeEsterno}</h1>

                            <Card className={classes.card}>
                                <Grid
                                    container
                                    direction="column"
                                    justify="space-between"
                                    alignItems="center"
                                >

                                    <Grid
                                        container
                                        className={classes.cardInner}
                                    >

                                        <Grid xs={12} >
                                            <Typography variant="h5" component="h2">{this.props.isSocio ? this.props.socio.NomeSocio + "(socio)" + this.props.socio.AdultoBambino : this.props.esterno.NomeEsterno + "(esterno)"}</Typography>
                                        </Grid>
                                        <Grid xs={12} >
                                            <TextField
                                                required
                                                id="filled-required"
                                                label="Importo(€)"
                                                defaultValue=""
                                                variant="filled"
                                                value={this.props.value.Importo}
                                                onChange={this.handleChangeImporto}
                                            />
                                        </Grid>

                                        <Grid xs={12} >


                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <Grid container justify="space-around">
                                                    <KeyboardDatePicker
                                                        disableToolbar
                                                        variant="inline"
                                                        format="MM/dd/yyyy"
                                                        margin="normal"
                                                        id="DataDiPagamento"
                                                        label="Data Di Pagamento"
                                                        value={this.props.value.DataDiPagamento}
                                                        onChange={this.handleDateChangeDataDiPagamento}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                    />

                                                </Grid>
                                            </MuiPickersUtilsProvider>


                                        </Grid>
                                        <Grid xs={12} >
                                            <FormControl component="fieldset">
                                                <FormLabel component="legend">Metodo</FormLabel>
                                                <RadioGroup
                                                    aria-label="Metodo"
                                                    name="MetodoDiPagamento"
                                                    value={this.props.value.MetodoDiPagamento}
                                                    onChange={this.handleChangeMetodoDiPagamento}>
                                                    <FormControlLabel value="Contanti" control={<Radio />} label="Contanti" />
                                                    <FormControlLabel value="Bonifico" control={<Radio />} label="Bonifico" />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                        <Grid xs={12} >
                                            <TextField className={classes.descrizione}
                                                id="Descrizione1"
                                                label="Descrizione1"
                                                multiline
                                                rowsMax={10}
                                                value={this.props.value.Descrizione1}
                                                onChange={this.handleChangeDescrizione1}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid xs={12} >
                                            <TextField className={classes.descrizione}
                                                id="Descrizione2"
                                                label="Descrizione2"
                                                multiline
                                                rowsMax={10}
                                                value={this.props.value.Descrizione2}
                                                onChange={this.handleChangeDescrizione2}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid xs={12} >
                                            <TextField className={classes.descrizione}
                                                id="Descrizione3"
                                                label="Descrizione3"
                                                multiline
                                                rowsMax={10}
                                                value={this.props.value.Descrizione3}
                                                onChange={this.handleChangeDescrizione3}
                                                variant="outlined"
                                            />
                                        </Grid>
                                    </Grid>
                                    <ArrowDownwardIcon />
                                    <Button
                                        disabled={this.props.value.Importo && this.props.value.DataDiPagamento && this.props.value.MetodoDiPagamento ? false : true}
                                        className={classes.buttonPagato}
                                        variant="contained"
                                        color="secondary"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.props.createTransactionAltro(
                                                this.props.isSocio ? this.props.socio.id : null,
                                                this.props.isEsterno ? this.props.esterno.id : null,
                                                this.props.AnnoAccademico,
                                                this.props.value.Importo,
                                                DateTool.createJSONDateWithoutTime(this.props.value.DataDiPagamento),
                                                this.props.value.MetodoDiPagamento,
                                                this.props.value.Descrizione1,
                                                this.props.value.Descrizione2,
                                                this.props.value.Descrizione3, 
                                                this.props.value.tipo, //TODO
                                                this.props.value.Pacchetto,
                                                this.props.setPagamentoId,
                                                this.props.setOpenModalTrue,
                                                this.props.getPagamentoById,
                                                this.props.logininfo.id
                                            );
                                        }}>Archiviazione</Button>
                                </Grid>
                            </Card>


                        </Grid>

                    </Grid>


                </Grid>


            </div>



        )
    }

}



const mapStateToProps = (state) => {
    return {
        Prezzi: state.model.Prezzi,
        logininfo: state.auth.logininfo,
        AnnoAccademico: state.model.AnnoAccademico,
    }
}

const mapDispatchToProps = (dispatch) => {

    return {

        createTransactionAltro: (
            SocioId,
            EsternoId,
            AnnoAccademico,
            Importo,
            DataDiPagamento,
            MetodoDiPagamento,
            Descrizione1,
            Descrizione2,
            Descrizione3,
            tipo,
            pacchetto,
            setPagamentoId,
            setOpenModalTrue,
            getPagamentoById,
            access_token) => {
            dispatch(PagamentoAction.createTransactionAltro(
                SocioId,
                EsternoId,
                AnnoAccademico,
                Importo,
                DataDiPagamento,
                MetodoDiPagamento,
                Descrizione1,
                Descrizione2,
                Descrizione3,
                tipo,
                pacchetto,
                setPagamentoId,
                setOpenModalTrue,
                getPagamentoById,
                access_token))
        }

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(PagamentoAltro));
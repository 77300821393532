

const defaultState = {
    pagamentoList : {},
    transactionComplete: false
}

export const pagamento = (state = defaultState, action) => {

    console.log(action.type);
    switch (action.type) {
        case 'PAGAMENTO_COMPLETE':
            return { ...state, transactionComplete: true }

        case 'PAGAMENTO_COMPLETE_MESSAGECLOSE':
            return { ...state, transactionComplete: false }

        case 'GET_PAGAMENTOLIST' :
            return  { ...state, pagamentoList: action.value }

        default: return state
    }
}   
import * as StrTool from './StrTool'
import { format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { zonedTimeToUtc } from 'date-fns-tz'


export const returnItalianTime = () => {

    const utcDate = Date.now();
    const italianDate = utcToZonedTime(utcDate, 'Europe/Rome')
    const italianString = format(italianDate, 'yyyy-MM-dd HH:mm:ss')
    console.log('italianString', italianString + '.000Z');

    return italianString + '.000Z'

}

export const returnItalianDate = () => {

    //2023-09-11T00:00:00.000Z

    const utcDate = Date.now();
    const italianDate = utcToZonedTime(utcDate, 'Europe/Rome')
    const italianString = format(italianDate, 'yyyy-MM-dd')
    console.log('italianString', italianString + 'T00:00:00.000Z');

    return italianString + 'T00:00:00.000Z'

}


export const returnItalianTimeFirstDayOfMonth = () => {

    //2023-09-09T00:00:00.000Z

    const utcDate = Date.now();
    const italianDate = utcToZonedTime(utcDate, 'Europe/Rome')
    const italianString = format(italianDate, 'yyyy-MM-dd HH:mm:ss')
    return italianString.substring(0, 8) + '01T00:00:00.000Z';

}


export const returnFirstDayOfNextMonth = (firstDayOfThisMonth) => {

    //2023-09-09T00:00:00.000Z

    //2023-09-01T00:00:00.000Z という文字列から　年　月　を切り出しUTC時刻のオブジェクトにする
    const firstDayOfThisMonthUTCObject = new Date(Date.UTC(firstDayOfThisMonth.substring(0, 4), parseInt(firstDayOfThisMonth.substring(5, 7), 10) - 1, 1, 0, 0, 0));

    //翌月月初
    firstDayOfThisMonthUTCObject.setMonth(firstDayOfThisMonthUTCObject.getMonth() + 1);
    const firstDayOfNextMonthUTCObject = firstDayOfThisMonthUTCObject

    //2023-09-09T00:00:00.000Zという文字列に整形する
    const year = firstDayOfNextMonthUTCObject.getFullYear(); // 1e4 gives us the the other digits to be filled later, so 20210000.
    const monthbeforepadding = '0' + (firstDayOfNextMonthUTCObject.getMonth() + 1);
    const month = monthbeforepadding.substring(monthbeforepadding.length - 2)
    const result = year + '-' + month + '-01T00:00:00.000Z'

    console.log('returnFirstDayOfNextMonth', result)


    return result;

}

export const returnFirstDayOfLastMonth = (firstDayOfThisMonth) => {

    //2023-09-09T00:00:00.000Z

    //2023-09-01T00:00:00.000Z という文字列から　年　月　を切り出しUTC時刻のオブジェクトにする
    const firstDayOfThisMonthUTCObject = new Date(Date.UTC(firstDayOfThisMonth.substring(0, 4), parseInt(firstDayOfThisMonth.substring(5, 7), 10) - 1, 1, 0, 0, 0));

    //先月月初
    firstDayOfThisMonthUTCObject.setMonth(firstDayOfThisMonthUTCObject.getMonth() - 1);
    const firstDayOfLastMonthUTCObject = firstDayOfThisMonthUTCObject

    //2023-09-09T00:00:00.000Zという文字列に整形する
    const year = firstDayOfLastMonthUTCObject.getFullYear(); // 1e4 gives us the the other digits to be filled later, so 20210000.
    const monthbeforepadding = '0' + (firstDayOfLastMonthUTCObject.getMonth() + 1);
    const month = monthbeforepadding.substring(monthbeforepadding.length - 2)
    const result = year + '-' + month + '-01T00:00:00.000Z'

    console.log('returnFirstDayOfLastMonth', result)


    return result;

}



export const convertMonth = (num) => {


    var month = '';

    switch (num) {
        case 1:
            month = 'Gennaio';
            break;
        case 2:
            month = 'Febbraio';
            break;
        case 3:
            month = 'Marzo';
            break;
        case 4:
            month = 'Aprile';
            break;
        case 5:
            month = 'Maggio';
            break;
        case 6:
            month = 'Giugno';
            break;
        case 7:
            month = 'Luglio';
            break;
        case 8:
            month = 'Agosto';
            break;
        case 9:
            month = 'Settembre';
            break;
        case 10:
            month = 'Ottobre';
            break;
        case 11:
            month = 'Novembre';
            break;
        case 12:
            month = 'Dicembre';
            break;

    }
    return month;
}

export const createDateStringFromJSONDate = (datetime) => {

    if (null == datetime) {
        return "";
    } else {
        return new Date(JSON.parse(datetime));
    }


}

export const createJSONDateWithoutTime = (datetime) => {
    if (datetime == null) {
        return ''
    } else {

        datetime.setHours(0);
        datetime.setMinutes(0);
        datetime.setSeconds(0);
        datetime.setMilliseconds(0);

        console.log(datetime);

        //date: "2020-09-10T09:33:52.007Z"
        return new Date(datetime.getTime() - (datetime.getTimezoneOffset() * 60000)).toJSON();
    }



}

export const createDateStringWithoutTimeFromJSONDate = (datetime) => {

    console.log('datetime1', datetime);



    if (datetime == "" || datetime == null) {
        return datetime
    } else {
        return StrTool.createStrDD_MM_YYYY(datetime);
    }


}
import React, { Component } from 'react';
import RubyText from './RubyText';
import * as Ruby from '../../util/Ruby'
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';


const styles = theme => ({
    root: {

    },
    formControlS: {
        margin: theme.spacing(1),
        width: 100,
    },
    formControlL: {
        margin: theme.spacing(1),
        width: 800
    },
    grammar: {
        width: 800
    },
    grammarPreview: {
        width: 800
    }
});


class InputJapaneseWithRuby extends Component {

    constructor(props) {
        super(props);

        this.state = {
            japanese: this.props.value,
            rubyArr: Ruby.createRubyArray(this.props.value)
        };
        this.onChangeInputJapanese = this.onChangeInputJapanese.bind(this);

    }

    onChangeInputJapanese = (e) => {

        this.setState({
            japanese: e.target.value,
            rubyArr: Ruby.createRubyArray(e.target.value)
        })
    }

    render() {
        const { classes } = this.props;

        return (
            <div>
                <Grid container >
                    <Grid item xs={12}>
                        <FormControl className={classes.formControlL}>
                            <RubyText rubyArr={this.state.rubyArr} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl className={classes.formControlL}>
                            <TextField
                                id="outlined-basic"
                                label={this.props.label}
                                variant="outlined"
                                value={this.state.japanese}
                                onChange={(e) => {
                                    this.onChangeInputJapanese(e);
                                    this.props.onChange(e)
                                }} />
                        </FormControl>
                    </Grid>
                </Grid>



            </div>
        )
    }


}


export default withStyles(styles, { withTheme: true })(InputJapaneseWithRuby);
import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import { connect } from 'react-redux';
import classNames from 'classnames'
import { createMuiTheme } from '@material-ui/core'
import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import GridMensile from './GridMensile';
import { addMonths } from 'date-fns';
import Typography from '@material-ui/core/Typography';
import ModalDataSocio from './ModalDataSocio'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';

import * as ModelAction from '../../store/actions/ModelAction'
import TextField from '@material-ui/core/TextField';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import * as PagamentoAction from '../../store/actions/PagamentoAction'
import * as DateTool from '../../util/DateTool'
import { SignalCellularNull } from '@material-ui/icons';


const styles = theme => ({

    descrizione: { width: 310 },
    card: { width: 350, height: 630, padding: 10 },
    cardInner: { width: 330, height: 480, padding: 10 },
    buttonPagato: { width: 310, height: 50 }


});



const nameOfMonth = ['Ottobre', 'Novembre', 'Dicembre', 'Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio']
//'agosto', 'settembre'

//const corsi = ['Aikido', 'MuayThai', 'MuayThai Fitness', 'BJJ', 'Lotta', 'Ginnastica Posturale'];
const corsi = ['Aikido', 'MuayThai', 'MuayThai Fitness', 'BJJ'];
const corsiGinnastica = ['Yoga', 'Pilates', 'Ginnastica Posturale'];
const corsiFitness = ['Fitness Musicale'];
const corsiGinnasticaFitness = ['Yoga', 'Pilates', 'Ginnastica Posturale', 'Fitness Musicale']

class SociMensileSimple extends Component {

    constructor(props) {//
        super(props)
        this.state = { numMonth: 0 };
        this.state = { numCorso: 0 };
    }

    handleDateChangeDataDiPagamento = (date) => {
        this.props.setState('DataDiPagamento', date)



        if (this.props.value.Pacchetto == 'DifesaFemminile') {

            var monthToPay = this.props.value.monthToPay.slice();
            monthToPay.length = 0; //すでにある配列を空にする場合は、「length プロパティを0にする」方法があります。
            //今日の月
            var today = date.getMonth();  //0はじまり
            var i = 0;

            console.log('date', today);

            if (today == 9 || today == 10 || today == 11) {
                i = today - 9;
            } else if (today == 7 || today == 8) { //月謝月以外は6月に含める
                i = 9
            } else {
                i = today + 3;
            }

            console.log('nameOfMonth[i]', nameOfMonth[i]);
            monthToPay.push(nameOfMonth[i]);

            this.props.setState('monthToPay', monthToPay)
        }


    }

    handleChangePacchetto = (e) => {

        e.preventDefault();

        var pacchetto = e.target.value;
        this.props.setState('Pacchetto', pacchetto)
        this.props.setState('Contratto', null)
        this.props.setState('tipoDiMensile', null)
        this.props.setState('allSelected', false)

        //選択済コースの初期化
        var corsiAll = corsi.concat(corsiGinnastica).concat(corsiFitness)

        corsiAll.forEach((c) => {
            this.handleChangeCheckCorso(false, c + 'Checked', c)

        })



        var monthToPay = this.props.value.monthToPay.slice();
        monthToPay.length = 0;
        this.props.setState('monthToPay', monthToPay)

        nameOfMonth.map((month) => {
            var nameChecked = month + 'Checked';

            this.props.setState(nameChecked, false)
        })






        if (pacchetto == 'DifesaFemminile') {

            var monthToPay = this.props.value.monthToPay.slice();
            monthToPay.length = 0; //すでにある配列を空にする場合は、「length プロパティを0にする」方法があります。
            //今日の月
            var today = new Date().getMonth();  //0はじまり
            var i = 0;

            console.log('today', today);

            if (today == 9 || today == 10 || today == 11) {
                i = today - 9;
            } else if (today == 7 || today == 8) { //月謝月以外は6月に含める
                i = 9
            } else {
                i = today + 3;
            }

            console.log('nameOfMonth[i]', nameOfMonth[i]);
            monthToPay.push(nameOfMonth[i]);

            this.props.setState('monthToPay', monthToPay)
        }







        var numCorso = 0;
        switch (pacchetto) {
            case 'Singolo': numCorso = 1; break;
            case 'Doppio': numCorso = 2; break;
            case 'Open': numCorso = 3; break;
            default: numCorso = 0;
        }

        this.setState({ numCorso: numCorso });

        console.log('handleChangePacchetto this.props.value.tipoDiMensile;', this.props.value.tipoDiMensile)
        var numMonth = 0;
        switch (this.props.value.tipoDiMensile) {
            case "Annuale"
                : if (pacchetto == 'Unico') {
                    //Reggioは9か月
                    numMonth = 9; break;
                } else if (pacchetto == 'Rimini' || pacchetto == 'SanVito') {
                    //Riminiは9か月
                    numMonth = 9; break;
                } else {
                    numMonth = 10; break;
                }
            case "Semestrale": numMonth = 6; break;
            case "Trimestrale": numMonth = 3; break;
            case "Mensile": numMonth = 1; break;
        }

        this.setState({ numMonth: numMonth });

        //this.calcImporto(e.target.value, this.props.value.tipoDiMensile, this.props.value.monthToPay, numMonth)

        if (pacchetto == 'DifesaFemminile') {
            this.props.setState('allSelected', true); //DifesaFemminileの場合は月の選択チェックをしない
        } else {
            //console.log('elseDifesaFemminile')
            //this.checkMonthChecked(e.target.value, this.props.value.Corsi, numCorso, this.props.value.tipoDiMensile, this.props.value.monthToPay, this.state.numMonth);

            //console.log(this.props.value.allSelected);
        }

        this.createDescrizione2(e.target.value);

        this.checkMonthChecked(this.props.value.Pacchetto, null, monthToPay, numMonth);
 




    }

    handleChangeContratto = (e) => {

        console.log('handleChangeContratto e.target.value;', e.target.value)
        this.props.setState('Contratto', e.target.value);

        e.preventDefault();
    }


    handleChangeTipoDiMensile = (e) => {
        e.preventDefault();

        console.log('handleChangeTipoDiMensile e.target.value;', e.target.value)

        var tipoDiMensile = e.target.value;
        var numMonth = 0;
        switch (tipoDiMensile) {
            case "Annuale"
                : if (this.props.value["Pacchetto"] == 'Unico') {
                    //Reggioは9か月
                    numMonth = 9; break;
                } else if (this.props.value["Pacchetto"] == 'Rimini' || this.props.value["Pacchetto"] == 'SanVito') {
                    //Riminiは9か月
                    numMonth = 9; break;
                } else {
                    numMonth = 10; break;
                }
            case "Semestrale": numMonth = 6; break;
            case "Trimestrale": numMonth = 3; break;
            case "Mensile": numMonth = 1; break;
        }

        this.props.setState('tipoDiMensile', e.target.value);
        this.setState({ numMonth: numMonth });

        this.createDescrizione1(e.target.value, this.props.value.monthToPay);
        this.createDescrizione2(this.props.value.Pacchetto);
        this.checkMonthChecked(this.props.value.Pacchetto, e.target.value, this.props.value.monthToPay, numMonth);
        this.calcImporto(this.props.value.Pacchetto, e.target.value, this.props.value.monthToPay, numMonth)
    }

    handleChangeMonth = (e, month) => {
        var checked = e.target.checked;
        this.props.setState([e.target.name], checked)
        var monthToPay = this.props.value.monthToPay.slice();
        if (checked) {
            monthToPay.push(month)
        } else {
            monthToPay = monthToPay.filter(m => m !== month)
        }
        this.props.setState('monthToPay', monthToPay)
        this.createDescrizione1(this.props.value.tipoDiMensile, monthToPay);
        this.createDescrizione2(this.props.value.Pacchetto);
        this.checkMonthChecked(this.props.value.Pacchetto, this.props.value.tipoDiMensile, monthToPay, this.state.numMonth);
        this.calcImporto(this.props.value.Pacchetto, this.props.value.tipoDiMensile, monthToPay, this.state.numMonth)
    }


    

    handleChangeCheckCorso = (checked, name, corso) => {
        this.props.setState(name, checked)
        /*
                var Corsi = this.props.value.Corsi.slice();
                if (checked) {
                    Corsi.push(corso)
                } else {
                    Corsi = Corsi.filter(c => c !== corso)
                }
                this.props.setState('Corsi', Corsi)
                */

        this.createDescrizione2(this.props.value.Pacchetto);
        this.checkMonthChecked(this.props.value.Pacchetto, this.props.value.tipoDiMensile, this.props.value.monthToPay, this.state.numMonth);
    }

    checkMonthChecked = (pacchetto, tipoDiMensile, monthToPay, numMonth) => {

        if (pacchetto != null) {

            if (tipoDiMensile != null) {
                if (tipoDiMensile == "Annuale" ||
                    tipoDiMensile == "Semestrale") {
                    console.log('判定２')

                    if (monthToPay.length > 0) {
                        this.props.setState('allSelected', true)
                    } else {
                        this.props.setState('allSelected', false)
                    }
                } else if (tipoDiMensile == "Trimestrale" || tipoDiMensile == "Mensile") {

                    if (monthToPay.length == numMonth) {

                        this.props.setState('allSelected', true)

                    } else {
                        this.props.setState('allSelected', false)
                    }
                }
            } else {
                this.props.setState('allSelected', false)
            }
        } else {
            console.log('判定5')
            this.props.setState('allSelected', false)
        }





    }

    createDescrizione1 = (tipoDiMensile, monthToPay) => {

        var AdultoBambino = "";

        switch (this.props.socio.AdultoBambino) {

            case "A": AdultoBambino = "Adulto"; break;
            case "R": AdultoBambino = "Ragazzo"; break;
            case "B": AdultoBambino = "Bambino"; break;

        }



        var descrizione1 = "Quota " + tipoDiMensile


        var descrizione1 = descrizione1 + '(';

        nameOfMonth.forEach((monthName) => {

            monthToPay.filter((m) => {
                if (m == monthName) {
                    //descrizione = descrizione + monthName + '\n';
                    descrizione1 = descrizione1 + monthName + '/';
                }
            })
        })

        descrizione1 = descrizione1.slice(0, descrizione1.length - 1);

        if (monthToPay.length != 0) {
            descrizione1 = descrizione1 + ')';
        }

        descrizione1 = descrizione1 + ' ' + AdultoBambino

        this.props.setState('Descrizione1', descrizione1)
    }

    createDescrizione2 = (Pacchetto) => {

        var corsiAll = corsi.concat(corsiGinnastica).concat(corsiFitness)

        console.log('createDescrizione2*******')

        var descrizione2 = Pacchetto + '(';

        var exist = false
        corsiAll.forEach((corso) => {

            if (this.props.value[corso + 'Checked']) {
                descrizione2 = descrizione2 + corso + '/';
                exist = true
            }

            /* CorsiArr.filter((c) => {
 
 
                 if (c == corso) {
                     //descrizione = descrizione + monthName + '\n';
                     descrizione2 = descrizione2 + corso + '/';
                 }
             })*/
        })

        descrizione2 = descrizione2.slice(0, descrizione2.length - 1);

        if (exist) {
            descrizione2 = descrizione2 + ')';
        }
        this.props.setState('Descrizione2', descrizione2)

        console.log(descrizione2)
        console.log('****************************')


    }

    calcImporto = (pacchetto, tipoDiMensile, monthToPay, numMonth) => {
        console.log('numMonth', numMonth);

        this.props.setState('Importo', 'NODATA')

        var total = 0;
        var ImportoAlMese = 0;

        this.props.Prezzi.filter((mm) => {


            if ((mm.TipoDiMensile == null || mm.TipoDiMensile == tipoDiMensile) &&  //チケットの場合はnull
                mm.Pacchetto == pacchetto &&
                mm.AdultoBambino == this.props.socio.AdultoBambino) {


                console.log('一致')
                total = mm.Importo;

                if (numMonth == 0) {
                    ImportoAlMese = total;
                } else {
                    ImportoAlMese = total / numMonth;
                }


                this.props.setState('ImportoAlMese', ImportoAlMese);

                if (tipoDiMensile == 'Annuale' || tipoDiMensile == 'Semestrale') {
                    console.log('かけ算', ImportoAlMese * monthToPay.length)
                    this.props.setState('Importo', ImportoAlMese * monthToPay.length);
                } else if (tipoDiMensile == 'Trimestrale' || tipoDiMensile == 'Mensile') {
                    this.props.setState('Importo', mm.Importo);
                } else if (tipoDiMensile == null) { //チケットの場合はnull
                    this.props.setState('Importo', mm.Importo);
                }
            }
        })
    }

    handleChangeMetodoDiPagamento = (e) => {
        this.props.setState('MetodoDiPagamento', e.target.value)

    }

    handleChangeDescrizione3 = (e) => {
        this.props.setState('Descrizione3', e.target.value)
    }


    render() {
        const { classes } = this.props;



        return (

            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
            >
                <Grid xs={12}><h1>{this.props.socio.NomeSocio}({this.props.socio.AdultoBambino})</h1>
                    {this.props.socioMensile && this.props.socioMensile['Contratto']
                        ? <h3 ><span> </span>{this.props.socioMensile['Contratto']}<span> </span></h3>
                        : null}
                </Grid>
                <Grid xs={12}>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                    >
                        {this.props.socio.AdultoBambino == 'B'
                            ? <Grid xs={2}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Pacchetto</FormLabel>
                                    <RadioGroup
                                        aria-label="pacchetto"
                                        name="pacchetto"
                                        value={this.props.value.Pacchetto}
                                        onChange={this.handleChangePacchetto}>
                                        <FormControlLabel value="Avviamento" control={<Radio />} label="Avviamento" />
                                        <FormControlLabel value="AvanzatiA" control={<Radio />} label="AvanzatiA" />
                                        <FormControlLabel value="AvanzatiB" control={<Radio />} label="AvanzatiB" />
                                        <FormControlLabel value="Baby" control={<Radio />} label="Baby" />
                                        <FormControlLabel value="1VoltaSettimana" control={<Radio />} label="1Vol/Sett" />
                                        <FormControlLabel value="SanVito" control={<Radio />} label="SanVito" />
                                        <FormControlLabel value="Rimini" control={<Radio />} label="Rimini" disabled hidden />
                                        <FormControlLabel value="Bellaria" control={<Radio />} label="Bellaria" disabled hidden />
                                        <FormControlLabel value="Avanzati" control={<Radio />} label="Avanzati" disabled hidden />
                                        <FormControlLabel value="Unico" control={<Radio />} label="Unico" disabled hidden />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            : <Grid xs={2}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Pacchetto</FormLabel>

                                    <RadioGroup
                                        aria-label="pacchetto"
                                        name="pacchetto"
                                        value={this.props.value.Pacchetto}
                                        onChange={this.handleChangePacchetto}>
                                        <FormControlLabel value="Singolo" control={<Radio />} label="Singolo" />
                                        <FormControlLabel value="Doppio" control={<Radio />} label="Doppio" />
                                        <FormControlLabel value="Open" control={<Radio />} label="Open" />
                                        <FormControlLabel value="Agonisti" control={<Radio />} label="Agonisti" />
                                        <FormControlLabel value="Ginnastica1VoltaSettimana" control={<Radio />} label="Ginnastica 1Vol/Sett" />
                                        <FormControlLabel value="Ginnastica2VolteSettimana" control={<Radio />} label="Ginnastica 2Vol/Sett" />
                                        <FormControlLabel value="FitnessMusicale1VoltaSettimana" control={<Radio />} label="Fitness M 1Vol/Sett" />
                                        <FormControlLabel value="FitnessMusicale2VolteSettimana" control={<Radio />} label="Fitness M 2Vol/Sett" />
                                        <FormControlLabel value="GinnasticaFitnessDoppio" control={<Radio />} label="Ginnastica Fitness Doppio" />
                                        <FormControlLabel value="SanVito" control={<Radio />} label="SanVito" />
                                        <FormControlLabel value="DifesaFemminile" control={<Radio />} label="DifesaFemminile" disabled hidden={true} />
                                        <FormControlLabel value="Rimini" control={<Radio />} label="Rimini" disabled hidden={true} />
                                        <FormControlLabel value="Bellaria" control={<Radio />} label="Bellaria" disabled hidden={true} />
                                        <FormControlLabel value="AgonistiA" control={<Radio />} label="AgonistiA" disabled hidden={true} />
                                        <FormControlLabel value="AgonistiB" control={<Radio />} label="AgonistiB" disabled hidden />
                                    </RadioGroup>

                                </FormControl>
                                <p />
                                <FormLabel component="legend">Corsi</FormLabel>
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="flex-start"
                                ></Grid>
                                {this.props.value.Pacchetto == 'Singolo' ||
                                    this.props.value.Pacchetto == 'Doppio' ||
                                    this.props.value.Pacchetto == 'Open' ||
                                    this.props.value.Pacchetto == 'Agonisti'
                                    ?





                                    corsi.map((corso) => {

                                        var corsoChecked = corso + 'Checked'

                                        return (<Grid xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={this.props.value[corsoChecked]}
                                                        onChange={(e) => {
                                                            this.handleChangeCheckCorso(e.target.checked, e.target.name, corso);
                                                        }}
                                                        name={corsoChecked}
                                                    />}
                                                label={corso}
                                            />
                                        </Grid>)
                                    })


                                    : this.props.value.Pacchetto == 'Ginnastica1VoltaSettimana' ||
                                        this.props.value.Pacchetto == 'Ginnastica2VolteSettimana'
                                        ?
                                        corsiGinnastica.map((corso) => {

                                            var corsoChecked = corso + 'Checked'

                                            return (<Grid xs={12}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={this.props.value[corsoChecked]}
                                                            onChange={(e) => {
                                                                this.handleChangeCheckCorso(e.target.checked, e.target.name, corso);
                                                            }}
                                                            name={corsoChecked}
                                                        />}
                                                    label={corso}
                                                />
                                            </Grid>)
                                        })

                                        : this.props.value.Pacchetto == 'FitnessMusicale1VoltaSettimana' ||
                                            this.props.value.Pacchetto == 'FitnessMusicale2VolteSettimana'

                                            ?
                                            corsiFitness.map((corso) => {

                                                var corsoChecked = corso + 'Checked'

                                                return (<Grid xs={12}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={this.props.value[corsoChecked]}
                                                                onChange={(e) => {
                                                                    this.handleChangeCheckCorso(e.target.checked, e.target.name, corso);
                                                                }}
                                                                name={corsoChecked}
                                                            />}
                                                        label={corso}
                                                    />
                                                </Grid>)
                                            })

                                            : this.props.value.Pacchetto == 'GinnasticaFitnessDoppio'
                                                ?

                                                corsiGinnasticaFitness.map((corso) => {

                                                    var corsoChecked = corso + 'Checked'

                                                    return (<Grid xs={12}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={this.props.value[corsoChecked]}
                                                                    onChange={(e) => {
                                                                        this.handleChangeCheckCorso(e.target.checked, e.target.name, corso);
                                                                    }}
                                                                    name={corsoChecked}
                                                                />}
                                                            label={corso}
                                                        />
                                                    </Grid>)
                                                })

                                                : null}

                            </Grid>
                        }


                        <Grid xs={2}>
                            {this.props.socioMensile && this.props.socioMensile['Contratto']
                                ? null
                                : <div><FormControl component="fieldset">
                                    <FormLabel component="legend">Contratto</FormLabel>
                                    <RadioGroup aria-label="tipo" name="tipo" value={this.props.value.Contratto} onChange={this.handleChangeContratto}>
                                        {this.props.value.Pacchetto == 'Ginnastica1VoltaSettimana' ||
                                            this.props.value.Pacchetto == 'Ginnastica2VolteSettimana' ||
                                            this.props.value.Pacchetto == 'FitnessMusicale1VoltaSettimana' ||
                                            this.props.value.Pacchetto == 'FitnessMusicale2VolteSettimana' ||
                                            this.props.value.Pacchetto == 'GinnasticaFitnessDoppio'
                                            ? <div><FormControlLabel value="ContrattoAnnuale" control={<Radio />} label="Annuale" disabled />
                                                <FormControlLabel value="ContrattoSemestrale" control={<Radio />} label="Semestrale" disabled /></div>
                                            : <div><FormControlLabel value="ContrattoAnnuale" control={<Radio />} label="Annuale" />
                                                <FormControlLabel value="ContrattoSemestrale" control={<Radio />} label="Semestrale" /></div>}


                                    </RadioGroup>
                                </FormControl><p /></div>}

                            <FormControl component="fieldset">
                                <FormLabel component="legend">Tipo di Mensile</FormLabel>
                                <RadioGroup aria-label="tipo" name="tipo" value={this.props.value.tipoDiMensile} onChange={this.handleChangeTipoDiMensile}>
                                    {this.props.socioMensile && this.props.socioMensile['Contratto'] == 'ContrattoAnnuale'
                                        || this.props.value.Contratto == 'ContrattoAnnuale'
                                        ? <FormControlLabel value="Annuale" control={<Radio />} label="Annuale" />
                                        : <FormControlLabel value="Annuale" control={<Radio />} label="Annuale" disabled />}
                                    {this.props.socioMensile && this.props.socioMensile['Contratto'] == 'ContrattoSemestrale'
                                        || this.props.value.Contratto == 'ContrattoSemestrale'
                                        ? <FormControlLabel value="Semestrale" control={<Radio />} label="Semestrale" />
                                        : <FormControlLabel value="Semestrale" control={<Radio />} label="Semestrale" disabled />}

                                    {this.props.value.Pacchetto == 'DifesaFemminile'
                                        ? <div>
                                            <FormControlLabel value="Trimestrale" control={<Radio />} label="Trimestrale" disabled />
                                            <FormControlLabel value="Mensile" control={<Radio />} label="Mensile" disabled />
                                        </div>
                                        : <div>
                                            <FormControlLabel value="Trimestrale" control={<Radio />} label="Trimestrale" />
                                            <FormControlLabel value="Mensile" control={<Radio />} label="Mensile" />
                                        </div>}
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid xs={2}>
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-start"
                            >
                                <FormLabel component="legend">Mese</FormLabel>
                                {nameOfMonth.map((month) => {

                                    var nameChecked = month + 'Checked';

                                    if (null != this.props.socioMensile &&
                                        null != this.props.socioMensile[month + 'Importo']) {  //この条件が何なのか不明
                                        return (
                                            <Grid xs={12}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox disabled checked />}
                                                    label={month}
                                                />
                                            </Grid>
                                        )
                                    } else {

                                        if (this.props.value.Pacchetto == 'DifesaFemminile') {
                                            return (
                                                <Grid xs={12}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox disabled />}
                                                        label={month}
                                                    />
                                                </Grid>
                                            )

                                        } else {
                                            return (
                                                <Grid xs={12}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={this.props.value[nameChecked]}
                                                                onChange={(e) => {
                                                                    this.handleChangeMonth(e, month);
                                                                }}
                                                                name={nameChecked}
                                                            />}
                                                        label={month}
                                                    />
                                                </Grid>
                                            )
                                        }

                                    }
                                })}
                            </Grid>
                        </Grid>

                        {this.props.value.allSelected
                            ? <Grid xs={6}>
                                <Card className={classes.card}>
                                    <Grid
                                        container
                                        direction="column"
                                        justify="space-between"
                                        alignItems="center"
                                    >

                                        <Grid
                                            container
                                            className={classes.cardInner}
                                        >

                                            <Grid xs={12} >
                                                <Typography variant="h5" component="h2">{this.props.socio.NomeSocio}({this.props.socio.AdultoBambino}) </Typography>
                                            </Grid>
                                            <Grid xs={12} >
                                                <Typography variant="h4" gutterBottom>Importo: €{this.props.value.Importo} </Typography>
                                            </Grid>

                                            <Grid xs={12} >
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <Grid container justify="space-around">
                                                        <KeyboardDatePicker
                                                            disableToolbar
                                                            variant="inline"
                                                            format="MM/dd/yyyy"
                                                            margin="normal"
                                                            id="DataDiPagamento"
                                                            label="Data Di Pagamento"
                                                            value={this.props.value.DataDiPagamento}
                                                            onChange={this.handleDateChangeDataDiPagamento}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change date',
                                                            }}
                                                        />

                                                    </Grid>
                                                </MuiPickersUtilsProvider>


                                            </Grid>
                                            <Grid xs={12} >
                                                <FormControl component="fieldset">
                                                    <FormLabel component="legend">Metodo</FormLabel>
                                                    <RadioGroup
                                                        aria-label="Metodo"
                                                        name="MetodoDiPagamento"
                                                        value={this.props.value.MetodoDiPagamento}
                                                        onChange={this.handleChangeMetodoDiPagamento}>
                                                        <FormControlLabel value="Contanti" control={<Radio />} label="Contanti" />
                                                        <FormControlLabel value="Bonifico" control={<Radio />} label="Bonifico" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>
                                            <Grid xs={12} >
                                                <TextField
                                                    inputProps={{ maxLength: 50 }}
                                                    className={classes.descrizione}
                                                    id="Descrizione3"
                                                    label="Descrizione"
                                                    rowsMax={10}
                                                    value={this.props.value.Descrizione3}
                                                    onChange={this.handleChangeDescrizione3}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                        </Grid>
                                        <ArrowDownwardIcon />
                                        <Button
                                            disabled={this.props.value.DataDiPagamento && this.props.value.MetodoDiPagamento ? false : true}
                                            className={classes.buttonPagato}
                                            variant="contained"
                                            color="secondary"
                                            onClick={(e) => {
                                                e.preventDefault();

                                                console.log('this.props', this.props)

                                                this.props.createTransactionMensile(
                                                    this.props.socio.id,
                                                    this.props.AnnoAccademico,
                                                    this.props.value.Pacchetto,
                                                    this.props.value.tipoDiMensile,
                                                    this.props.value.Importo,
                                                    this.props.value.ImportoAlMese,
                                                    JSON.stringify(this.props.value.monthToPay),
                                                    DateTool.createJSONDateWithoutTime(this.props.value.DataDiPagamento),
                                                    this.props.value.MetodoDiPagamento,
                                                    this.props.value.Contratto
                                                        ? this.props.value.Contratto
                                                        : this.props.socioMensile
                                                            ? this.props.socioMensile['Contratto']
                                                            : null,
                                                    this.props.value.Descrizione1,
                                                    'Corso : ' + this.props.value.Descrizione2,
                                                    this.props.value.Descrizione3,
                                                    this.props.value.tipo,
                                                    this.props.setPagamentoId,
                                                    this.props.setOpenModalTrue,
                                                    this.props.getPagamentoBySocioId,
                                                    this.props.logininfo.id
                                                );
                                            }}>Archiviazione</Button>


                                    </Grid>

                                </Card>


                            </Grid>

                            : null
                        }

                    </Grid>
                </Grid>
            </Grid>


        )
    }



}



const mapStateToProps = (state) => {
    return {

        Prezzi: state.model.Prezzi,
        logininfo: state.auth.logininfo,
        AnnoAccademico: state.model.AnnoAccademico,

    }
}

const mapDispatchToProps = (dispatch) => {

    return {

        createTransactionMensile: (
            SocioId,
            AnnoAccademico,
            Pacchetto,
            tipoDiMensile,
            Importo,
            ImportoAlMese,
            monthToPay,
            DataDiPagamento,
            MetodoDiPagamento,
            Contratto,
            Descrizione1,
            Descrizione2,
            Descrizione3,
            tipo,
            setPagamentoId,
            setOpenModalTrue,
            getPagamentoBySocioId,
            access_token) => {
            dispatch(PagamentoAction.createTransactionMensile(
                SocioId,
                AnnoAccademico,
                Pacchetto,
                tipoDiMensile,
                Importo,
                ImportoAlMese,
                monthToPay,
                DataDiPagamento,
                MetodoDiPagamento,
                Contratto,
                Descrizione1,
                Descrizione2,
                Descrizione3,
                tipo,
                setPagamentoId,
                setOpenModalTrue,
                getPagamentoBySocioId,
                access_token))
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(SociMensileSimple));
import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import { connect } from 'react-redux';
import classNames from 'classnames'
import { createMuiTheme } from '@material-ui/core'
import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import * as RicevutaAction from '../../store/actions/RicevutaAction'
import * as DateTool from '../../util/DateTool'
import AttachFileIcon from '@material-ui/icons/AttachFile';

const styles = theme => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: 'black',
        height: 250,
    },
    paperClickable: {
        cursor: 'pointer'
    },
    paperColorWhite: {
        backgroundColor: 'white',
    },
    paperColorGrey: {
        backgroundColor: '#929292',
    },
    paperColorYellow: {
        backgroundColor: '#FFE06F',
    },
    ricevutaColorInherit: {
    },
    ricevutaColorYellow: {
        backgroundColor: '#FFE06F',
    },
    monthName: {

    }

});


class GridMensile extends Component {

    constructor(props) {
        super(props);
        this.state = { paperColor: 'paperColorWhite', ricevutaColor: 'ricevutaColorInherit' }
    }

    componentDidMount() {

        if (null == this.props.importo) {
            this.setState({ paperColor: 'paperColorGrey' })
        } else {
            this.setState({ paperColor: 'paperColorWhite' })

            if (null == this.props.dataDiEmissioneDiRicevuta) {
                this.setState({ ricevutaColor: 'ricevutaColorYellow' })
            }
        }



    }

    render() {

        const { classes } = this.props;

        return (
            <Grid item xs={3} >
                {this.props.month ?
                    <Paper
                        className={
                            classNames(classes.paper,
                                this.props.importo ? classes.paperColorWhite : classes.paperColorGrey,
                                this.props.importo ? null : classes.paperClickable
                            )}
                        onClick={(e) => {
                            if (this.props.importo == null) {
                                this.props.setOpenPagamentoPageTrue(e)
                            }
                        }}
                    >
                        <Grid
                            container
                            direction="column"
                            justify="flex-start"
                            alignItems="left"
                        >
                            <List component="nav" className={classes.root} aria-label="mailbox folders">
                                <ListItem >
                                    <Typography variant="h5" gutterBottom>{this.props.month}</Typography>
                                </ListItem>
                                <Divider />
                                <ListItem >
                                    {this.props.importo
                                        ? <Typography variant="h9" gutterBottom>Importo : €{this.props.importo}</Typography>
                                        : null}
                                </ListItem>
                                <Divider />
                                {this.props.importo
                                    ? <ListItem>
                                        <Typography variant="h7" gutterBottom>{this.props.descrizione}
                                        </Typography>
                                    </ListItem>
                                    : null}

                                <Divider />

                                {this.props.month == 'QuotaAikikai'
                                    ? null
                                    : this.props.importo
                                        ? this.props.pagamento
                                            ? this.props.pagamento.DataDiEmissioneDiRicevuta
                                                ? <ListItem button onClick={(e) => {
                                                    e.preventDefault();
                                                    this.props.getRicevutaByIdWithNewWindow(this.state.ricevutaId, this.props.logininfo.id);
                                                }}>
                                                    <Typography variant="h7" gutterBottom>Ricevuta: <AttachFileIcon /></Typography>
                                                </ListItem>
                                                : <ListItem
                                                    className={classes.ricevutaColorYellow}
                                                    button onClick={(e) => {
                                                        e.preventDefault();
                                                        this.props.setOpenModalTrue(this.props.pagamento.id);
                                                    }}><Typography variant="h7" gutterBottom>DA EMETTERE!!</Typography></ListItem>
                                            : null
                                        : null}
                            </List>
                        </Grid>
                    </Paper>
                    : null
                }
            </Grid>
        )
    }

}



const mapStateToProps = (state) => {
    return {
        logininfo: state.auth.logininfo
    }
}

const mapDispatchToProps = (dispatch) => {

    return {

        getRicevutaByIdWithNewWindow: (RicevutaId,access_token) => {
            dispatch(RicevutaAction.getRicevutaById(RicevutaId, access_token,(Pdf) => {
                window.open('http://localhost:2000/ricevuta/' + Pdf);
            }))
        }



    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(GridMensile));
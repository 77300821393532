import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import { connect } from 'react-redux';
import classNames from 'classnames'
import { createMuiTheme } from '@material-ui/core'
import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import GridMensile from './GridMensile';
import { addMonths } from 'date-fns';
import Typography from '@material-ui/core/Typography';
import ModalDataSocio from './ModalDataSocio';
import SociMensileDashboard from './SociMensileSimple';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

import * as ModelAction from '../../store/actions/ModelAction'



const styles = theme => ({

    margin: {
        margin: theme.spacing(1),
    },
    sociList: {
        width: 210
    }



});




class SociSearcher extends Component {

    constructor(props) {

        super(props);
        this.state = { searchText: "" };

    }

    componentDidMount() {
        this.props.getSociList(this.props.logininfo.id);
    }

    selectSosio(e, id) {
        e.preventDefault();


        var selectedSocio = this.state.selectedSocio;
        this.props.sociList.forEach((socio) => {
            if (socio.id == id) {
                selectedSocio = socio;
            }
        })

        this.props.onSelect(id, selectedSocio);

        //this.setState({ searchText: selectedSocio });

    }


    render() {

        const { classes } = this.props;
        return (
            <div>
                <InputLabel htmlFor="input-text">cerca...</InputLabel>
                <Input
                    id="input-text"
                    startAdornment={
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    }
                    onChange={(e) => { this.setState({ searchText: e.target.value }) }}
                    value={this.state.searchText}
                />
                <List component="nav" >
                    {this.props.sociList.map((socio) => {

                        if (this.state.searchText == "") {
                            return (
                                <ListItem
                                    className={classNames(classes.sociList)}
                                    button
                                    onClick={(e) => { this.selectSosio(e, socio.id) }}
                                >
                                    <ListItemText primary={socio.NomeSocio} />
                                </ListItem>
                            )
                        } else {

                            if (socio.NomeSocio.toLowerCase().indexOf(this.state.searchText.toLowerCase()) != -1) {
                                return (
                                    <ListItem
                                        className={classNames(classes.sociList)}
                                        button
                                        onClick={(e) => { this.selectSosio(e, socio.id) }}
                                    >
                                        <ListItemText primary={socio.NomeSocio} />
                                    </ListItem>
                                )
                            }
                        }
                    })}
                </List>
            </div>

        )
    }

}



const mapStateToProps = (state) => {
    return {
        sociList: state.model.Socio,
        logininfo: state.auth.logininfo
    }
}

const mapDispatchToProps = (dispatch) => {

    return {
        getSociList: (access_token) => {
            //全件取得
            dispatch(ModelAction.apiGet('Socio',1,1000,[],access_token))
        },

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(SociSearcher));
import React, { Component } from 'react';

class InputField extends Component {

    render() {
        return (
            <div className="form-group">
                <label
                    className="small mb-1"
                    htmlFor={this.props.id}>
                    {this.props.label}
                </label>
                <input
                    className="form-control py-4"
                    id={this.props.id}
                    type={this.props.type}
                    placeholder={this.props.placeholder} 
                    value={this.props.value}
                    onChange={this.props.onChange}
                    />
            </div>
        )
    }

}


export default InputField
import axios from 'axios';
import * as LoopbackCall from '../../util/LoopbackCall';
import * as StrTool from '../../util/StrTool';

var FileSaver = require('file-saver');

export const getElencoIscritti = (access_token) => {

    //transactionMensile

    return (dispatch) => {
        axios.post(LoopbackCall.url + `Socio/elencoiscritti_json`,
            null,
            {
                params: {
                    access_token: access_token
                }
            }).then((response) => {

                if (response.status == 200) {

                    console.log("getElencoIscritti");
                    dispatch({ type: "GET_ELENCOISCRITTI", value: response.data })
                }



            }).catch((error) => {

                
                if (error.response.status === 401) {
                    console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                    dispatch({
                        type: "ACCESSTOKEN_EXPIRED"
                    })
                }
                console.log("getElencoIscritti失敗", error)
            })
    }
}



export const transactionMessageClose = () => {

    return (dispatch) => {
        dispatch({ type: "PAGAMENTO_COMPLETE_MESSAGECLOSE" })
    }

}



export const apiExportExcelElencoIscritti = (access_token) => {

    var modelName = 'elencoiscritti';

    //https://github.com/exceljs/exceljs#fills
    return (dispatch) => {
        axios.post(LoopbackCall.url + `Socio/elencoiscritti_json`,
            null,
            { params: { access_token: access_token } }
        ).then((response) => {

            console.log("apiExportExcel response.status", response.status)
            if (response.status == 200) {

                const excel = require("exceljs");
                let workbook = new excel.Workbook();
                let worksheet = workbook.addWorksheet(modelName);

                var columns = [];
                var col_num = 0;
                if (response.data.length > 0) {
                    Object.keys(response.data[0]).forEach((key) => {
                        columns.push({ header: key, key: key, width: 12 });
                        col_num++;
                    })
                }
                worksheet.columns = columns;

                var row_num = 1;
                response.data.forEach((d) => {
                    worksheet.addRow(d);
                    row_num++;
                })

                var row_index = 1;
                while (row_index <= row_num) {
                    var col_index = 1;
                    while (col_index <= col_num) {
                        worksheet.getRow(row_index).getCell(col_index).border = {
                            top: { style: 'thin' },
                            left: { style: 'thin' },
                            bottom: { style: 'thin' },
                            right: { style: 'thin' }
                        };
                        col_index++;
                    }
                    row_index++;
                }



                workbook.xlsx.writeBuffer().then(function (data) {

                    FileSaver.saveAs(
                        new Blob(
                            [data],
                            { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }),
                        modelName + StrTool.createStrYYYYMMDDHHMMSS(new Date()) + ".xlsx"
                    );

                });


            }

        }).catch((error) => {

            
            if (error.response.status === 401) {
                console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                dispatch({
                    type: "ACCESSTOKEN_EXPIRED"
                })
            }

            console.log("GET_ALL_" + modelName + " ERR", error)
        })

    }

}

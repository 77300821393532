
import React from 'react';
import ReactDOM from 'react-dom';
import { useLocation } from 'react-router-dom'

import Login from './Login'
import Password from './Password'


//function component で記述
const LoginGetParam = () => {

    const search = useLocation().search
    const searchParams = new URLSearchParams(search)

    var verificationtoken = searchParams.get('verificationtoken')
    var email = searchParams.get('email')

    //クエリパラメータにverificationtokenとemailが設定されていたらPasswordへ
    //設定されていなければlogin
    if (verificationtoken != null && email != null) {
        return <Password
            verificationtoken={verificationtoken}
            email={email}
        />;
    } else {
        return <Login />;
    }


}

export default LoginGetParam;
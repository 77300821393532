import React, { Component } from 'react';

import classNames from 'classnames'
import Grid from '@material-ui/core/Grid';
import { withStyles } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import * as WebSocketAction from '../../../store/actions/WebSocketAction';
import * as PresenzeAction from '../../../store/actions/PresenzeAction'
import { connect } from 'react-redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { createMuiTheme } from '@material-ui/core'
import * as DateTool from '../../../util/DateTool';
import * as StrTool from '../../../util/StrTool';
import Pagination from '@material-ui/lab/Pagination';
import CrudModalCreatePresenze from './CrudModalCreatePresenze'
import CrudModalUpdatePresenze from './CrudModalUpdatePresenze'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


import DropzoneAreaModal from '../../common/DropzoneAreaModal'


const styles = theme => ({

    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    table: {
        // minWidth: 650,
    },
    tableHead: {
        //background: 'linear-gradient(45deg, #8C8C8C, #BDBDBD)'
        background: "#b9b9b9"
    },

    row: {
        '&:hover': {
            background: "#CACACA"
        }
    },
    cell: {
        '&:hover': {
            background: "#b9b9b9"
        },
    },
    modal: {
        display: 'block',
        position: 'absolute',
        overflow: 'scroll',
    },
    modalpaper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: 900,
    },
    buttonback: {
        backgroundColor: "#b9b9b9",
        border: '0px solid #000',
        boxShadow: theme.shadows[0],
        padding: theme.spacing(1.5, 1.5, 1.5),

    },
    formControlS: {
        margin: theme.spacing(1),
        width: 120,
    },
    formControlM: {
        margin: theme.spacing(1),
        width: 300
    },
    formControlL: {
        margin: theme.spacing(1),
        width: 800
    },
    grammar: {
        width: 800
    },
    grammarPreview: {
        width: 800
    },
    cell_short: {
        fontSize: "10px",
        width: 50,
    },
    cell_medium: {
        fontSize: "10px",
        width: 100,
    },
    cell_clickable: {
        cursor: "pointer"
    },
    cell_searched: {
        background: "#FA8072"
    },
    height100: {
        height: '100%'
    },
    gridItem: {
    },
    button: {
        background: 'white',
        borderRadius: 3,
        border: 0,
        color: 'black',
        padding: '0 20px',
        '&:hover': {
            background: "#b9b9b9"
        },
    },
    container: {
        maxHeight: 600,
    },
});

const customTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#bfbfbf',
            light: '#969696',
            dark: '#616060',
        },
        secondary: {
            main: '#616060',
        },
    },
})

const modelProps = {
    "id": {
        "type": "string"
    },
    "Data": {
        "type": "date"
    },
    "UserId": {
        "type": "string"
    },
    "StaffId": {
        "type": "string"
    },
    "Presenza": {
        "type": "string"
    },
    "Ore": {
        "type": "number",
        "mysql": {
            "dataType": "float"
        }
    },
    "CostoOrario": {
        "type": "number",
        "mysql": {
            "dataType": "float"
        }
    },
    "Confermata": {
        "type": "Boolean"
    },
    "Descrizione": {
        "type": "string"
    },
    "LastModifiedBy": {
        "type": "string"
    },
    "LastModifiedAt": {
        "type": "date"
    }
}


var defaultFormValue = {};

class SimpleCrud extends Component {

    constructor(props) {
        super(props);

        var submitDisabled = false

        var firstdayofmonth = DateTool.returnItalianTimeFirstDayOfMonth() // 2023-09-01T00:00:00.000Z
        console.log('firstdayofmonth★★★★★', firstdayofmonth);

        var thisMonth = DateTool.convertMonth(parseInt(firstdayofmonth.substring(5, 7), 10))



        this.state = null;
        this.state = {
            thisMonth: thisMonth,
            formValue: defaultFormValue,
            page: 1,
            numData: 500,
            searchword: '',
            searchedNum: 0,
            dataholderuserId: null,
            //submitDisabled : submitDisabled
            unfilledRequiredFIeld: false,
            firstdayofmonth: firstdayofmonth,

        };

    }


    componentDidMount() {
        this.props.getModelCount(this.props.role == 'jyushin' ? null : this.props.logininfo.userId, this.state.firstdayofmonth, this.props.logininfo.id)
        this.props.getModelList(this.props.role == 'jyushin' ? null : this.props.logininfo.userId, this.state.firstdayofmonth, this.state.page, this.state.numData, this.props.logininfo.id)
        //this.props.setupWebSocket(this.props.logininfo.id);
    }




    onChangeSearchWord = (value) => {

        var searchword = value.trim();

        this.setState({ searchword: searchword })

        //検索ワードがある場合、全件取得 検索ワードを消した場合も全件取得 ページは１に固定
        //if (searchword != '') {
        var total = this.props.modelList["Presenze_count"]
        this.setState({ numData: total });
        this.setState({ page: 1 });
        this.props.getModelList(this.props.role == 'jyushin' ? null : this.props.logininfo.userId, this.state.firstdayofmonth, 1, total, this.props.logininfo.id)
        //}
    }

    onChangeInputNumData = (e) => {
        e.preventDefault();

        var num = e.target.value;

        var total = this.props.modelList["Presenze_count"]

        if (num >= total) {
            num = total;
        } else {
            //全件１ページ表示字のみ検索可
            this.setState({ searchword: '' })

        }

        this.setState({ numData: num });

        if (num == null || num == "") {
            this.props.getModelList(this.props.role == 'jyushin' ? null : this.props.logininfo.userId, this.state.firstdayofmonth, this.state.page, 1, this.props.logininfo.id)
        } else {
            this.props.getModelList(this.props.role == 'jyushin' ? null : this.props.logininfo.userId, this.state.firstdayofmonth, this.state.page, this.state.numData, this.props.logininfo.id)
        }

    }

    onChangePagination = (page) => {
        this.setState({ page: page });
        this.props.getModelList(this.props.role == 'jyushin' ? null : this.props.logininfo.userId, this.state.firstdayofmonth, this.state.page, this.state.numData, this.props.logininfo.id)
    }


    

    render() {


        const { classes } = this.props;

        

        return (
            <Grid
                container
                direction="row"
                justify="space-around"
                alignItems="center"
            >
                <Grid item xs={12}  >
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-end"
                        spacing={2}
                    >
                        <Typography variant="h2" component="h2">
                            <Box fontSize="h15.fontsize" m={1} fontStyle="oblique">Presenze</Box>
                        </Typography>
                        <Typography variant="h2" component="h2">
                            <Box fontSize="h15.fontsize" m={1} fontStyle="oblique">
                                <ArrowBackIcon
                                    className={classNames(classes.cell_clickable)}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        var firstdayoflastmonth = DateTool.returnFirstDayOfLastMonth(this.state.firstdayofmonth)
                                        this.setState({ firstdayofmonth: firstdayoflastmonth })
                                        var thisMonth = DateTool.convertMonth(parseInt(firstdayoflastmonth.substring(5, 7), 10))
                                        this.setState({ thisMonth: thisMonth })
                                        this.props.getModelCount(this.props.logininfo.userId, firstdayoflastmonth, this.props.logininfo.id)
                                        this.props.getModelList(this.props.role == 'jyushin' ? null : this.props.logininfo.userId, firstdayoflastmonth, 1, this.state.numData, this.props.logininfo.id)
                                    }}
                                />
                            </Box>
                        </Typography>
                        <Typography variant="h2" component="h2">
                            <Box fontSize="h15.fontsize" m={1} fontStyle="oblique">{this.state.thisMonth}</Box>
                        </Typography>
                        <Typography variant="h2" component="h2">
                            <Box fontSize="h15.fontsize" m={1} fontStyle="oblique">{this.state.firstdayofmonth.substring(0, 4)}</Box>
                        </Typography>
                        <Typography variant="h2" component="h2">
                            <Box fontSize="h15.fontsize" m={1} fontStyle="oblique">
                                <ArrowForwardIcon
                                    className={classNames(classes.cell_clickable)}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        var firstdayofNextmonth = DateTool.returnFirstDayOfNextMonth(this.state.firstdayofmonth)
                                        this.setState({ firstdayofmonth: firstdayofNextmonth })
                                        var thisMonth = DateTool.convertMonth(parseInt(firstdayofNextmonth.substring(5, 7), 10))
                                        this.setState({ thisMonth: thisMonth })
                                        this.props.getModelCount(this.props.logininfo.userId, firstdayofNextmonth, this.props.logininfo.id)
                                        this.props.getModelList(this.props.role == 'jyushin' ? null : this.props.logininfo.userId, firstdayofNextmonth, 1, this.state.numData, this.props.logininfo.id)

                                    }}
                                />
                            </Box>
                        </Typography>

                    </Grid>
                </Grid>
                <Grid item xs={5}  >
                    <CrudModalCreatePresenze
                        modelProps={modelProps}
                        modelName='Presenze'
                        page={this.state.page}
                        numData={this.state.numData}
                        firstdayofmonth={this.state.firstdayofmonth}
                    />
                </Grid>
                <Grid item xs={7}  >

                </Grid>

                <Grid item xs={12}  >


                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                    >

                        <TextField
                            id="standard-basic"
                            label="cerca..."
                            value={this.state.searchword}
                            onChange={(e) => {
                                e.preventDefault();
                                this.onChangeSearchWord(e.target.value)
                            }}
                            className={
                                this.state.searchword == ''
                                    ? null
                                    : classNames(classes.cell_searched)
                            }
                        />
                        <Pagination
                            onChange={(e, page) => { e.preventDefault(); this.onChangePagination(page) }}
                            page={this.state.page}
                            count={this.props.modelList
                                ? Math.ceil(this.props.modelList["Presenze_count"] / this.state.numData)
                                : 3}
                        />
                        {this.state.searchword == ''
                            ? <TextField
                                id="standard-basic"
                                label="number of datas"
                                value={this.state.numData}
                                onChange={(e) => { this.onChangeInputNumData(e) }}
                            />
                            : null
                        }

                        {this.state.searchword == ''
                            ? <Typography variant="h6" >total:{this.props.modelList["Presenze_count"]}</Typography>
                            : null
                        }


                    </Grid>
                </Grid>
                <Grid item xs={12} >
                    <div >
                        <TableContainer className={classes.container} >
                            <Table stickyHeader aria-label="a dense table"  >
                                <TableHead className={classes.tableHead} >
                                    <TableRow >
                                        <TableCell align="left" style={{ backgroundColor: '#BDBDBD' }} >Data</TableCell>
                                        {this.props.role == 'jyushin' &&
                                            <TableCell align="left" style={{ backgroundColor: '#BDBDBD' }}>Staff</TableCell>
                                        }
                                        <TableCell align="left" style={{ backgroundColor: '#BDBDBD' }} >Presenza</TableCell>
                                        <TableCell align="left" style={{ backgroundColor: '#BDBDBD' }} >Ore</TableCell>
                                        {this.props.role == 'jyushin' &&
                                            <TableCell align="left" style={{ backgroundColor: '#BDBDBD' }}>CostoOrario</TableCell>
                                        }
                                        <TableCell align="left" style={{ backgroundColor: '#BDBDBD' }} >Descrizione</TableCell>
                                        <TableCell align="left" style={{ backgroundColor: '#BDBDBD' }} >Confermata</TableCell>
                                        <TableCell align="left" style={{ backgroundColor: '#BDBDBD' }} ></TableCell>
                                        <TableCell align="left" style={{ backgroundColor: '#BDBDBD' }} ></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody >
                                    {this.props.modelList['Presenze'].map((m) => {

                                        var showData = false;

                                       

                                        if (this.state.searchword == '') {
                                            showData = true;
                                        } else {

                                            var keys;

                                            if (this.props.role == 'jyushin') {
                                                keys = [
                                                    "Data",
                                                    "Staff",
                                                    "Presenza",
                                                    "Ore",
                                                    "CostoOrario",
                                                    "Confermata",
                                                    "Descrizione"
                                                ]
                                            } else {
                                                keys = [
                                                    "Data",
                                                    "Presenza",
                                                    "Ore",
                                                    "Confermata",
                                                    "Descrizione"
                                                ]
                                            }








                                            //Array.prototype.some()という関数があります。コールバック関数がtrueを返すと即座にtrueを返すというテスト関数
                                            keys.some((key) => {
                                                if (key == 'Staff') {
                                                    if (m['Staff'] != null &&
                                                        (String(m['Staff']['Cognome']).toLowerCase().indexOf(this.state.searchword.toLowerCase()) != -1 ||
                                                            String(m['Staff']['Nome']).toLowerCase().indexOf(this.state.searchword.toLowerCase()) != -1)) {
                                                        showData = true;
                                                        return true;
                                                    }
                                                } else {
                                                    if (m[key] != null &&
                                                        String(m[key]).toLowerCase().indexOf(this.state.searchword.toLowerCase()) != -1) {
                                                        showData = true;

                                                        return true;
                                                    }
                                                }

                                            })


                                        }

                                        if (showData) {
                                            return (
                                                <TableRow key={m.id} className={classes.row}>
                                                    <TableCell
                                                        align="left"
                                                        className={
                                                            this.state.searchword == '' || m['Data'] == null
                                                                ? classNames(classes.cell)
                                                                : String(m['Data']).toLowerCase().indexOf(this.state.searchword.toLowerCase()) == -1
                                                                    ? classNames(classes.cell)
                                                                    : classNames(classes.cell, classes.cell_searched)
                                                        }
                                                    >
                                                        {StrTool.createStrDD_MM_YYYY(m['Data'])}
                                                    </TableCell>
                                                    {this.props.role == 'jyushin' &&
                                                        <TableCell
                                                            align="left"
                                                            className={
                                                                this.state.searchword == '' || m['Staff'] == null
                                                                    ? classNames(classes.cell)
                                                                    : String(m['Staff']['Cognome']).toLowerCase().indexOf(this.state.searchword.toLowerCase()) == -1
                                                                        ? String(m['Staff']['Nome']).toLowerCase().indexOf(this.state.searchword.toLowerCase()) == -1
                                                                            ? classNames(classes.cell)
                                                                            : classNames(classes.cell, classes.cell_searched)
                                                                        : classNames(classes.cell, classes.cell_searched)
                                                            }
                                                        >
                                                            {m['Staff'] != null &&
                                                                m['Staff']['Cognome'] + ' ' + m['Staff']['Nome']}
                                                        </TableCell>
                                                    }
                                                    <TableCell
                                                        align="left"
                                                        className={
                                                            this.state.searchword == '' || m['Presenza'] == null
                                                                ? classNames(classes.cell)
                                                                : String(m['Presenza']).toLowerCase().indexOf(this.state.searchword.toLowerCase()) == -1
                                                                    ? classNames(classes.cell)
                                                                    : classNames(classes.cell, classes.cell_searched)
                                                        }
                                                    >
                                                        {m['Presenza']}
                                                    </TableCell>
                                                    <TableCell
                                                        align="left"
                                                        className={
                                                            this.state.searchword == '' || m['Ore'] == null
                                                                ? classNames(classes.cell, classes.cell_medium)
                                                                : String(m['Ore']).toLowerCase().indexOf(this.state.searchword.toLowerCase()) == -1
                                                                    ? classNames(classes.cell, classes.cell_medium)
                                                                    : classNames(classes.cell, classes.cell_medium, classes.cell_searched)
                                                        }
                                                    >
                                                        <Typography variant="h6" >{m['Ore']}</Typography>
                                                    </TableCell>
                                                    {this.props.role == 'jyushin' &&
                                                        <TableCell
                                                            align="left"
                                                            className={
                                                                this.state.searchword == '' || m['CostoOrario'] == null
                                                                    ? classNames(classes.cell, classes.cell_medium)
                                                                    : String(m['CostoOrario']).toLowerCase().indexOf(this.state.searchword.toLowerCase()) == -1
                                                                        ? classNames(classes.cell, classes.cell_medium)
                                                                        : classNames(classes.cell, classes.cell_medium, classes.cell_searched)
                                                            }
                                                        >
                                                            <Typography variant="h6" >
                                                                {m['CostoOrario'] != null && m['CostoOrario'] != ''
                                                                    ? m['CostoOrario']
                                                                    : m['Staff']['CostoOrario']}
                                                            </Typography>
                                                        </TableCell>
                                                    }
                                                    <TableCell
                                                        align="left"
                                                        className={
                                                            this.state.searchword == '' || m['Descrizione'] == null
                                                                ? classNames(classes.cell)
                                                                : String(m['Descrizione']).toLowerCase().indexOf(this.state.searchword.toLowerCase()) == -1
                                                                    ? classNames(classes.cell)
                                                                    : classNames(classes.cell, classes.cell_searched)
                                                        }
                                                    >
                                                        {m['Descrizione']}
                                                    </TableCell>
                                                    <TableCell
                                                        align="left"
                                                        className={
                                                            this.state.searchword == '' || m['Confermata'] == null
                                                                ? classNames(classes.cell)
                                                                : String(m['Confermata']).toLowerCase().indexOf(this.state.searchword.toLowerCase()) == -1
                                                                    ? classNames(classes.cell)
                                                                    : classNames(classes.cell, classes.cell_searched)
                                                        }
                                                    >


                                                        {m['Confermata'] == true
                                                            ? <Typography variant="h6" style={{ color: 'blue' }}>Si</Typography>
                                                            : <Typography variant="h6" style={{ color: 'red' }}>No</Typography>}
                                                    </TableCell>
                                                    <TableCell
                                                        align="left"
                                                        className={classNames(classes.cell, classes.cell_short,)}
                                                    >
                                                        <CrudModalUpdatePresenze
                                                            modelValue={m}
                                                            firstdayofmonth={this.state.firstdayofmonth} />
                                                    </TableCell>

                                                    <TableCell
                                                        align="right"
                                                        className={classNames(classes.cell, classes.cell_short)}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            if (window.confirm("deleting data id : " + m['id'])) {
                                                                this.props.submitDelete(this.props.role == 'jyushin' ? null : this.props.logininfo.userId, this.state.firstdayofmonth, this.state.numData, m['id'], this.props.logininfo.id)

                                                            }
                                                        }}>
                                                        <DeleteForeverIcon className={classNames(classes.cell_clickable)} />
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        } else {
                                            return null;
                                        }
                                    })}


                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </Grid >
            </Grid >

        );
    }
}


const mapStateToProps = (state) => {
    return {
        modelList: state.model,
        socket: state.webSocket.socket,
        logininfo: state.auth.logininfo,
        role: state.auth.role

    }
}

const mapDispatchToProps = (dispatch) => {

    return {

        setupWebSocket: (access_token) => {
            dispatch(WebSocketAction.setupWebSocket(access_token))
        },
        getModelList: (userId, firstdayofmonth, page, numData, access_token) => {
            dispatch(PresenzeAction.apiGet_presenza_by_month(userId, firstdayofmonth, page, numData, access_token))
        },
        getModelCount: (userId, firstdayofmonth, access_token) => {
            dispatch(PresenzeAction.apiGetCount(userId, firstdayofmonth, access_token))
        },
        submitDelete: (userId, firstdayofmonth, numData, presenzaId, access_token) => {
            dispatch(PresenzeAction.apiDelete(userId, firstdayofmonth, numData, presenzaId, access_token))
        },
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(SimpleCrud));
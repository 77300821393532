import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import { connect } from 'react-redux';
import classNames from 'classnames'
import { createMuiTheme } from '@material-ui/core'
import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';

import FormControl from '@material-ui/core/FormControl';
import SociSearcher from '../common/SociSearcher';
import * as ModelAction from '../../store/actions/ModelAction';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import SociMensileSimple from '../common/SociMensileSimple'
import * as PagamentoAction from '../../store/actions/PagamentoAction'
import * as SocioMensileAction from '../../store/actions/SocioMensileAction'
import * as RicevutaAction from '../../store/actions/RicevutaAction'
import CloseIcon from '@material-ui/icons/Close';
import PagamentoDetailModal from '../common/PagamentoDetailModal'
import PagamentoDetailModalSenzaRicevuta from '../common/PagamentoDetailModalSenzaRicevuta'
import PagamentoAltroDetailModal from '../common/PagamentoAltroDetailModal'

import PagamentoCsen from '../common/PagamentoCsen'
import PagamentoAikikai from '../common/PagamentoAikikai'
import PagamentoAltro from '../common/PagamentoAltro'
import PagamentoSingolaEntrata from '../common/PagamentoSingolaEntrata'
import SociPiuEsternoSearcher from '../common/SociPiuEsternoSearcher'


import Button from '@material-ui/core/Button';

const styles = theme => ({

    margin: {
        margin: theme.spacing(1),
    },
    sociList: {
        width: 210
    },
    paper: {
        width: 600,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3)
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttonEmettereRicevuta: {
        backgroundColor: '#ffd700'
    }

});



const defaultValue = {
    tipoDiMensile: null,
    OttobreChecked: false,
    NovembreChecked: false,
    DicembreChecked: false,
    GennaioChecked: false,
    FebbraioChecked: false,
    MarzoChecked: false,
    AprileChecked: false,
    MaggioChecked: false,
    GiugnoChecked: false,
    LuglioChecked: false,
    monthToPay: [],
    Contratto: null,
    Descrizione1: "",
    Descrizione2: "",
    Descrizione3: "",
    allSelected: false,
    Pacchetto: null,
    AikidoChecked: false,
    MuayThaiChecked: false,
    BJJChecked: false,
    Corsi: [],
    Importo: null,
    ImportoAlMese: null,
    DataDiPagamento: new Date(),
    MetodoDiPagamento: null,
}


class Pagamento extends Component {

    constructor(props) {
        super(props);

        this.state = {
            tipo: "",
            selectedSocioId: null,
            selectedSocio: null,
            selectedEsternoId: null,
            selectedEsterno: null,
            openModal: false,
            openModalWithoutRicevuta: false,
            PasswordForRicevuta: null,
            pagamentoId: null,
            ricevutaFileName: null,
            ...defaultValue
        };

        this.props.getPrezzi(this.props.logininfo.id);
    }

    componentDidMount() {

    }

    handleChangeTipo = (e) => {

        this.setState({ ...defaultValue })
        this.setState({ tipo: e.target.value })
        //this.setState({ value: defaultValue })
        this.setState({ selectedSocioId: null })
        this.setState({ selectedSocio: null })
        this.setState({ selectedEsternoId: null })
        this.setState({ selectedEsterno: null })
    }

    handleInputPasswordForRicevuta = (e) => {
        this.setState({ PasswordForRicevuta: e.target.value })
    }


    render() {

        const { classes } = this.props;



        return (



            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
            >

                <Grid xs={2} >

                    <FormControl component="fieldset">
                        <FormLabel component="legend">Tipo di Pagamento</FormLabel>
                        <RadioGroup aria-label="tipo" name="tipo" value={this.state.tipo} onChange={this.handleChangeTipo}>
                            <FormControlLabel value="pacchettoSocio" control={<Radio />} label="Pacchetto Socio" />
                            <FormControlLabel value="Csen" control={<Radio />} label="Quota Csen" />
                            <FormControlLabel value="Aikikai" control={<Radio />} label="Quota Aikikai" />
                            <FormControlLabel value="Consulenza" control={<Radio />} label="Consulenza" />
                            <FormControlLabel value="SingolaEntrata" control={<Radio />} label="Singola Entrata" />
                        </RadioGroup>
                    </FormControl>

                    {this.state.tipo == "pacchettoSocio"
                        || this.state.tipo == "Csen"
                        || this.state.tipo == "Aikikai"
                        ?
                        <SociSearcher
                            selectedSocioId={this.state.selectedSocioId}
                            onSelect={(id, selectedSocio) => {
                                this.props.getSociById(id, this.props.logininfo.id)
                                this.props.getSociMensiliBySocioIdAnnoAccademico(id, this.props.AnnoAccademico, this.props.logininfo.id)
                                this.setState({ selectedSocio: selectedSocio })
                                this.setState({ selectedSocioId: id })
                                this.setState({ selectedEsterno: null })
                                this.setState({ selectedEsternoId: null })

                                if (this.state.tipo == "Csen") {
                                    this.props.Prezzi.filter((mm) => {

                                        console.log(mm)
                                        console.log('selectedSocio.AdultoBambino', selectedSocio.AdultoBambino)

                                        if (mm.TipoDiMensile == this.state.tipo &&
                                            mm.AdultoBambino == selectedSocio.AdultoBambino) {
                                            this.setState({ Importo: mm.Importo })
                                        }
                                    })

                                } else {
                                    console.log('onSelect=>defaultValue', defaultValue)
                                    this.setState({ ...defaultValue })
                                }


                            }}
                        />
                        : this.state.tipo == "Consulenza"
                            ?
                            <SociPiuEsternoSearcher
                                selectedSocioId={this.state.selectedSocioId}
                                onSelect={(selectedSocio, selectedEsterno) => {

                                    this.setState({ ...defaultValue })

                                    this.setState({ Descrizione1: 'LEZIONE PRIVATA AIKIDO' }) //TODO

                                    //this.props.getSociById(id, this.props.logininfo.id)
                                    if (selectedSocio != null) {
                                        this.setState({ selectedSocio: selectedSocio })
                                        this.setState({ selectedSocioId: selectedSocio.id })
                                        this.setState({ selectedEsterno: null })
                                        this.setState({ selectedEsternoId: null })
                                        this.setState({ Descrizione2: selectedSocio.NomeSocio })

                                        if (selectedSocio.CodiceFiscale == null) {
                                            this.setState({ Descrizione3: 'C.F.NOTREGISTERED' })
                                        } else {
                                            this.setState({ Descrizione3: 'C.F.' + selectedSocio.CodiceFiscale })
                                        }
                                    }

                                    if (selectedEsterno != null) {
                                        this.setState({ selectedSocio: null })
                                        this.setState({ selectedSocioId: null })
                                        this.setState({ selectedEsterno: selectedEsterno })
                                        this.setState({ selectedEsternoId: selectedEsterno.id })
                                        this.setState({ Descrizione2: selectedEsterno.NomeEsterno })

                                        if (selectedEsterno.CodiceFiscale == null) {
                                            this.setState({ Descrizione3: 'C.F.NOTREGISTERED' })
                                        } else {
                                            this.setState({ Descrizione3: 'C.F.' + selectedEsterno.CodiceFiscale })
                                        }

                                    }

                                }}
                            />
                            : this.state.tipo == "SingolaEntrata"
                                ?
                                <SociSearcher
                                    selectedSocioId={this.state.selectedSocioId}
                                    onSelect={(id, selectedSocio) => {
                                        this.props.getSociById(id, this.props.logininfo.id)
                                        this.props.getSociMensiliBySocioIdAnnoAccademico(id, this.props.AnnoAccademico, this.props.logininfo.id)
                                        console.log('onSelect=>defaultValue', defaultValue)
                                        this.setState({ ...defaultValue })


                                        this.setState({ selectedSocio: selectedSocio })
                                        this.setState({ selectedSocioId: id })
                                        this.setState({ selectedEsterno: null })
                                        this.setState({ selectedEsternoId: null })


                                        this.setState({ Descrizione1: 'Singola Entrata' })



                                    }}
                                />
                                : null
                    }

                </Grid>




                {
                    this.state.tipo == "pacchettoSocio"
                        && this.state.selectedSocioId
                        && this.state.selectedSocio
                        ? <Grid xs={10} >
                            <SociMensileSimple
                                socio={this.state.selectedSocio}
                                socioMensile={this.props.sociMensiliCache[this.state.selectedSocioId]}
                                setPagamentoId={(id) => { this.setState({ pagamentoId: id }) }}
                                setOpenModalTrue={() => { this.setState({ openModal: true }) }}
                                getPagamentoBySocioId={(socioId) => { this.props.getPagamentoBySocioId(socioId, this.props.logininfo.id) }}
                                value={this.state}
                                setState={(name, value) => {
                                    var obj = {};
                                    obj[name] = value;
                                    this.setState(obj);
                                }}
                                
                            />
                        </Grid>
                        : null
                }

                {
                    this.state.tipo == "Csen"
                        && this.state.selectedSocioId
                        && this.state.selectedSocio
                        ?
                        <Grid xs={10} >
                            <PagamentoCsen
                                socio={this.state.selectedSocio}
                                socioMensile={this.props.sociMensiliCache[this.state.selectedSocioId]}
                                setPagamentoId={(id) => { this.setState({ pagamentoId: id }) }}
                                setOpenModalTrue={() => { this.setState({ openModal: true }) }}
                                getPagamentoBySocioId={(socioId) => { this.props.getPagamentoBySocioId(socioId, this.props.logininfo.id) }}
                                value={this.state}
                                ImportoCsen={this.state.Importo}
                                setState={(name, value) => {
                                    var obj = {};
                                    obj[name] = value;
                                    this.setState(obj);
                                }}
                            />
                        </Grid>

                        : null
                }
                {
                    this.state.tipo == "Aikikai"
                        && this.state.selectedSocioId
                        && this.state.selectedSocio
                        ?
                        <Grid xs={10} >
                            <PagamentoAikikai
                                socio={this.state.selectedSocio}
                                socioMensile={this.props.sociMensiliCache[this.state.selectedSocioId]}
                                setPagamentoId={(id) => { this.setState({ pagamentoId: id }) }}
                                setOpenModalTrue={() => { this.setState({ openModalWithoutRicevuta: true }) }}
                                getPagamentoBySocioId={(socioId) => { this.props.getPagamentoBySocioId(socioId, this.props.logininfo.id) }}
                                value={this.state}
                                setState={(name, value) => {
                                    var obj = {};
                                    obj[name] = value;
                                    this.setState(obj);
                                }}
                            />
                        </Grid>

                        : null
                }

                {
                    this.state.tipo == "Consulenza" ?
                        (this.state.selectedSocioId && this.state.selectedSocio)
                            ? <Grid xs={10} >
                                <PagamentoAltro
                                    isSocio={true}
                                    isEsterno={false}
                                    socio={this.state.selectedSocio}
                                    esterno={null}
                                    setPagamentoId={(id) => { this.setState({ pagamentoId: id }) }}
                                    setOpenModalTrue={() => { this.setState({ openModal: true }) }}
                                    getPagamentoBySocioId={(socioId) => { this.props.getPagamentoBySocioId(socioId, this.props.logininfo.id) }}
                                    getPagamentoById={(pagamentoId) => { this.props.getPagamentoById(pagamentoId, this.props.logininfo.id) }}
                                    value={this.state}
                                    setState={(name, value) => {
                                        var obj = {};
                                        obj[name] = value;
                                        this.setState(obj);
                                    }}
                                />
                            </Grid>
                            : (this.state.selectedEsternoId && this.state.selectedEsterno)
                                ? <Grid xs={10} >
                                    <PagamentoAltro
                                        isSocio={false}
                                        isEsterno={true}
                                        socio={null}
                                        esterno={this.state.selectedEsterno}
                                        setPagamentoId={(id) => { this.setState({ pagamentoId: id }) }}
                                        setOpenModalTrue={() => { this.setState({ openModal: true }) }}
                                        getPagamentoBySocioId={(socioId) => { this.props.getPagamentoBySocioId(socioId, this.props.logininfo.id) }}
                                        getPagamentoById={(pagamentoId) => { this.props.getPagamentoById(pagamentoId, this.props.logininfo.id) }}
                                        value={this.state}
                                        setState={(name, value) => {
                                            var obj = {};
                                            obj[name] = value;
                                            this.setState(obj);
                                        }}
                                    />
                                </Grid>
                                : null


                        : null
                }

                {
                    this.state.tipo == "SingolaEntrata"
                        && this.state.selectedSocioId
                        && this.state.selectedSocio
                        ? <Grid xs={10} >
                            <PagamentoSingolaEntrata
                                isSocio={true}
                                isEsterno={false}
                                socio={this.state.selectedSocio}
                                esterno={null}
                                setPagamentoId={(id) => { this.setState({ pagamentoId: id }) }}
                                setOpenModalTrue={() => { this.setState({ openModal: true }) }}
                                getPagamentoBySocioId={(socioId) => { this.props.getPagamentoBySocioId(socioId, this.props.logininfo.id) }}
                                getPagamentoById={(pagamentoId) => { this.props.getPagamentoById(pagamentoId, this.props.logininfo.id) }}
                                value={this.state}
                                setState={(name, value) => {
                                    var obj = {};
                                    obj[name] = value;
                                    this.setState(obj);
                                }}
                            />
                        </Grid>
                        : null
                }

                {this.state.tipo == "pacchettoSocio"
                    || this.state.tipo == "Csen"
                    ? <PagamentoDetailModal
                        socioId={this.state.selectedSocioId}
                        openModal={this.state.openModal}
                        pagamentoId={this.state.pagamentoId}
                        setOpenModalFalse={() => { this.setState({ openModal: false }) }}
                    />
                    : null}

                {this.state.tipo == "Aikikai"
                    ? <PagamentoDetailModalSenzaRicevuta
                        socioId={this.state.selectedSocioId}
                        openModal={this.state.openModalWithoutRicevuta}　　///////this.state.tipoで分かれているからopenModalWithoutRicevutaつかわなくてもいける
                        pagamentoId={this.state.pagamentoId}
                        setOpenModalFalse={() => { this.setState({ openModalWithoutRicevuta: false }) }}
                    />
                    : null}

                {this.state.tipo == "Consulenza"
                    ? <PagamentoAltroDetailModal
                        name={this.state.selectedSocio ? this.state.selectedSocio.NomeSocio : this.state.selectedEsterno ? this.state.selectedEsterno.NomeEsterno : null}
                        socioOrEsterno={this.state.selectedSocio ? this.state.selectedSocio : this.state.selectedEsterno}
                        openModal={this.state.openModal}
                        pagamentoId={this.state.pagamentoId}
                        setOpenModalFalse={() => { this.setState({ openModal: false }) }}
                    />
                    : null}


                {this.state.tipo == "SingolaEntrata"
                    ? <PagamentoAltroDetailModal
                        name={this.state.selectedSocio ? this.state.selectedSocio.NomeSocio : this.state.selectedEsterno ? this.state.selectedEsterno.NomeEsterno : null}
                        socioOrEsterno={this.state.selectedSocio ? this.state.selectedSocio : this.state.selectedEsterno}
                        openModal={this.state.openModal}
                        pagamentoId={this.state.pagamentoId}
                        setOpenModalFalse={() => { this.setState({ openModal: false }) }}
                    />
                    : null}



            </Grid >

        )
    }

}



const mapStateToProps = (state) => {
    return {
        sociList: state.model.Socio,
        sociCache: state.cache.sociCache,
        pagamentoCache: state.pagamentoCache,
        socket: state.webSocket.socket,
        openModal: state.pagamento.transactionComplete,
        AnnoAccademico: state.model.AnnoAccademico,
        sociMensiliCache: state.sociMensiliCache,
        Prezzi: state.model.Prezzi,
        logininfo: state.auth.logininfo


    }
}

const mapDispatchToProps = (dispatch) => {

    return {
        getSociById: (id, access_token) => {
            dispatch(ModelAction.apiGetById('Socio', id, access_token))
        },
        getSociMensiliBySocioIdAnnoAccademico: (socioId, annoAccademico, access_token) => {
            dispatch(SocioMensileAction.getSociMensiliBySocioIdAnnoAccademico(socioId, annoAccademico, access_token))
        },
        transactionMessageClose: () => {
            dispatch(PagamentoAction.transactionMessageClose())
        },
        getPagamentoBySocioId: (socioId, access_token) => {
            dispatch(PagamentoAction.getPagamentoBySocioId(socioId, access_token))
        },
        getPagamentoById: (id, access_token) => {
            dispatch(PagamentoAction.getPagamentoById(id, access_token))
        },
        getPrezzi: (access_token) => {
            //全件取得
            dispatch(ModelAction.apiGet('Prezzi', 1, 1000, [], access_token))

        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Pagamento));
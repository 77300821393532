import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import './component/css/styles.css'
import { connect } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  useParams,
  useHistory,
  useLocation,
} from 'react-router-dom'
import LoginGetParam from './component/pages/LoginGetParam'
import PageWrapper from './component/common/PageWrapper'
import Sample from './component/pages/admin/Sample'
import Soci from './component/pages/admin/Soci'
import AdminPagamento from './component/pages/admin/Pagamento'
import Pagamento from './component/pages/Pagamento'
import SociMensile from './component/pages/SociMensile'
import AdminSociMensile from './component/pages/admin/SociMensile'
import ElencoIscritti from './component/pages/ElencoIscritti'
import PagamentoList from './component/pages/PagamentoList'
import Prezzi from './component/pages/admin/Prezzi'
import Esterno from './component/pages/admin/Esterno'
import Stipendi from './component/pages/Stipendi/CrudForUserStipendi'
import AdminPresenze from './component/pages/admin/Presenze'

import Presenze from './component/pages/presenze/CrudPresenze'


import Staff from './component/pages/admin/Staff'
import Ricevuta from './component/pages/admin/Ricevuta'
import PageRicevuta from './component/pages/Ricevuta'
import FileManager from './component/pages/FileManager'
import PasswordGetVarification from './component/pages/PasswordGetVarification'
import ChangePassword from './component/pages/ChangePassword'


import Dashboard from './component/pages/admin/Dashboard'



import "bootstrap/dist/css/bootstrap.min.css"
import "./component/css/custom.css"


class App extends Component {


  render() {


    console.log('this.props.location App', this.props.location)
    console.log('this.props.role', this.props.role)

    return (
      <div>
        {this.props.logininfo != null
          ? this.props.role == 'jyushin'
            ?
            <Router>
              <Route exact
                path="/"
                render={() => {
                  return <PageWrapper><Dashboard /></PageWrapper>
                }}
              />

              <Route exact
                path="/calendariopagamenti"
                render={() => {
                  return <PageWrapper><SociMensile /></PageWrapper>
                }}
              /><Route exact
                path="/pagamento"
                render={() => {
                  return <PageWrapper><Pagamento /></PageWrapper>
                }}
              /><Route exact
                path="/modal/Pagamento"
                render={() => {
                  return <Pagamento />
                }}
              />

              <Route exact
                path="/presenze"
                render={() => {
                  return <PageWrapper><Presenze /></PageWrapper>
                }}
              />
              <Route exact
                path="/stipendi"
                render={() => {
                  return <PageWrapper><Stipendi /></PageWrapper>
                }}
              />

              <Route exact
                path="/admin/socimensile"
                render={() => {
                  return <PageWrapper><AdminSociMensile /></PageWrapper>
                }}
              />
              <Route exact
                path="/admin/elencoiscritti"
                render={() => {
                  return <PageWrapper><ElencoIscritti /></PageWrapper>
                }}
              />
              <Route exact
                path="/admin/sample"
                render={() => {
                  return <PageWrapper><Sample /></PageWrapper>
                }}
              />
              <Route exact
                path="/admin/soci"
                render={() => {
                  return <PageWrapper><Soci /></PageWrapper>
                }}
              />
              <Route exact
                path="/admin/pagamento"
                render={() => {
                  return <PageWrapper><AdminPagamento /></PageWrapper>
                }}
              />
              <Route exact
                path="/admin/prezzi"
                render={() => {
                  return <PageWrapper><Prezzi /></PageWrapper>
                }}
              />
              <Route exact
                path="/admin/esterno"
                render={() => {
                  return <PageWrapper><Esterno /></PageWrapper>
                }}
              />

              <Route exact
                path="/admin/presenze"
                render={() => {
                  return <PageWrapper><AdminPresenze /></PageWrapper>
                }}
              />
              <Route exact
                path="/admin/staff"
                render={() => {
                  return <PageWrapper><Staff /></PageWrapper>
                }}
              />





              <Route exact
                path="/admin/pagamentolist"
                render={() => {
                  return <PageWrapper><PagamentoList /></PageWrapper>
                }}
              />
              <Route exact
                path="/admin/ricevuta"
                render={() => {
                  return <PageWrapper><Ricevuta /></PageWrapper>
                }}
              />
              <Route exact
                path="/ricevuta"
                render={() => {
                  return <PageWrapper><PageRicevuta /></PageWrapper>
                }}
              />
              <Route exact
                path="/filemanager"
                render={() => {
                  return <PageWrapper><FileManager /></PageWrapper>
                }}
              />
              <Route exact
                path="/changepassword"
                render={() => {
                  return <PageWrapper><ChangePassword /></PageWrapper>
                }}
              />
            </Router>
            : this.props.role == 'staff'
              ? <Router><Route exact
                path="/"
                render={() => {
                  return <PageWrapper><h5>Staff Root</h5></PageWrapper>
                }}
              />
                <Route exact
                  path="/presenze"
                  render={() => {
                    return <PageWrapper><Presenze /></PageWrapper>
                  }}
                />

              </Router>
              : <Router>
                <Route exact
                  path="/"
                  render={() => {
                    return <LoginGetParam />
                  }}
                />
              </Router>
          : <Router>
            <Route exact
              path="/"
              render={() => {
                return <LoginGetParam />
              }}
            />
          </Router>



        }
      </div>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    logininfo: state.auth.logininfo,
    role: state.auth.role
  }

}

const mapDispatchToProps = (dispatch) => {

  return {

  }

}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);

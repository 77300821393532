import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import { withStyles } from "@material-ui/core/styles";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import * as ModelAction from '../../../store/actions/ModelAction'
import * as PresenzeAction from '../../../store/actions/PresenzeAction'

import { connect } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import { createMuiTheme } from '@material-ui/core'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import * as DateTool from '../../../util/DateTool';
import * as StrTool from '../../../util/StrTool';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import * as NumberTool from '../../../util/NumTool';
import AutoModeIconcal from '@mui/icons-material/AutoMode';

import StaffSearcher from '../../common/StaffSearcher';


import { styles } from '../../css/commonstyles'
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';


const customTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#bfbfbf',
            light: '#969696',
            dark: '#616060',
        },
        secondary: {
            main: '#616060',
        },
    },
})




var defaultFormValue = {};

class CrudModalForUserUpdateStipendi extends Component {

    constructor(props) {
        super(props);

        var submitDisabled = false

        /*
        Object.keys(this.props.modelProps).forEach((k) => {

            if (k != "id") {
                switch (this.props.modelProps[k].type) {
                    case "number":
                        defaultFormValue[k] = 0;
                        return;
                    case "string":
                        if (this.props.modelProps[k].defaultValue) {
                            defaultFormValue[k] = this.props.modelProps[k].defaultValue;
                        } else {
                            defaultFormValue[k] = "";
                        }
                        return;
                    case "date":
                        defaultFormValue[k] = DateTool.createJSONDateWithoutTime(new Date());
                        return;
                    case "time":
                        defaultFormValue[k] = DateTool.createJSONDateWithoutTime(new Date());
                        return;
                    case "select":
                        defaultFormValue[k] = "";
                        return;

                }

                //入力必須項目がある場合は Update　ボタンを非活性にする
                //if (this.props.modelProps[k].inputRequired == true ){
                //    submitDisabled = true
                //}
            }


        })

        */
        this.state = null;
        this.state = {
            formValue: {}, //初期値がないと一覧レンダーで落ちる
            unfilledRequiredFIeld: false,
            openUpdateModal: false,

        };

    }

    componentDidMount() {

    }



    handlUpdateModalOpen = (model) => {
        console.log("handlUpdateModalOpen", model)

        this.setState({
            openUpdateModal: true,
            formValue: model,
            unfilledRequiredFIeld: false

        })
    };

    handleModalClose = () => {
        this.setState({ openUpdateModal: false })
    };



    onChangeInputCommon = (key, value) => {


        var newFormValue = this.state.formValue;
        newFormValue[key] = value;
        this.setState({ formValue: newFormValue });



        this.checkInputRequired()

    }


    onChangeInputNumCommon = (key, value) => {
        this.setState({ formValue: NumberTool.validateAndSetNewFormValue(key, value, this.state.formValue) });
    }


    handleDateTimeChange = (datetime, key) => {

        var newFormValue = this.state.formValue;

        if (datetime == null || datetime == "") {
            newFormValue[key] = null;
        } else {
            newFormValue[key] = DateTool.createJSONDateWithoutTime(datetime);
        }
        this.setState({ formValue: newFormValue });

    }

    checkInputRequired = (UserId, Dal, Al, hLav, CostoOrario, Stipendio, Acconto, Saldo, Pagato) => {





        console.log('UserId', UserId)

        console.log('Dal', Dal)
        console.log('Al', Al)
        console.log('hLav', hLav)
        console.log('CostoOrario', CostoOrario)
        console.log('Stipendio', Stipendio)
        console.log('Acconto', Acconto)
        console.log('Saldo', Saldo)
        console.log('Pagato', Pagato)


        var unfilledRequiredFIeld = false;

        if (UserId == null) {
            unfilledRequiredFIeld = true;
        }

        if (Dal == null) {
            unfilledRequiredFIeld = true;
        }
        if (Al == null) {
            unfilledRequiredFIeld = true;
        }


        if (Stipendio == null || Stipendio === '') {
            unfilledRequiredFIeld = true;
        }

        if (Pagato == null) {
            unfilledRequiredFIeld = true;
        }
        //空文字と 0 を 等価演算子 (==) で比較すると true になるため、ここでは 0 が来たときに想定通りの挙動にはなりません。
        //ここでは 厳密等価演算子 (===) で比較します。

        //if (Ore == null || Ore === '') {
        //    unfilledRequiredFIeld = true;
        //}


        this.setState({ unfilledRequiredFIeld: unfilledRequiredFIeld })
        console.log('unfilledRequiredFIeld', unfilledRequiredFIeld)


    }

    checkStaffDalAl = () => {
        var disabled = false;

        if (this.state.formValue['Staff'] == null) {
            disabled = true
        }
        if (this.state.formValue['Dal'] == null) {
            disabled = true
        }
        if (this.state.formValue['Dal'] == '') {
            disabled = true
        }
        if (this.state.formValue['Al'] == null) {
            disabled = true
        }
        if (this.state.formValue['Al'] == '') {
            disabled = true
        }

        return disabled
    }

    render() {

        const { classes } = this.props;

        return (
            <div className={classes.cell_clickable}>
                <div onClick={(e) => { e.preventDefault(); this.handlUpdateModalOpen(this.props.modelValue) }}>
                    <EditIcon />
                </div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={this.state.openUpdateModal}
                    onClose={this.handleModalClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.openUpdateModal}>
                        <div className={classes.modalpaper}>

                            <Grid container >
                                <Grid item xs={12}  >
                                    <h4 id="transition-modal-title">{this.props.modelName}</h4>
                                </Grid>


                                <Grid item xs={12}  >
                                    <form className={classes.root} noValidate autoComplete="on">
                                        <Grid container justify="left"  >
                                            <Grid item xs={12} key={'form_Staff'}  >
                                                <FormControl className={classes.formControlM}>
                                                    <FormLabel component="legend">Staff*</FormLabel>
                                                    <h4> {this.state.formValue.Staff && this.state.formValue.Staff.Nome + ' ' + this.state.formValue.Staff.Cognome}</h4>

                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} key={'form_Dal'}  >
                                                <FormControl className={classes.formControlM}>
                                                    <MuiThemeProvider theme={customTheme}>
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                            <KeyboardDatePicker
                                                                margin="normal"
                                                                id="date-picker-dialog"
                                                                label='Dal*'
                                                                format="dd/MM/yyyy"
                                                                value={this.state.formValue['Dal']}
                                                                onChange={(e) => { this.handleDateTimeChange(e, 'Dal') }}
                                                                KeyboardButtonProps={{
                                                                    'aria-label': 'change date',
                                                                }}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </MuiThemeProvider>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} key={'form_Al'}  >
                                                <FormControl className={classes.formControlM}>
                                                    <MuiThemeProvider theme={customTheme}>
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                            <KeyboardDatePicker
                                                                margin="normal"
                                                                id="date-picker-dialog"
                                                                label='Al*'
                                                                format="dd/MM/yyyy"
                                                                value={this.state.formValue['Al']}
                                                                onChange={(e) => { this.handleDateTimeChange(e, 'Al') }}
                                                                KeyboardButtonProps={{
                                                                    'aria-label': 'change date',
                                                                }}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </MuiThemeProvider>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} >
                                                <Button
                                                    variant="contained"
                                                    disabled={this.checkStaffDalAl()}
                                                    className={classes.formControlM}
                                                    onClick={(e) => {

                                                        this.setState({ apiCall: 'start' })
                                                        this.setState({ apiCallerror: null })

                                                        this.props.getStipendiFromPresenza(this.state.formValue['UserId'],
                                                            this.state.formValue['Dal'],
                                                            this.state.formValue['Al'],
                                                            this.props.logininfo.id,
                                                            (bool, msg, total, CostoOrario, Stipendio) => {
                                                                console.log('sss')


                                                                if (bool) {
                                                                    this.setState({ apiCall: 'end' })


                                                                    if (total == 0) {
                                                                        this.onChangeInputNumCommon('hLav', 0)
                                                                        //this.onChangeInputNumCommon('CostoOrario',CostoOrario) CostoOrarioはマスタ保管のものを使う
                                                                        this.onChangeInputNumCommon('Stipendio', 0)

                                                                        this.checkInputRequired(
                                                                            this.state.formValue['UserId'],
                                                                            this.state.formValue['Dal'],
                                                                            this.state.formValue['Al'],
                                                                            0,
                                                                            this.state.formValue['CostoOrario'],
                                                                            0,
                                                                            this.state.formValue['Acconto'],
                                                                            this.state.formValue['Saldo'],
                                                                            this.state.formValue['Pagato'])

                                                                    } else {

                                                                        console.log('★', total, CostoOrario, Stipendio)

                                                                        this.onChangeInputNumCommon('hLav', total)
                                                                        this.onChangeInputNumCommon('CostoOrario', CostoOrario)
                                                                        this.onChangeInputNumCommon('Stipendio', Stipendio)

                                                                        this.checkInputRequired(
                                                                            this.state.formValue['UserId'],
                                                                            this.state.formValue['Dal'],
                                                                            this.state.formValue['Al'],
                                                                            total,
                                                                            CostoOrario,
                                                                            Stipendio,
                                                                            this.state.formValue['Acconto'],
                                                                            this.state.formValue['Saldo'],
                                                                            this.state.formValue['Pagato'])

                                                                    }

                                                                } else {

                                                                    if (msg == '') {
                                                                        //ほんとのエラー
                                                                        this.onChangeInputNumCommon('hLav', 0)
                                                                        //this.onChangeInputNumCommon('CostoOrario', CostoOrario)
                                                                        this.onChangeInputNumCommon('Stipendio', 0)

                                                                        this.checkInputRequired(
                                                                            this.state.formValue['UserId'],
                                                                            this.state.formValue['Dal'],
                                                                            this.state.formValue['Al'],
                                                                            0,
                                                                            this.state.formValue['CostoOrario'],
                                                                            0,
                                                                            this.state.formValue['Acconto'],
                                                                            this.state.formValue['Saldo'],
                                                                            this.state.formValue['Pagato'])

                                                                    } else {
                                                                        //costo orario 複数エラー

                                                                        this.onChangeInputNumCommon('hLav', total)
                                                                        this.onChangeInputNumCommon('CostoOrario', '')
                                                                        this.onChangeInputNumCommon('Stipendio', '')

                                                                        this.checkInputRequired(
                                                                            this.state.formValue['UserId'],
                                                                            this.state.formValue['Dal'],
                                                                            this.state.formValue['Al'],
                                                                            total,
                                                                            '',
                                                                            '',
                                                                            this.state.formValue['Acconto'],
                                                                            this.state.formValue['Saldo'],
                                                                            this.state.formValue['Pagato'])
                                                                    }


                                                                    this.setState({ apiCall: 'error' })
                                                                    this.setState({ apiCallerror: msg })
                                                                }
                                                            })


                                                    }}>Scarica da presenze</Button>

                                            </Grid>







                                            <Grid item xs={12} key={'form_hLav'}  >
                                                <FormControl className={classes.formControlM}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label='hLav'
                                                        value={this.state.formValue['hLav']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputNumCommon('hLav', e.target.value)
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} key={'form_CostoOrario'}  >
                                                <FormControl className={classes.formControlM}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label='CostoOrario'
                                                        value={this.state.formValue['CostoOrario']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputNumCommon('CostoOrario', e.target.value)
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} key={'form_Stipendio'}  >
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="flex-start"
                                                    alignItems="flex-end">
                                                    <Grid item xs={8} >
                                                        <FormControl className={classes.formControlS}>
                                                            <TextField
                                                                id="standard-basic"
                                                                label='Stipendio*'
                                                                value={this.state.formValue['Stipendio']}
                                                                onChange={(e) => {
                                                                    e.preventDefault()
                                                                    this.onChangeInputNumCommon('Stipendio', e.target.value)
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={4} >
                                                        <Button
                                                            disabled={this.state.formValue['hLav'] === null ||
                                                                this.state.formValue['hLav'] === '' ||
                                                                this.state.formValue['CostoOrario'] === null ||
                                                                this.state.formValue['CostoOrario'] === ''
                                                            }
                                                            aria-controls="simple-menu"
                                                            aria-haspopup="true"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                var s = NumberTool.calcMultiple(this.state.formValue['hLav'], this.state.formValue['CostoOrario'])

                                                                console.log('this.state.formValue[hLav]', this.state.formValue['hLav'])
                                                                console.log('this.state.formValue[CostoOrario]', this.state.formValue['CostoOrario'])
                                                                console.log('s', s)
                                                                this.onChangeInputCommon('Stipendio', s) //数値チェックは不要
                                                            }
                                                            } >
                                                            <AutoModeIconcal />{'calcola'}
                                                        </Button>
                                                    </Grid>
                                                </Grid>

                                            </Grid>


                                            <Grid item xs={12} key={'form_Acconto'}  >
                                                <FormControl className={classes.formControlM}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label='Acconto'
                                                        value={this.state.formValue['Acconto']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputNumCommon('Acconto', e.target.value)
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} key={'form_Saldo'}  >
                                                <FormControl className={classes.formControlM}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label='Saldo'
                                                        value={this.state.formValue['Saldo']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputNumCommon('Saldo', e.target.value)
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} key={'form_Pagato'}  >
                                                <FormControl className={classes.formControlM}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label='Pagato'
                                                        value={this.state.formValue['Pagato']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputCommon('Pagato', e.target.value)
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} >
                                                <Button variant="contained"

                                                    disabled={this.state.formValue.Staff === null ||
                                                        this.state.formValue.Stipendio === null ||
                                                        this.state.formValue.Stipendio === '' ||
                                                        this.state.formValue.Dal === null ||
                                                        this.state.formValue.Dal === '' ||
                                                        this.state.formValue.Al === null ||
                                                        this.state.formValue.Al === ''

                                                    }
                                                    onClick={(e) => {
                                                        e.preventDefault();

                                                        //最終更新者・日時を追加
                                                        var newFormValue = this.
                                                            state.formValue;
                                                        newFormValue["LastModifiedBy"] = this.props.logininfo.userId;
                                                        newFormValue["LastModifiedAt"] = DateTool.returnItalianTime();

                                                        if (this.state.formValue.Acconto == '') {
                                                            newFormValue["Acconto"] = null
                                                        }

                                                        if (this.state.formValue.Saldo == '') {
                                                            newFormValue["Saldo"] = null
                                                        }


                                                        this.setState({ formValue: newFormValue });
                                                        this.props.submitUpdate(
                                                            this.props.socket,
                                                            'Stipendi',
                                                            this.props.page,
                                                            this.props.numData,
                                                            this.state.formValue,
                                                            ['User', 'Staff'],
                                                            ['Dal DESC'],
                                                            this.props.logininfo.id
                                                        );
                                                        this.handleModalClose();



                                                    }}>Update</Button>
                                            </Grid>
                                        </Grid >
                                    </form>
                                </Grid>
                            </Grid>



                        </div>


                    </Fade>
                </Modal >
            </div>

        );
    }
}


const mapStateToProps = (state) => {
    return {
        logininfo: state.auth.logininfo

    }
}

const mapDispatchToProps = (dispatch) => {

    return {

        submitUpdate: (socket, modelName, page, numData, formValue, arrInclude, order, access_token) => {
            dispatch(ModelAction.apiPut_arr_order(socket, modelName, page, numData, formValue, arrInclude, order, access_token))

        },
        getStipendiFromPresenza: (userId, dal, al, access_token, cb) => {
            dispatch(PresenzeAction.apiGet_stipendi_from_presenza(userId, dal, al, access_token, cb))
        },


    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(CrudModalForUserUpdateStipendi));
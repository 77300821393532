import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import { connect } from 'react-redux';
import classNames from 'classnames'
import { createMuiTheme } from '@material-ui/core'
import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';

import FormControl from '@material-ui/core/FormControl';
import SociSearcher from './SociSearcher';
import * as ModelAction from '../../store/actions/ModelAction';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import SociMensileSimple from './SociMensileSimple'
import * as PagamentoAction from '../../store/actions/PagamentoAction'
import * as SocioMensileAction from '../../store/actions/SocioMensileAction'
import * as RicevutaAction from '../../store/actions/RicevutaAction'
import CloseIcon from '@material-ui/icons/Close';
import PagamentoDetailModal from './PagamentoDetailModal'
import PagamentoDetailModalSenzaRicevuta from './PagamentoDetailModalSenzaRicevuta'
import PagamentoCsen from './PagamentoCsen'
import PagamentoAikikai from './PagamentoAikikai'

import Button from '@material-ui/core/Button';

const styles = theme => ({

    margin: {
        margin: theme.spacing(1),
    },
    sociList: {
        width: 210
    },
    paper: {
        width: 600,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3)
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttonEmettereRicevuta: {
        backgroundColor: '#ffd700'
    }

});



const defaultValue = {
    tipoDiMensile: null,
    OttobreChecked: false,
    NovembreChecked: false,
    DicembreChecked: false,
    GennaioChecked: false,
    FebbraioChecked: false,
    MarzoChecked: false,
    AprileChecked: false,
    MaggioChecked: false,
    GiugnoChecked: false,
    LuglioChecked: false,
    monthToPay: [],
    Contratto: null,
    Descrizione1: "",
    Descrizione2: "",
    Descrizione3: "",
    allSelected: false,
    Pacchetto: null,
    AikidoChecked: false,
    MuayThaiChecked: false,
    BJJChecked: false,
    Corsi: [],
    Importo: null,
    ImportoAlMese: null,
    DataDiPagamento: new Date(),
    MetodoDiPagamento: null,
}


class PagamentoSocioSelected extends Component {

    constructor(props) {
        super(props);

        this.state = {
            tipo: this.props.tipo,
            openModal: false,
            openModalWithoutRicevuta: false,
            PasswordForRicevuta: null,
            pagamentoId: null,
            ricevutaFileName: null,
            ...defaultValue,
        };



    }

    componentDidMount() {

    }

    handleInputPasswordForRicevuta = (e) => {
        this.setState({ PasswordForRicevuta: e.target.value })
    }


    render() {

        const { classes } = this.props;



        return (



            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
            >



                {
                    this.props.tipo == "pacchettoSocio"
                        && this.props.selectedSocioId
                        && this.props.selectedSocio
                        ? <Grid xs={8} >
                            <SociMensileSimple
                                socio={this.props.selectedSocio}
                                socioMensile={this.props.sociMensiliCache[this.props.selectedSocioId]}
                                setPagamentoId={(id) => { this.setState({ pagamentoId: id }) }}
                                setOpenModalTrue={() => { this.setState({ openModal: true }) }}
                                getPagamentoBySocioId={(socioId) => { this.props.getPagamentoBySocioId(socioId,this.props.logininfo.id) }}
                                value={this.state}
                                setState={(name, value) => {
                                    var obj = {};
                                    obj[name] = value;
                                    this.setState(obj);
                                }}
                            />
                        </Grid>
                        : null
                }

                {
                    this.state.tipo == "Csen"
                        && this.props.selectedSocioId
                        && this.props.selectedSocio
                        && this.props.Importo
                        ?
                        <Grid xs={8} >
                            <PagamentoCsen
                                socio={this.props.selectedSocio}
                                socioMensile={this.props.sociMensiliCache[this.props.selectedSocioId]}
                                setPagamentoId={(id) => { this.setState({ pagamentoId: id }) }}
                                setOpenModalTrue={() => { this.setState({ openModal: true }) }}
                                getPagamentoBySocioId={(socioId) => { this.props.getPagamentoBySocioId(socioId,this.props.logininfo.id) }}
                                value={this.state}
                                ImportoCsen={this.props.Importo}
                                setState={(name, value) => {
                                    var obj = {};
                                    obj[name] = value;
                                    this.setState(obj);
                                }}
                            />
                        </Grid>

                        : null
                }
                {
                    this.state.tipo == "Aikikai"
                        && this.props.selectedSocioId
                        && this.props.selectedSocio
                        ?
                        <Grid xs={8} >
                            <PagamentoAikikai
                                socio={this.props.selectedSocio}
                                socioMensile={this.props.sociMensiliCache[this.props.selectedSocioId]}
                                setPagamentoId={(id) => { this.setState({ pagamentoId: id }) }}
                                setOpenModalTrue={() => { this.setState({ openModalWithoutRicevuta: true }) }}
                                getPagamentoBySocioId={(socioId) => { this.props.getPagamentoBySocioId(socioId,this.props.logininfo.id) }}
                                value={this.state}
                                setState={(name, value) => {
                                    var obj = {};
                                    obj[name] = value;
                                    this.setState(obj);
                                }}
                            />
                        </Grid>

                        : null
                }

                <PagamentoDetailModal
                    socioId={this.props.selectedSocioId}
                    openModal={this.state.openModal}
                    pagamentoId={this.state.pagamentoId}
                    setOpenModalFalse={() => { this.setState({ openModal: false }) }}
                />

                <PagamentoDetailModalSenzaRicevuta
                    socioId={this.props.selectedSocioId}
                    openModal={this.state.openModalWithoutRicevuta}
                    pagamentoId={this.state.pagamentoId}
                    setOpenModalFalse={() => { this.setState({ openModalWithoutRicevuta: false }) }}
                />

            </Grid >

        )
    }

}



const mapStateToProps = (state) => {
    return {
        sociList: state.model.Socio,
        sociCache: state.cache.sociCache,
        socket: state.webSocket.socket,
        openModal: state.pagamento.transactionComplete,
        AnnoAccademico: state.model.AnnoAccademico,
        sociMensiliCache: state.sociMensiliCache,
        Prezzi: state.model.Prezzi,
        logininfo: state.auth.logininfo

    }
}

const mapDispatchToProps = (dispatch) => {

    return {
        getSociById: (id,access_token) => {
            dispatch(ModelAction.apiGetById('Socio', id,access_token))
        },
        transactionMessageClose: () => {
            dispatch(PagamentoAction.transactionMessageClose())
        },
        getPagamentoBySocioId: (socioId,access_token) => {
            dispatch(PagamentoAction.getPagamentoBySocioId(socioId,access_token))
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(PagamentoSocioSelected));
import * as modelAction from './ModelAction'

export const setupWebSocket = (id) => {

    return (dispatch) => {

        console.log(setupWebSocket)
        let socket = new WebSocket("wss://jyushin-websocket.izan-jp.com/");

        socket.onopen = () => {
            dispatch({
                type: 'SETUP_WEBSOCKET',
                value: socket
            })
        }

        socket.onmessage = (message) => {
            console.log(message);
            let message_data = JSON.parse(message.data);

            switch (message_data.type) {
                case 'GET_MODELS':

                
                                                    window.location.reload(false);
                    //全件取得
                    //dispatch(modelAction.apiGetCount(message_data.modelName,id))
                    //dispatch(modelAction.apiGet(message_data.modelName, message_data.currentPage,  message_data.currentNumData, [],id));
                default:
            }

        }
    }


}
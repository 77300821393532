import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import { connect } from 'react-redux';
import classNames from 'classnames'
import { createMuiTheme } from '@material-ui/core'
import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import GridMensile from './GridMensile';
import { addMonths } from 'date-fns';
import Typography from '@material-ui/core/Typography';
import ModalDataSocio from './ModalDataSocio'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';

import * as ModelAction from '../../store/actions/ModelAction'
import TextField from '@material-ui/core/TextField';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import * as PagamentoAction from '../../store/actions/PagamentoAction'
import * as DateTool from '../../util/DateTool'


const styles = theme => ({

    descrizione: { width: 310 },
    card: { width: 350, height: 630, padding: 10 },
    cardInner: { width: 330, height: 480, padding: 10 },
    buttonPagato: { width: 310, height: 50 }


});


class PagamentoCsen extends Component {

    constructor(props) {
        super(props)
        this.state = this.props.value;
        this.props.getPrezzi(this.props.logininfo.id);
    }

    handleDateChangeDataDiPagamento = (date) => {
        this.props.setState('DataDiPagamento', date)
    }




    handleChangeMetodoDiPagamento = (e) => {
        this.props.setState('MetodoDiPagamento', e.target.value)

    }

    handleChangeDescrizione3 = (e) => {
        this.props.setState('Descrizione3', e.target.value)
    }


    render() {
        const { classes } = this.props;



        return (

            <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
            >
                <h1>{this.props.socio.NomeSocio}</h1>

                {this.props.socioMensile && this.props.socioMensile['QuotaCsenPagamentoId']
                    ? <h3>Pagato</h3>
                    : <Card className={classes.card}>
                        <Grid
                            container
                            direction="column"
                            justify="space-between"
                            alignItems="center"
                        >

                            <Grid
                                container
                                className={classes.cardInner}
                            >

                                <Grid xs={12} >
                                    <Typography variant="h5" component="h2">{this.props.socio.NomeSocio}({this.props.socio.AdultoBambino}) </Typography>
                                </Grid>
                                <Grid xs={12} >
                                    <Typography variant="h4" gutterBottom>Importo: €{this.props.ImportoCsen} </Typography>
                                </Grid>

                                <Grid xs={12} >


                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Grid container justify="space-around">
                                            <KeyboardDatePicker
                                                disableToolbar
                                                variant="inline"
                                                format="MM/dd/yyyy"
                                                margin="normal"
                                                id="DataDiPagamento"
                                                label="Data Di Pagamento"
                                                value={this.props.value.DataDiPagamento}
                                                onChange={this.handleDateChangeDataDiPagamento}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />

                                        </Grid>
                                    </MuiPickersUtilsProvider>


                                </Grid>
                                <Grid xs={12} >
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Metodo</FormLabel>
                                        <RadioGroup
                                            aria-label="Metodo"
                                            name="MetodoDiPagamento"
                                            value={this.props.value.MetodoDiPagamento}
                                            onChange={this.handleChangeMetodoDiPagamento}>
                                            <FormControlLabel value="Contanti" control={<Radio />} label="Contanti" />
                                            <FormControlLabel value="Bonifico" control={<Radio />} label="Bonifico" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid xs={12} >
                                    <TextField className={classes.descrizione}
                                        id="Descrizione3"
                                        label="Descrizione"
                                        multiline
                                        rowsMax={10}
                                        value={this.props.value.Descrizione3}
                                        onChange={this.handleChangeDescrizione3}
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                            <ArrowDownwardIcon />
                            <Button
                                disabled={this.props.value.DataDiPagamento && this.props.value.MetodoDiPagamento ? false : true}
                                className={classes.buttonPagato}
                                variant="contained"
                                color="secondary"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.props.createTransactionCsen(
                                        this.props.socio.id,
                                        this.props.AnnoAccademico,
                                        this.props.ImportoCsen,
                                        DateTool.createJSONDateWithoutTime(this.props.value.DataDiPagamento),
                                        this.props.value.MetodoDiPagamento,
                                        'Quota Assicurativa CSEN ' + this.props.AnnoAccademico,
                                        this.props.value.Descrizione3,
                                        null,
                                        this.props.value.tipo,
                                        this.props.setPagamentoId,
                                        this.props.setOpenModalTrue,
                                        this.props.getPagamentoBySocioId,
                                        this.props.logininfo.id
                                    );
                                }}>Archiviazione</Button>


                        </Grid>

                    </Card>}



            </Grid>

        )
    }



}



const mapStateToProps = (state) => {
    return {
        logininfo: state.auth.logininfo,
        AnnoAccademico: state.model.AnnoAccademico,
    }
}

const mapDispatchToProps = (dispatch) => {

    return {

        createTransactionCsen: (
            SocioId,
            AnnoAccademico,
            Importo,
            DataDiPagamento,
            MetodoDiPagamento,
            Descrizione1,
            Descrizione2,
            Descrizione3,
            tipo,
            setPagamentoId,
            setOpenModalTrue,
            getPagamentoBySocioId,
            access_token) => {
            dispatch(PagamentoAction.createTransactionCsen(
                SocioId,
                AnnoAccademico,
                Importo,
                DataDiPagamento,
                MetodoDiPagamento,
                Descrizione1,
                Descrizione2,
                Descrizione3,
                tipo,
                setPagamentoId,
                setOpenModalTrue,
                getPagamentoBySocioId,
                access_token))
        },
        getPrezzi: (access_token) => {
            //全件取得
            dispatch(ModelAction.apiGet('Prezzi', 1, 1000, [], access_token))
        }

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(PagamentoCsen));
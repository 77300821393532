import axios from 'axios';
import * as LoopbackCall from '../../util/LoopbackCall';
import * as StrTool from '../../util/StrTool';

export const getPagamentoList = (access_token) => {

    //transactionMensile

    return (dispatch) => {
        axios.post(LoopbackCall.url + `Pagamento/list_json`,
            null,
            { params: { access_token: access_token } }
        ).then((response) => {

            if (response.status == 200) {

                console.log("getPagamentoList");
                dispatch({ type: "GET_PAGAMENTOLIST", value: response.data })
            }



        }).catch((error) => {

            if (error.response.status === 401) {
                console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                dispatch({
                    type: "ACCESSTOKEN_EXPIRED"
                })
            }

            console.log("getPagamentoList失敗", error)
        })
    }
}


export const createTransactionAltro = (
    SocioId,
    EsternoId,
    AnnoAccademico,
    Importo,
    DataDiPagamento,
    MetodoDiPagamento,
    Descrizione1,
    Descrizione2,
    Descrizione3,
    tipo,
    Pacchetto,
    setPagamentoId,
    setOpenModalTrue,
    getPagamentoById,
    access_token) => {


    return (dispatch) => {
        axios.post(LoopbackCall.url + `Pagamento`, {
            'SocioId': SocioId,
            'EsternoId': EsternoId,
            'AnnoAccademico': AnnoAccademico,
            'Importo': Importo,
            'DataDiPagamento': DataDiPagamento,
            'MetodoDiPagamento': MetodoDiPagamento,
            'Descrizione1': Descrizione1,
            'Descrizione2': Descrizione2,
            'Descrizione3': Descrizione3,
            'Category': tipo  
        }, { params: { access_token: access_token } }).then((response) => {
            if (response.status == 200) {

                console.log("createTransactionAltro成功");
                dispatch({ type: "PAGAMENTO_COMPLETE" })
            }

            setPagamentoId(response.data.id);
            getPagamentoById(response.data.id, access_token);
            setOpenModalTrue();

        }).catch((error) => {

            if (error.response.status === 401) {
                console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                dispatch({
                    type: "ACCESSTOKEN_EXPIRED"
                })
            }

            console.log("createTransactionAltro失敗", error)
        })
    }
}


export const createTransactionAikikai = (
    SocioId,
    AnnoAccademico,
    Importo,
    DataDiPagamento,
    MetodoDiPagamento,
    Descrizione1,
    Descrizione2,
    Descrizione3,
    tipo,
    Pacchetto,
    setPagamentoId,
    setOpenModalTrue,
    getPagamentoBySocioId,
    access_token) => {

    //transactionMensile

    return (dispatch) => {
        axios.post(LoopbackCall.url + `Pagamento/transactionAikikai`, {
            'SocioId': SocioId,
            'AnnoAccademico': AnnoAccademico,
            'Importo': Importo,
            'DataDiPagamento': DataDiPagamento,
            'MetodoDiPagamento': MetodoDiPagamento,
            'Descrizione1': 'Quota Aikikai ' + Pacchetto,
            'Descrizione2': Descrizione2,
            'Descrizione3': Descrizione3,
            'Category': tipo
        }, { params: { access_token: access_token } }).then((response) => {
            if (response.status == 200) {

                console.log("transactionMensile成功");
                dispatch({ type: "PAGAMENTO_COMPLETE" })
            }

            setPagamentoId(response.data.Pagamento.id);
            getPagamentoBySocioId(SocioId, access_token);
            setOpenModalTrue();

        }).catch((error) => {

            if (error.response.status === 401) {
                console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                dispatch({
                    type: "ACCESSTOKEN_EXPIRED"
                })
            }

            console.log("transactionMensile失敗", error)
        })
    }
}



export const createTransactionCsen = (
    SocioId,
    AnnoAccademico,
    Importo,
    DataDiPagamento,
    MetodoDiPagamento,
    Descrizione1,
    Descrizione2,
    Descrizione3,
    tipo,
    setPagamentoId,
    setOpenModalTrue,
    getPagamentoBySocioId,
    access_token) => {

    //transactionMensile

    return (dispatch) => {
        axios.post(LoopbackCall.url + `Pagamento/transactionCsen`, {
            'SocioId': SocioId,
            'AnnoAccademico': AnnoAccademico,
            'Importo': Importo,
            'DataDiPagamento': DataDiPagamento,
            'MetodoDiPagamento': MetodoDiPagamento,
            'Descrizione1': 'Quota Assicurativa CSEN',
            'Descrizione2': Descrizione2,
            'Descrizione3': Descrizione3,
            'Category': 'quotaCsen'
        },
            { params: { access_token: access_token } }).then((response) => {
                if (response.status == 200) {

                    console.log("transactionMensile成功");
                    dispatch({ type: "PAGAMENTO_COMPLETE" })
                }

                setPagamentoId(response.data.Pagamento.id);
                getPagamentoBySocioId(SocioId, access_token);
                setOpenModalTrue();

            }).catch((error) => {

                if (error.response.status === 401) {
                    console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                    dispatch({
                        type: "ACCESSTOKEN_EXPIRED"
                    })
                }
    
                console.log("transactionMensile失敗", error)
            })
    }
}



export const createTransactionMensile = (
    SocioId,
    AnnoAccademico,
    Pacchetto,
    tipoDiMensile,
    Importo,
    ImportoAlMese,
    monthToPay,
    DataDiPagamento,
    MetodoDiPagamento,
    Contratto,
    Descrizione1,
    Descrizione2,
    Descrizione3,
    tipo,
    setPagamentoId,
    setOpenModalTrue,
    getPagamentoBySocioId,
    access_token) => {

    //transactionMensile

    return (dispatch) => {
        axios.post(LoopbackCall.url + `Pagamento/transactionMensile`, {
            'SocioId': SocioId,
            'AnnoAccademico': AnnoAccademico,
            'Pacchetto': Pacchetto,
            'tipoDiMensile': tipoDiMensile,
            'Importo': Importo,
            'ImportoAlMese': ImportoAlMese,
            'monthToPay': monthToPay,
            'DataDiPagamento': DataDiPagamento,
            'MetodoDiPagamento': MetodoDiPagamento,
            'Contratto': Contratto,
            'Descrizione1': Descrizione1,
            'Descrizione2': Descrizione2,
            'Descrizione3': Descrizione3,
            'Category': tipo
        }, { params: { access_token: access_token } }).then((response) => {
            if (response.status == 200) {

                console.log("transactionMensile成功");
                dispatch({ type: "PAGAMENTO_COMPLETE" })
            }

            setPagamentoId(response.data.Pagamento.id);
            getPagamentoBySocioId(SocioId, access_token);
            setOpenModalTrue();

        }).catch((error) => {

            if (error.response.status === 401) {
                console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                dispatch({
                    type: "ACCESSTOKEN_EXPIRED"
                })
            }

            console.log("transactionMensile失敗", error)
        })
    }
}


export const getPagamentoBySocioId = (SocioId, access_token) => {

    //transactionMensile

    return (dispatch) => {
        axios.get(LoopbackCall.url + `Pagamento`, {
            params: {
                filter: {
                    where: {
                        SocioId: SocioId
                    }
                },
                access_token: access_token
            }
        }).then((response) => {
            if (response.status == 200) {

                console.log("getPagamentoBySocioId成功");
                dispatch({
                    type: "GET_BY_SOCIOID_Pagamento",
                    value: response.data
                })
            }
        }).catch((error) => {

            if (error.response.status === 401) {
                console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                dispatch({
                    type: "ACCESSTOKEN_EXPIRED"
                })
            }
            console.log("getPagamentoBySocioId失敗", error)
        })
    }
}



////////////////////////////////////////////////////////
export const getPagamentoById = (Id, access_token) => {

    return (dispatch) => {
        axios.get(LoopbackCall.url + `Pagamento/` + Id, {
            params: {
                filter: {
                    where: {

                    }
                },
                access_token: access_token
            }
        }).then((response) => {
            if (response.status == 200) {

                console.log("getPagamentoById成功", response.data);
                dispatch({
                    type: "GET_BY_ID_Pagamento",
                    value: response.data
                })
            }
        }).catch((error) => {

            if (error.response.status === 401) {
                console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                dispatch({
                    type: "ACCESSTOKEN_EXPIRED"
                })
            }
            console.log("getPagamentoById失敗", error)
        })
    }
}



export const transactionMessageClose = () => {

    return (dispatch) => {
        dispatch({ type: "PAGAMENTO_COMPLETE_MESSAGECLOSE" })
    }

}


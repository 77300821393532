export const createRubyArray = (inputText) => {

    console.log(inputText);

    if (inputText != "" && inputText != null) {
        //inputText = "わたしは｜山田｜やまだ｜｜太郎｜たろう｜です。"

        //一文字ずつに分割
        const words = [...inputText];

        let cnt = 0;

        let arr = [];

        let text = "";
        let ruby = "";
        let startKanji = false;
        let spliter = false;

        words.map((each) => {

            if (each === "｜") {
                cnt++;
                if (cnt == 1) {
                    startKanji = true;
                }
                if (spliter) {
                    //連続スプリッターの場合
                    spliter = false;
                }
            } else {
                spliter = false;
            }


            if (!false) {
                if (each == "｜") {
                    each = "";
                }
                if (cnt == 0) {
                    text = text + each;
                } else if (cnt == 1) {
                    if (startKanji) {
                        arr.push({
                            text: text,
                            ruby: ""
                        })
                        text = "";
                        startKanji = false;
                    }
                    text = text + each;
                } else if (cnt == 2) {
                    ruby = ruby + each;
                } else if (cnt == 3) {
                    arr.push({
                        text: text,
                        ruby: ruby
                    })
                    text = ""
                    ruby = "";
                    cnt = 0;
                    text = text + each;
                }
            }

        })

        if (text != "") {
            arr.push({
                text: text,
                ruby: ""
            })
        }
        return arr;
    } else {
        return [];
    }



}

export const webSocket = (state = { socket: null }, action) => {

    switch (action.type) {
        case 'SETUP_WEBSOCKET':

            console.log('SETUP_WEBSOCKET socket:', action.value);
            return {
                ...state,
                socket: action.value
            }
        default: return state
    }

}
import React, { Component, Children } from 'react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import { logout } from '../../store/actions/AuthAction';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import GTranslateIcon from '@material-ui/icons/GTranslate';
import blue from '@material-ui/core/colors/blue';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import FaceIcon from '@material-ui/icons/Face';
import ChildCareIcon from '@material-ui/icons/ChildCare';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import * as ModelAction from '../../store/actions/ModelAction';
import ListAltIcon from '@material-ui/icons/ListAlt';
import MoneyIcon from '@material-ui/icons/Money';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import HomeIcon from '@material-ui/icons/Home';
import AttachmentIcon from '@material-ui/icons/Attachment';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import PaymentIcon from '@mui/icons-material/Payment';
import PaymentsIcon from '@mui/icons-material/Payments';

const drawerWidth = 250;

const styles = theme => ({
    root: {
        display: 'flex',
        height: '100%',
        background: '#FAFAFA'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        background: 'linear-gradient(45deg, #6E6E6E, #1646AE)',
        color: 'white',
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
        background: 'linear-gradient(45deg, #6E6E6E, #1646AE)'
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar
    },
    toolbarBlack: {
        background: 'linear-gradient(45deg, #1646AE, #6E6E6E)'
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        background: '#fafafa',
        height: '100vh'
    },
    whiteIcon: {
        color: 'white',
    },
    link: {
        '&:hover': {
            color: '#000000',
        },
    }
});




class PageWrapper extends Component {

    constructor(props) {
        super(props);

        this.state = {
            menuOpen: true,
            anchorEl: null
        }

        this.closeMenu = this.closeMenu.bind(this);
        this.openMenu = this.openMenu.bind(this);
        this.closeUserMenu = this.closeUserMenu.bind(this);
        this.openUserMenu = this.openUserMenu.bind(this);

        this.props.getAnnoAccademico(this.props.logininfo.id);
    }

    closeMenu = () => {
        this.setState({ menuOpen: false })
    };

    openMenu = (event) => {
        this.setState({ menuOpen: true })
    };

    closeUserMenu = () => {
        this.setState({ userMenuOpen: false })
    };

    openUserMenu = (event) => {
        this.setState({ userMenuOpen: true, anchorEl: event.currentTarget })
    };






    render() {

        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    className={clsx(classes.appBar, {
                        [classes.appBarShift]: this.state.menuOpen,
                    })}
                >
                    <Toolbar className={classes.toolbarBlack}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={this.openMenu}
                            edge="start"
                            className={clsx(classes.menuButton,
                                classes.whiteIcon,
                                { [classes.hide]: this.state.menuOpen }
                            )}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" noWrap>Jyushin Dojo  AnnoAccademico {this.props.AnnoAccademico}</Typography>
                        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={this.openUserMenu} >
                            <FaceIcon className={classes.whiteIcon} /></Button>
                        <Menu
                            id="simple-menu"
                            anchorEl={this.state.anchorEl}
                            keepMounted
                            open={Boolean(this.state.userMenuOpen)}
                            onClose={this.closeUserMenu}
                        >
                            <MenuItem
                                button
                                component={RouterLink}
                                to="/changepassword"
                                className={classes.link}
                                onClick={this.closeUserMenu}>Change Password</MenuItem>
                            <MenuItem
                                onClick={(e) => { this.props.logout(e, this.props.logininfo) }}>Logout</MenuItem>
                        </Menu>
                        <Typography variant="h6" noWrap>{this.props.usernameOrEmail} [  {this.props.role}  ] </Typography>
                        <Button component={RouterLink} to="/" >
                            <HomeIcon className={classes.whiteIcon} /></Button>
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant="permanent"
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: this.state.menuOpen,
                        [classes.drawerClose]: !this.state.menuOpen,
                    })}
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: this.state.menuOpen,
                            [classes.drawerClose]: !this.state.menuOpen,
                        }),
                    }}
                >
                    <div className={classes.toolbar}>
                        <IconButton onClick={this.closeMenu}>
                            <ChevronLeftIcon className={classes.whiteIcon} />
                        </IconButton>
                    </div>
                    {this.props.role == 'jyushin'
                        ? <div>
                            <Divider />
                            <List>
                                <ListItem button component={RouterLink} to="/admin/soci" className={classes.link}>
                                    <ListItemIcon>
                                        <AccessibilityNewIcon className={classes.whiteIcon} />
                                    </ListItemIcon>
                                    <ListItemText primary="Soci" />
                                </ListItem>
                                <ListItem button component={RouterLink} to="/calendariopagamenti" className={classes.link}>
                                    <ListItemIcon>
                                        <CalendarTodayIcon className={classes.whiteIcon} />
                                    </ListItemIcon>
                                    <ListItemText primary="Calendario Pagamenti" />
                                </ListItem>
                                <ListItem button component={RouterLink} to="/pagamento" className={classes.link}>
                                    <ListItemIcon>
                                        <EuroSymbolIcon className={classes.whiteIcon} />
                                    </ListItemIcon>
                                    <ListItemText primary="Pagamento" />
                                </ListItem>
                                <ListItem button component={RouterLink} to="/ricevuta" className={classes.link}>
                                    <ListItemIcon>
                                        <MailIcon className={classes.whiteIcon} />
                                    </ListItemIcon>
                                    <ListItemText primary="Ricevuta" />
                                </ListItem>
                                <ListItem button component={RouterLink} to="/admin/elencoiscritti" className={classes.link}>
                                    <ListItemIcon>
                                        <ListAltIcon className={classes.whiteIcon} />
                                    </ListItemIcon>
                                    <ListItemText primary="Elenco Iscritti" />
                                </ListItem>
                                <ListItem button component={RouterLink} to="/presenze" className={classes.link}>
                                    <ListItemIcon>
                                        <AccessibilityNewIcon className={classes.whiteIcon} />
                                    </ListItemIcon>
                                    <ListItemText primary="Presenze" />
                                </ListItem>
                                <ListItem button component={RouterLink} to="/stipendi" className={classes.link}>
                                    <ListItemIcon>
                                        <PaymentsIcon className={classes.whiteIcon} />
                                    </ListItemIcon>
                                    <ListItemText primary="Stipendi" />
                                </ListItem>
                            </List>
                            <Divider />
                            <ListItem >
                                <ListItemIcon>
                                </ListItemIcon>
                                <ListItemText primary=" " />
                            </ListItem>
                            <Divider />
                            <List>
                                <ListItem button component={RouterLink} to="/admin/pagamento" className={classes.link}>
                                    <ListItemIcon />
                                    <ListItemText primary="Pagamento" />
                                </ListItem>
                                <ListItem button component={RouterLink} to="/admin/prezzi" className={classes.link}>
                                    <ListItemIcon />
                                    <ListItemText primary="Prezzi" />
                                </ListItem>
                                <ListItem button component={RouterLink} to="/admin/socimensile" className={classes.link}>
                                    <ListItemIcon />
                                    <ListItemText primary="Soci Mensile" />
                                </ListItem>
                                <ListItem button component={RouterLink} to="/admin/ricevuta" className={classes.link}>
                                    <ListItemIcon />
                                    <ListItemText primary="Ricevuta" />
                                </ListItem>
                                <ListItem button component={RouterLink} to="/admin/esterno" className={classes.link}>
                                    <ListItemIcon />
                                    <ListItemText primary="Esterno" />
                                </ListItem>
                                <ListItem button component={RouterLink} to="/admin/staff" className={classes.link}>
                                    <ListItemIcon />
                                    <ListItemText primary="Staff" />
                                </ListItem>
                                <ListItem button component={RouterLink} to="/admin/presenze" className={classes.link}>
                                    <ListItemIcon />
                                    <ListItemText primary="Presenze" />
                                </ListItem>
                                <ListItem button component={RouterLink} to="/admin/pagamentolist" className={classes.link}>
                                    <ListItemIcon>
                                        <EqualizerIcon className={classes.whiteIcon} />
                                    </ListItemIcon>
                                    <ListItemText primary="Pagamento List" />
                                </ListItem>
                                <ListItem button component={RouterLink} to="/filemanager" className={classes.link}>
                                    <ListItemIcon>
                                        <AttachmentIcon className={classes.whiteIcon} />
                                    </ListItemIcon>
                                    <ListItemText primary="File" />
                                </ListItem>
                            </List>
                        </div>
                        : this.props.role == 'staff'
                            ? <div>
                                <Divider />
                                <List>
                                    <ListItem button component={RouterLink} to="/presenze" className={classes.link}>
                                        <ListItemIcon>
                                            <CalendarTodayIcon className={classes.whiteIcon} />
                                        </ListItemIcon>
                                        <ListItemText primary="Presenza" />
                                    </ListItem>
                                </List>
                                <Divider />
                            </div>
                            : null}







                </Drawer>
                <main className={classes.content} >
                    <div className={classes.toolbar} />
                    {this.props.accesstoken_expired
                        ? <div>please login again</div>
                        : this.props.children}
                </main>
            </div>
        );

    }


}

const mapStateToProps = (state) => {
    return {
        logininfo: state.auth.logininfo,
        role: state.auth.role,
        usernameOrEmail: state.auth.usernameOrEmail,
        accesstoken_expired: state.auth.accesstoken_expired,
        AnnoAccademico: state.model.AnnoAccademico,
    }

}

const mapDispatchToProps = (dispatch) => {

    return {

        logout: (e, logininfo) => {
            e.preventDefault();
            dispatch(logout(logininfo));

        },
        getAnnoAccademico: (access_token) => {
            dispatch(ModelAction.apiGetById('AnnoAccademico', 1, access_token))
        },
    }

}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(PageWrapper));
import axios from 'axios';
import * as LoopbackCall from '../../util/LoopbackCall';
import * as StrTool from '../../util/StrTool';




/*
POST / StaffApi / delete_presenza_by_id


presenzaId
access_token



POST / StaffApi / get_presenza_by_id

presenzaid
access_token




POST / StaffApi / post_or_put_presenza

presenza
access_token

*/

/*

POST /StaffApi/get_presenza_by_month

userId	
firstdayofmonth	
page	
numData	
access_token	
*/
export const apiGet_presenza_by_month = (userId, firstdayofmonth, page, numData, access_token) => {

    return (dispatch) => {

        axios.post(LoopbackCall.url + 'StaffApi/get_presenza_by_month',
            null, {
            params: {
                userId,
                firstdayofmonth,
                page,
                numData,
                access_token
            }
        }).then((response) => {

            if (response.status == 200) {
                dispatch({
                    type: "GET_ALL_Presenze",
                    value: response.data
                })

            }
        }).catch((error) => {

            if (error.response.status === 401) {
                console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                dispatch({
                    type: "ACCESSTOKEN_EXPIRED"
                })
            }
            console.log("get_presenza_by_month失敗", error)
        })
    }
}





/*

POST /StaffApi/get_presenza_by_month

userId	
firstdayofmonth	
page	
numData	
access_token	
*/
export const apiGet_stipendi_from_presenza = (userId, dal, al, access_token, cb) => {


    return (dispatch) => {

        axios.post(LoopbackCall.url + 'StaffApi/get_presenza_by_term',
            null, {
            params: {
                userId,
                dal,
                al,
                access_token
            }
        }).then((response) => {

            if (response.status == 200) {

                console.log('response.data', response.data)

                var totalOre = 0;
                var costoOrario = '';
                var err = false;
                var errMsg = '';

                //cosoOrarioをどうするか　TODO
                if (response.data.length == 0) {
                    cb(true, null, 0, costoOrario, 0)
                }



                for (var i = 0; i < response.data.length; i++) {
                    console.log(response.data[i]);

                    if (i == 0) {
                        costoOrario = response.data[i].CostoOrario
                        errMsg = response.data[i].CostoOrario + ' '
                    } else {
                        if (costoOrario != response.data[i].CostoOrario) {
                            err = true;
                            errMsg = errMsg + response.data[i].CostoOrario + ' '
                        }
                    }

                    totalOre = totalOre + response.data[i].Ore

                    console.log('totalOre',totalOre)
                }

                var stipendio = totalOre * costoOrario

                if (err) {
                    cb(false, 'errore mentere scaricando presenza : nell questo periodo ci sono vali costi orario ( ' + errMsg + ')', totalOre, null, null)
                } else {
                    cb(true, null, totalOre, costoOrario, stipendio)
                }



                //dispatch({
                //    type: "GET_Presenza_by_term",
                //    value: response.data
                //})
            } else {
                cb(false, '', null, null, null)
            }
        }).catch((error) => {

            if (error.response.status === 401) {
                console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                dispatch({
                    type: "ACCESSTOKEN_EXPIRED"
                })
            }
            console.log("ここでapiGet_presenza_by_term失敗", error, null, null, null)
            cb(false, 'apiGet_presenza_by_term失敗', null, null, null)
        })
    }
}





/*
POST / StaffApi / get_count_presenza_by_month

userId
firstdayofmonth
access_token
*/
export const apiGetCount = (userId, firstdayofmonth, access_token) => {

    return (dispatch) => {

        axios.post(LoopbackCall.url + 'StaffApi/get_count_presenza_by_month',
            null, {
            params: {
                userId,
                firstdayofmonth,
                access_token
            }
        }).then((response) => {

            if (response.status == 200) {
                dispatch({
                    type: "GET_COUNT_Presenze",
                    value: response.data['count']
                })
            }

        }).catch((error) => {

            if (error.response.status === 401) {
                console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                dispatch({
                    type: "ACCESSTOKEN_EXPIRED"
                })
            }
            console.log("get_count_presenza_by_month失敗", error)
        })

    }
}


/*

POST / StaffApi / post_or_put_presenza

presenza
access_token
*/
export const apiPostOrPut = (userId, firstdayofmonth, page, numData, formValue, access_token) => {

    console.log('ここの　apiPostOrPut formValue', formValue)


    return (dispatch) => {

        axios.post(LoopbackCall.url + 'StaffApi/post_or_put_presenza',
            {
                "id": formValue["id"],
                "Data": formValue["Data"],
                "UserId": formValue["UserId"],
                "StaffId": formValue["StaffId"],
                "Presenza": formValue["Presenza"],
                "Ore": formValue["Ore"],
                "CostoOrario": formValue["CostoOrario"],
                "Confermata": formValue["Confermata"],
                "Descrizione": formValue["Descrizione"]
            },
            {
                params: {
                    //presenza: formValue,
                    access_token
                }
            }).then((response) => {


                axios.post(LoopbackCall.url + 'StaffApi/get_count_presenza_by_month',
                    null, {
                    params: {
                        userId,
                        firstdayofmonth,
                        access_token
                    }
                }).then((response) => {

                    if (response.status == 200) {

                        dispatch({
                            type: "GET_COUNT_Presenze",
                            value: response.data.count
                        })


                        axios.post(LoopbackCall.url + 'StaffApi/get_presenza_by_month',
                            null, {
                            params: {
                                userId,
                                firstdayofmonth,
                                page,
                                numData,
                                access_token
                            }
                        }).then((response) => {

                            if (response.status == 200) {
                                dispatch({
                                    type: "GET_ALL_Presenze",
                                    value: response.data
                                })
                            } else {
                                dispatch({
                                    type: "GET_ALL_Presenze",
                                    value: []
                                })
                            }

                        }).catch((error) => {
                            if (error.response.status === 401) {
                                console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                                dispatch({
                                    type: "ACCESSTOKEN_EXPIRED"
                                })
                            }
                            console.log("get_presenza_by_month失敗", error)
                            dispatch({
                                type: "GET_ALL_Presenze",
                                value: []
                            })
                        })
                    } else {
                        dispatch({
                            type: "GET_COUNT_Presenze",
                            value: 0
                        })
                    }

                }).catch((error) => {

                    if (error.response.status === 401) {
                        console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                        dispatch({
                            type: "ACCESSTOKEN_EXPIRED"
                        })
                    }
                    console.log("get_count_presenza_by_month失敗", error)
                })




            }).catch((error) => {

                if (error.response.status === 401) {
                    console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                    dispatch({
                        type: "ACCESSTOKEN_EXPIRED"
                    })
                }
                console.log("登録失敗", error)
            })



    }
}



export const apiDelete = (userId, firstdayofmonth, numData, presenzaId, access_token) => {
    return (dispatch) => {


        axios.post(LoopbackCall.url + 'StaffApi/delete_presenza_by_id',
            null,
            {
                params: {
                    presenzaId,
                    access_token
                }
            }).then((response) => {


                axios.post(LoopbackCall.url + 'StaffApi/get_count_presenza_by_month',
                    null, {
                    params: {
                        userId,
                        firstdayofmonth,
                        access_token
                    }
                }).then((response) => {

                    if (response.status == 200) {

                        dispatch({
                            type: "GET_COUNT_Presenze",
                            value: response.data.count
                        })


                        axios.post(LoopbackCall.url + 'StaffApi/get_presenza_by_month',
                            null, {
                            params: {
                                userId,
                                firstdayofmonth,
                                page: 1,
                                numData,
                                access_token
                            }
                        }).then((response) => {

                            if (response.status == 200) {
                                dispatch({
                                    type: "GET_ALL_Presenze",
                                    value: response.data
                                })
                            } else {
                                dispatch({
                                    type: "GET_ALL_Presenze",
                                    value: []
                                })
                            }

                        }).catch((error) => {

                            if (error.response.status === 401) {
                                console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                                dispatch({
                                    type: "ACCESSTOKEN_EXPIRED"
                                })
                            }
                            console.log("get_presenza_by_month失敗", error)
                            dispatch({
                                type: "GET_ALL_Presenze",
                                value: []
                            })
                        })
                    } else {
                        dispatch({
                            type: "GET_COUNT_Presenze",
                            value: 0
                        })
                    }

                }).catch((error) => {

                    if (error.response.status === 401) {
                        console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                        dispatch({
                            type: "ACCESSTOKEN_EXPIRED"
                        })
                    }
                    console.log("get_count_presenza_by_month失敗", error)
                })




            }).catch((error) => {

                if (error.response.status === 401) {
                    console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                    dispatch({
                        type: "ACCESSTOKEN_EXPIRED"
                    })
                }
                console.log("登録失敗", error)
            })



    }
}



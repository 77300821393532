import { combineReducers } from 'redux';
import { auth } from './AuthReducer';
import { webSocket } from './WebSocketReducer';
import { model } from './ModelReducers';
import { cache , sociMensiliCache, sociPagamentoCache, pagamentoCache} from './cacheReducers'
import { pagamento } from './PagamentoReducers';
import { stipendi } from './StipendiReducers';
import { soci } from './SociReducers';
import { ricevuta } from './RicevutaReducers' 
import { gdrivefile } from './GDriveFileReducers'

export const reducer = combineReducers(
    {
        model, auth, webSocket, cache, pagamento, ricevuta , soci , sociMensiliCache, sociPagamentoCache, pagamentoCache, gdrivefile,stipendi
    }
);

import { createStore, applyMiddleware } from 'redux';
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { reducer } from './reducers/index';
import thunk from 'redux-thunk';

const config = {
    key: 'auth', // Storageに保存されるキー名を指定する
    storage, // 保存先としてlocalStorageがここで設定される
    whitelist: ['auth'] // Stateは`auth`のみStorageに保存する
}

const persistedReducer = persistReducer(config, reducer)

export const store = createStore(persistedReducer, applyMiddleware(thunk));
export const persistor = persistStore(store);
import React, { Component } from 'react';

import classNames from 'classnames'
import Grid from '@material-ui/core/Grid';
import { withStyles } from "@material-ui/core/styles";
import TableCell from '@material-ui/core/TableCell';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import * as ModelAction from '../../../store/actions/ModelAction'
import { connect } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import { createMuiTheme } from '@material-ui/core'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import * as DateTool from '../../../util/DateTool';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import StaffSearcher from '../../common/StaffSearcher';
import Checkbox from '@mui/material/Checkbox';
import * as NumTool from '../../../util/NumTool';
import * as PresenzeAction from '../../../store/actions/PresenzeAction'


import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';


const styles = theme => ({

    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    table: {
        // minWidth: 650,
    },
    tableHead: {
        //background: 'linear-gradient(45deg, #8C8C8C, #BDBDBD)'
        background: "#b9b9b9"
    },

    row: {
        '&:hover': {
            background: "#CACACA"
        }
    },
    cell: {
        '&:hover': {
            background: "#b9b9b9"
        },
    },
    modal: {
        display: 'block',
        position: 'absolute',
        overflow: 'scroll',
    },
    modalpaper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: 900,
    },
    buttonback: {
        backgroundColor: "#b9b9b9",
        border: '0px solid #000',
        boxShadow: theme.shadows[0],
        padding: theme.spacing(1.5, 1.5, 1.5),

    },
    formControlS: {
        margin: theme.spacing(1),
        width: 120,
    },
    formControlM: {
        margin: theme.spacing(1),
        width: 300
    },
    formControlL: {
        margin: theme.spacing(1),
        width: 800
    },
    grammar: {
        width: 800
    },
    grammarPreview: {
        width: 800
    },
    cell_short: {
        fontSize: "10px",
        width: 50,
    },
    cell_medium: {
        fontSize: "10px",
        width: 100,
    },
    cell_clickable: {
        cursor: "pointer"
    },
    cell_searched: {
        background: "#FA8072"
    },
    gridItem: {
    },
    button: {
        background: 'white',
        borderRadius: 3,
        border: 0,
        color: 'black',
        padding: '0 20px',
        '&:hover': {
            background: "#b9b9b9"
        },
    },
    container: {
        maxHeight: 600,
    },
    div100: {
        height: '100%',
        width: '100%',

    },
});

const customTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#bfbfbf',
            light: '#969696',
            dark: '#616060',
        },
        secondary: {
            main: '#616060',
        },
    },
})




var defaultFormValue = {};

class CrudModalUpdatePresenze extends Component {

    constructor(props) {
        super(props);

        var submitDisabled = false


        this.state = null;
        this.state = {
            formValue: defaultFormValue,
            unfilledRequiredFIeld: false,
            openUpdateModal: false,

        };

    }

    componentDidMount() {

    }



    handlUpdateModalOpen = (model) => {
        console.log("handlUpdateModalOpen", model)


        var f = {};

        if (this.props.role == 'jyushin') {

            f['id'] = model.id
            f['UserId'] = model.UserId
            f['Presenza'] = model.Presenza
            f['Data'] = model.Data
            f['Ore'] = model.Ore
            //CostoOrario　値がない場合(confirm前)はStaffから保管　confirm後はDBに登録されているなんらかの値があるのでそれを表示
            f['CostoOrario'] = (model.CostoOrario != null && model.CostoOrario != '' ? model.CostoOrario : model.Staff.CostoOrario)
            f['Staff'] = model.Staff
            f['Confermata'] = model.Confermata



        } else {

            f['id'] = model.id
            f['UserId'] = model.UserId
            f['Presenza'] = model.Presenza
            f['Data'] = model.Data
            f['Ore'] = model.Ore
            f['Staff'] = model.Staff
        }

        this.setState({
            openUpdateModal: true,
            formValue: f,
            unfilledRequiredFIeld: false,

        })


    };

    handleModalClose = () => {
        this.setState({ openUpdateModal: false })
    };


    onChangeInputCommon = (key, value) => {
        var newFormValue = this.state.formValue;
        newFormValue[key] = value;
        this.setState({ formValue: newFormValue });
        // this.checkInputRequired();
    }

    handleDateTimeChange = (datetime, key) => {
        console.log(datetime)
        var newFormValue = this.state.formValue;
        newFormValue[key] = DateTool.createJSONDateWithoutTime(datetime);
        console.log(DateTool.createJSONDateWithoutTime(datetime))
        //newFormValue[key] = datetime;
        this.setState({ formValue: newFormValue });
        //this.checkInputRequired();
    }

    onChangeInputNumCommon = (key, value) => {
        this.setState({ formValue: NumTool.validateAndSetNewFormValue(key, value, this.state.formValue) });
        //this.checkInputRequired();
    }


    checkInputRequired = (UserId, Presenza, Data, Ore, CostoOrario, Confermata) => {

        console.log('UserId', UserId)
        console.log('Presenza', Presenza)
        console.log('Data', Data)
        console.log('Ore', Ore)
        console.log('CostoOrario', CostoOrario)
        console.log('Confermata', Confermata)

        var unfilledRequiredFIeld = false;

        if (Presenza == null) {
            unfilledRequiredFIeld = true;
        }

        if (Data == null) {
            unfilledRequiredFIeld = true;
        }

        //空文字と 0 を 等価演算子 (==) で比較すると true になるため、ここでは 0 が来たときに想定通りの挙動にはなりません。
        //ここでは 厳密等価演算子 (===) で比較します。

        if (Ore == null || Ore === '') {
            unfilledRequiredFIeld = true;
        }

        if (this.props.role == 'jyushin') {
            if (UserId == null) {
                unfilledRequiredFIeld = true;
            }

            if (CostoOrario == null || CostoOrario === '') {
                unfilledRequiredFIeld = true;
            }

            if (Confermata == null) {
                unfilledRequiredFIeld = true;
            }
        }


        this.setState({ unfilledRequiredFIeld: unfilledRequiredFIeld })
        console.log('unfilledRequiredFIeld', unfilledRequiredFIeld)


    }
    render() {

        const { classes } = this.props;

        const nums = [];
        for (let i = 1; i <= 50; i++) {
            nums.push(i);
        }

        return (
            <div className={classes.cell_clickable}>
                <div onClick={(e) => { e.preventDefault(); this.handlUpdateModalOpen(this.props.modelValue) }}>
                    <EditIcon />
                </div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={this.state.openUpdateModal}
                    onClose={this.handleModalClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.openUpdateModal}>
                        <div className={classes.modalpaper}>
                            <form className={classes.root} noValidate autoComplete="on">
                                <Grid item xs={12}  >
                                    <h4 id="transition-modal-title">Presenza</h4>
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    spacing={2}
                                >

                                    <Grid item xs={12}  >
                                        <Grid container justify="left"  >
                                            {this.props.role == 'jyushin' &&
                                                <Grid container justify="left"  >
                                                    <Grid item xs={12}  >
                                                        <h6 >Staff</h6>
                                                    </Grid>
                                                    <Grid item xs={12}  >
                                                        <h4 >{this.state.formValue.Staff && this.state.formValue.Staff.Cognome + ' ' + this.state.formValue.Staff.Nome}</h4>
                                                    </Grid>
                                                </Grid>
                                            }
                                            <Grid item xs={12} key={'form_Data'}  >
                                                <FormControl className={classes.formControlM}>
                                                    <MuiThemeProvider theme={customTheme}>
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                            <KeyboardDatePicker
                                                                margin="normal"
                                                                id="date-picker-dialog"
                                                                label='Data*'
                                                                format="dd/MM/yyyy"
                                                                value={this.state.formValue['Data']}
                                                                onChange={(e) => {
                                                                    this.handleDateTimeChange(e, 'Data')
                                                                    this.checkInputRequired(
                                                                        this.state.formValue['UserId'],
                                                                        this.state.formValue['Presenza'],
                                                                        DateTool.createJSONDateWithoutTime(e),
                                                                        this.state.formValue['Ore'],
                                                                        this.state.formValue['CostoOrario'],
                                                                        this.state.formValue['Confermata'])

                                                                }}
                                                                KeyboardButtonProps={{
                                                                    'aria-label': 'change date',
                                                                }}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </MuiThemeProvider>
                                                </FormControl>
                                            </Grid>



                                            <Grid item xs={12} key={'radio_presenza'} >
                                                <FormControl className={classes.formControlS}>
                                                    <FormLabel component="legend">Presenza*</FormLabel>
                                                    <RadioGroup aria-label='Presenza' name='Presenza' value={this.state.formValue['Presenza']} onChange={(e) => {
                                                        e.preventDefault()
                                                        this.onChangeInputCommon('Presenza', e.target.value)


                                                        if (e.target.value == 'Assente') {
                                                            this.onChangeInputNumCommon('Ore', 0)

                                                            this.checkInputRequired(
                                                                this.state.formValue['UserId'],
                                                                e.target.value,
                                                                this.state.formValue['Data'],
                                                                0,
                                                                this.state.formValue['CostoOrario'],
                                                                this.state.formValue['Confermata'])
                                                        } else {
                                                            this.checkInputRequired(
                                                                this.state.formValue['UserId'],
                                                                e.target.value,
                                                                this.state.formValue['Data'],
                                                                this.state.formValue['Ore'],
                                                                this.state.formValue['CostoOrario'],
                                                                this.state.formValue['Confermata'])

                                                        }

                                                    }}>
                                                        <FormControlLabel key={'key_presente'} value="Presente" control={<Radio />} label="Presente" />
                                                        <FormControlLabel key={'key_assente'} value="Assente" control={<Radio />} label="Assente" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} key={'form_Ore'}  >
                                                <FormControl className={classes.formControlM}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label='Ore*'
                                                        value={this.state.formValue['Ore']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputNumCommon('Ore', e.target.value)
                                                            this.checkInputRequired(
                                                                this.state.formValue['UserId'],
                                                                this.state.formValue['Presenza'],
                                                                this.state.formValue['Data'],
                                                                e.target.value,
                                                                this.state.formValue['CostoOrario'],
                                                                this.state.formValue['Confermata'])
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            {this.props.role == 'jyushin' &&
                                                <Grid item xs={12} key={'form_CostoOrario'}  >
                                                    <FormControl className={classes.formControlM}>
                                                        <TextField
                                                            id="standard-basic"
                                                            label='CostoOrario*'
                                                            value={this.state.formValue['CostoOrario']}
                                                            onChange={(e) => {
                                                                e.preventDefault()
                                                                this.onChangeInputNumCommon('CostoOrario', e.target.value)
                                                                this.checkInputRequired(
                                                                    this.state.formValue['UserId'],
                                                                    this.state.formValue['Presenza'],
                                                                    this.state.formValue['Data'],
                                                                    this.state.formValue['Ore'],
                                                                    e.target.value,
                                                                    this.state.formValue['Confermata'])
                                                            }}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            }
                                            {this.props.role == 'jyushin' &&
                                                <Grid item xs={12} key={'radio_Confermata'} >
                                                    <FormControl className={classes.formControlM}>

                                                        <FormControlLabel control={
                                                            <Checkbox
                                                                defaultChecked
                                                                checked={this.state.formValue['Confermata']}
                                                                onChange={(e) => {
                                                                    e.preventDefault()
                                                                    console.log('Checkbox', e.target.value)
                                                                    if (this.state.formValue['Confermata']) {
                                                                        this.onChangeInputCommon('Confermata', false)

                                                                        this.checkInputRequired(
                                                                            this.state.formValue['UserId'],
                                                                            this.state.formValue['Presenza'],
                                                                            this.state.formValue['Data'],
                                                                            this.state.formValue['Ore'],
                                                                            this.state.formValue['CostoOrario'],
                                                                            false)


                                                                    } else {
                                                                        this.onChangeInputCommon('Confermata', true)

                                                                        this.checkInputRequired(
                                                                            this.state.formValue['UserId'],
                                                                            this.state.formValue['Presenza'],
                                                                            this.state.formValue['Data'],
                                                                            this.state.formValue['Ore'],
                                                                            this.state.formValue['CostoOrario'],
                                                                            true)


                                                                    }

                                                                }}
                                                            />
                                                        } label="Confermata" />
                                                    </FormControl>
                                                </Grid>
                                            }

                                            <Grid item xs={12} key={'form_Descrizione'} >
                                                <FormControl className={classes.formControlM}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label='Descrizione'
                                                        value={this.state.formValue['Descrizione']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputCommon('Descrizione', e.target.value)

                                                        }} /> </FormControl> </Grid>




                                            {this.state.openUpdateModal &&
                                                <Grid item xs={12} >
                                                    <Button variant="contained"
                                                        disabled={this.state.unfilledRequiredFIeld}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            console.log("this.props", this.props)

                                                            this.props.submitUpdate(this.props.role == 'jyushin' ? null : this.props.logininfo.userId, this.props.firstdayofmonth, this.props.page, this.props.numData, this.state.formValue, this.props.logininfo.id)
                                                            this.handleModalClose();

                                                        }}>Update</Button>
                                                </Grid>
                                            }
                                            {this.state.unfilledRequiredFIeld &&
                                                <Grid item xs={12} ><div className={classes.formControlM}><font color="#F50057">Please fill in the required sections *</font></div></Grid>}
                                        </Grid >
                                    </Grid >
                                </Grid>
                            </form>
                        </div>

                    </Fade>
                </Modal >
            </div>

        );
    }
}


const mapStateToProps = (state) => {
    return {
        logininfo: state.auth.logininfo,
        role: state.auth.role

    }
}

const mapDispatchToProps = (dispatch) => {

    return {

        submitUpdate: (userId, firstdayofmonth, page, numData, formValue, access_token) => {
            console.log('formValue', formValue)
            dispatch(PresenzeAction.apiPostOrPut(userId, firstdayofmonth, page, numData, formValue, access_token))
        },


    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(CrudModalUpdatePresenze));
import React, { Component } from 'react';

import classNames from 'classnames';
import { withStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import SimpleCrud from '../../common/SimpleCrud'




const styles = theme => ({

    root: {
        flexGrow: 1,
        margin: 20
    },
    paper: {
        height: 100,
        width: 800,
        margin: 10,
        padding: 10,
        '&:hover': {
            background: "#CACACA"
        }
    },
    control: {
        padding: theme.spacing(2),
    }
});

const modelProps = {
    "id": {
        "type": "string"
    },
    "UserId": {
        "type": "string"
    },
    "Cognome": {
        "type": "string"
    },
    "Nome": {
        "type": "string"
    },
    "Indirizzo": {
        "type": "string"
    },
    "Prov": {
        "type": "string"
    },
    "Localita": {
        "type": "string"
    },
    "CAP": {
        "type": "string"
    },
    "DataDiNascita": {
        "type": "date"
    },
    "ProvDiNascita": {
        "type": "string"
    },
    "LocalitaDiNascita": {
        "type": "string"
    },
    "CodFisc": {
        "type": "string"
    },
    "PIva": {
        "type": "string"
    },
    "IBAN": {
        "type": "string"
    },
    "Telefono1": {
        "type": "string"
    },
    "Telefono2": {
        "type": "string"
    },
    "Cel1": {
        "type": "string"
    },
    "Cel2": {
        "type": "string"
    },
    "FAX": {
        "type": "string"
    },
    "EMail": {
        "type": "string"
    },
    "CostoOrario": {
        "type": "number",
        "mysql": {
            "dataType": "float"
        }
    },
    "CostoMensile": {
        "type": "number",
        "mysql": {
            "dataType": "float"
        }
    },
    "Contratto": {
        "type": "string"
    },
    "Matricola": {
        "type": "string"
    },
    "DataAssunzione": {
        "type": "date"
    },
    "DataLicenziamento": {
        "type": "date"
    },
    "LastModifiedBy": {
        "type": "string"
    },
    "LastModifiedAt": {
        "type": "date"
    }

}

class Staff extends Component {

    constructor(props) {
        super(props);

    }



    render() {

        const { classes } = this.props;

        return (
            <SimpleCrud
                modelName="Staff"
                modelNameSingolare="Staff"
                modelProps={modelProps}
            />
        );
    }
}


const mapStateToProps = (state) => {
    return {

    }

}

const mapDispatchToProps = (dispatch) => {

    return {


    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Staff));
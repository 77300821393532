import React, { Component } from 'react';

import classNames from 'classnames'
import Grid from '@material-ui/core/Grid';
import { withStyles } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import * as WebSocketAction from '../../store/actions/WebSocketAction';
import * as ModelAction from '../../store/actions/ModelAction'
import { connect } from 'react-redux';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import RubyText from './RubyText';
import * as Ruby from '../../util/Ruby'
import { createMuiTheme } from '@material-ui/core'
import * as DateTool from '../../util/DateTool';
import * as StrTool from '../../util/StrTool';
import Pagination from '@material-ui/lab/Pagination';
import SimpleCrudModalCreate from './SimpleCrudModalCreate'
import SimpleCrudModalUpdate from './SimpleCrudModalUpdate'


import DropzoneAreaModal from './DropzoneAreaModal'


const styles = theme => ({

    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    table: {
        // minWidth: 650,
    },
    tableHead: {
        //background: 'linear-gradient(45deg, #8C8C8C, #BDBDBD)'
        background: "#b9b9b9"
    },

    row: {
        '&:hover': {
            background: "#CACACA"
        }
    },
    cell: {
        '&:hover': {
            background: "#b9b9b9"
        },
    },
    modal: {
        display: 'block',
        position: 'absolute',
        overflow: 'scroll',
    },
    modalpaper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: 900,
    },
    buttonback: {
        backgroundColor: "#b9b9b9",
        border: '0px solid #000',
        boxShadow: theme.shadows[0],
        padding: theme.spacing(1.5, 1.5, 1.5),

    },
    formControlS: {
        margin: theme.spacing(1),
        width: 120,
    },
    formControlM: {
        margin: theme.spacing(1),
        width: 300
    },
    formControlL: {
        margin: theme.spacing(1),
        width: 800
    },
    grammar: {
        width: 800
    },
    grammarPreview: {
        width: 800
    },
    cell_short: {
        fontSize: "10px",
        width: 50,
    },
    cell_medium: {
        fontSize: "10px",
        width: 100,
    },
    cell_clickable: {
        cursor: "pointer"
    },
    cell_searched: {
        background: "#FA8072"
    },
    height100:{
        height: '100%'
    },
    gridItem: {
    },
    button: {
        background: 'white',
        borderRadius: 3,
        border: 0,
        color: 'black',
        padding: '0 20px',
        '&:hover': {
            background: "#b9b9b9"
        },
    },
    container: {
        maxHeight: 600,
    },
});

const customTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#bfbfbf',
            light: '#969696',
            dark: '#616060',
        },
        secondary: {
            main: '#616060',
        },
    },
})




var defaultFormValue = {};

class SimpleCrud extends Component {

    constructor(props) {
        super(props);

        var submitDisabled = false

        Object.keys(this.props.modelProps).forEach((k) => {

            if (k != "id") {
                switch (this.props.modelProps[k].type) {
                    case "number":
                        defaultFormValue[k] = 0;
                        return;
                    case "string":
                        if (this.props.modelProps[k].defaultValue) {
                            defaultFormValue[k] = this.props.modelProps[k].defaultValue;
                        } else {
                            defaultFormValue[k] = "";
                        }
                        return;
                    case "date":
                        defaultFormValue[k] = DateTool.createJSONDateWithoutTime(new Date());
                        return;
                    case "time":
                        defaultFormValue[k] = DateTool.createJSONDateWithoutTime(new Date());
                        return;
                    case "select":
                        defaultFormValue[k] = "";
                        return;

                }

            }


        })

        this.state = null;
        this.state = {
            formValue: defaultFormValue,
            page: 1,
            numData: this.props.modelProps.DefaultNum ? this.props.modelProps.DefaultNum : 200,
            searchword: '',
            searchedNum: 0,
            //submitDisabled : submitDisabled
            unfilledRequiredFIeld: false

        };

    }

    componentDidMount() {
        this.props.getModelCount(this.props.modelName, this.props.logininfo.id)
        this.props.getModelList(this.props.modelName, this.state.page, this.state.numData, this.props.logininfo.id);
        //this.props.setupWebSocket(this.props.logininfo.id);
    }




    onChangeSearchWord = (value) => {

        var searchword = value.trim();

        this.setState({ searchword: searchword })

        //検索ワードがある場合、全件取得 検索ワードを消した場合も全件取得
        //if (searchword != '') {
        var total = this.props.modelList[this.props.modelName + "_count"]
        this.setState({ numData: total });
        this.setState({ page: 1 });
        this.props.getModelList(this.props.modelName, 1, total, this.props.logininfo.id);
        //}
    }

    onChangeInputNumData = (e) => {
        e.preventDefault();

        var num = e.target.value;

        var total = this.props.modelList[this.props.modelName + "_count"]

        if (num >= total) {
            num = total;
        } else {
            //全件１ページ表示字のみ検索可
            this.setState({ searchword: '' })

        }

        this.setState({ numData: num });

        if (num == null || num == "") {
            this.props.getModelList(this.props.modelName, this.state.page, 1, this.props.logininfo.id);
        } else {
            this.props.getModelList(this.props.modelName, this.state.page, num, this.props.logininfo.id);
        }

    }

    onChangePagination = (page) => {
        this.setState({ page: page });
        this.props.getModelList(this.props.modelName, page, this.state.numData, this.props.logininfo.id);
    }


    onChangeInputCommon = (e, key) => {
        e.preventDefault();
        var newFormValue = this.state.formValue;
        newFormValue[key] = e.target.value;
        this.setState({ formValue: newFormValue });
        //たぶんこれのせいで遅い
        //this.checkInputRequired();
    }

    handleDateTimeChange = (datetime, key) => {
        var newFormValue = this.state.formValue;
        newFormValue[key] = DateTool.createJSONDateWithoutTime(datetime);
        //newFormValue[key] = datetime;
        this.setState({ formValue: newFormValue });
        //たぶんこれのせいで遅い
        //this.checkInputRequired();
    }

   

    checkInputRequired = () => {

        var unfilledRequiredFIeld = false

        Object.keys(this.props.modelProps).forEach((k) => {

            if (k != "id") {
                //入力必須項目に入力があるか
                if (this.props.modelProps[k].inputRequired == true) {
                    console.log('入力必須', this.props.modelProps[k])

                    switch (this.props.modelProps[k].type) {
                        case "number":
                            if (this.state.formValue[k] == 0) {
                                unfilledRequiredFIeld = true
                            }
                            return;
                        case "string":
                            if (this.state.formValue[k] == "") {
                                unfilledRequiredFIeld = true
                            }
                            return;
                        case "date":
                            if (this.state.formValue[k] == "") {
                                unfilledRequiredFIeld = true
                            }
                            return;
                        case "time":
                            if (this.state.formValue[k] == "") {
                                unfilledRequiredFIeld = true
                            }
                            return;
                        case "select":
                            if (this.state.formValue[k] == "") {
                                unfilledRequiredFIeld = true
                            }
                            return;
                    }
                }
            }

        })

        this.setState({ unfilledRequiredFIeld: unfilledRequiredFIeld });

        return unfilledRequiredFIeld
    }

    render() {

        var keys = Object.keys(this.props.modelProps);

        const { classes } = this.props;

        const nums = [];
        for (let i = 1; i <= 50; i++) {
            nums.push(i);
        }

        return (
            <Grid
                container
                direction="row"
                justify="space-around"
                alignItems="center"
            >
                <Grid item xs={12}  >
                    <Typography variant="h2" component="h2">
                        <Box fontSize="h15.fontsize" m={1} fontStyle="oblique">{this.props.modelName}</Box>
                    </Typography>
                </Grid>
                <Grid item xs={5}  >
                    <SimpleCrudModalCreate
                        modelProps={this.props.modelProps}
                        modelName={this.props.modelName}
                    />
                </Grid>
                <Grid item xs={7}  >
                    <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={3}  >
                            <Paper className={classes.buttonback} >
                                <Grid container  >
                                    <Grid item xs={4}  >
                                        <Typography variant="h6">Import</Typography>
                                    </Grid>
                                    <Grid item xs={4}  >
                                        <DropzoneAreaModal
                                            buttonName="JSON"
                                            socket={this.props.socket}
                                            importAction={this.props.cleanImportJson}
                                            modelName={this.props.modelNameSingolare}
                                            access_token={this.props.logininfo.id}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs={6}  >
                            <Paper className={classes.buttonback} >
                                <Grid
                                    container
                                    direction="row"
                                    justify="space-evenly"
                                    alignItems="center"
                                >

                                    <Typography variant="h6" >Export</Typography>


                                    <Button
                                        variant="outlined"
                                        size="medium"
                                        className={classes.button}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.props.exportExcel(this.props.modelName, this.props.logininfo.id)
                                        }}
                                    >Excel</Button>
                                    <Button
                                        variant="outlined"
                                        size="medium"
                                        className={classes.button}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.props.exportJson(this.props.modelNameSingolare, this.props.logininfo.id)
                                        }}
                                    >Json</Button>

                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}  >


                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                    >

                        <TextField
                            id="standard-basic"
                            label="cerca..."
                            value={this.state.searchword}
                            onChange={(e) => {
                                e.preventDefault();
                                this.onChangeSearchWord(e.target.value)
                            }}
                            className={
                                this.state.searchword == ''
                                    ? null
                                    : classNames(classes.cell_searched)
                            }
                        />
                        <Pagination
                            onChange={(e, page) => { e.preventDefault(); this.onChangePagination(page) }}
                            page={this.state.page}
                            count={this.props.modelList
                                ? Math.ceil(this.props.modelList[this.props.modelName + "_count"] / this.state.numData)
                                : 3}
                        />
                        {this.state.searchword == ''
                            ? <TextField
                                id="standard-basic"
                                label="number of datas"
                                value={this.state.numData}
                                onChange={(e) => { this.onChangeInputNumData(e) }}
                            />
                            : null
                        }

                        {this.state.searchword == ''
                            ? <Typography variant="h6" >total:{this.props.modelList[this.props.modelName + "_count"]}</Typography>
                            : null
                        }


                    </Grid>
                </Grid>
                <Grid item xs={12} >
                    <div >
                        <TableContainer className={classes.container} >
                            <Table stickyHeader aria-label="a dense table"  >
                                <TableHead className={classes.tableHead} >
                                    <TableRow >
                                        {keys.map((k) => {
                                            if (k != "DefaultNum") {

                                                //表示ラベルの設定がある場合はそれを適用
                                                var label = this.props.modelProps[k].label ? this.props.modelProps[k].label : k

                                                return (<TableCell key={'hd_'+k} align="left" style={{ backgroundColor: '#BDBDBD' }} >{label}</TableCell>);

                                            }
                                        })}
                                        <TableCell align="left" style={{ backgroundColor: '#BDBDBD' }} ></TableCell>
                                        <TableCell align="left" style={{ backgroundColor: '#BDBDBD' }}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody >
                                    {this.props.modelList[this.props.modelName].map((m) => {

                                        var showData = false;

                                        if (this.state.searchword == '') {
                                            showData = true;
                                        } else {



                                            //Array.prototype.some()という関数があります。コールバック関数がtrueを返すと即座にtrueを返すというテスト関数
                                            keys.some((key) => {
                                                if (key != "DefaultNum") {
                                                    if (m[key] != null &&
                                                        String(m[key]).toLowerCase().indexOf(this.state.searchword.toLowerCase()) != -1) {
                                                        showData = true;


                                                        return true;
                                                    }
                                                }
                                            })


                                        }

                                        if (showData) {


                                            return (
                                                <TableRow key={m.id} className={classes.row}>
                                                    {keys.map((key) => {

                                                        if (key != "DefaultNum") {
                                                            if (key == "id") {
                                                                return (
                                                                    <TableCell
                                                                        align="left"

                                                                        className={
                                                                            this.state.searchword == '' || m[key] == null
                                                                                ? classNames(classes.cell, classes.cell_short)
                                                                                : String(m[key]).toLowerCase().indexOf(this.state.searchword.toLowerCase()) == -1
                                                                                    ? classNames(classes.cell, classes.cell_short)
                                                                                    : classNames(classes.cell, classes.cell_short, classes.cell_searched)
                                                                        } >
                                                                        {m[key]}
                                                                    </TableCell>
                                                                )
                                                            } else if (key == "japanese") {
                                                                return (
                                                                    <TableCell
                                                                        align="left"
                                                                        className={
                                                                            this.state.searchword == '' || m[key] == null
                                                                                ? classNames(classes.cell)
                                                                                : String(m[key]).toLowerCase().indexOf(this.state.searchword.toLowerCase()) == -1
                                                                                    ? classNames(classes.cell)
                                                                                    : classNames(classes.cell, classes.cell_searched)
                                                                        }
                                                                    >
                                                                        <RubyText
                                                                            rubyArr={Ruby.createRubyArray(m[key])} />
                                                                    </TableCell>
                                                                )
                                                            } else {
                                                                if (this.props.modelProps[key]["type"] == "number") {
                                                                    return (
                                                                        <TableCell
                                                                            align="left"
                                                                            className={
                                                                                this.state.searchword == '' || m[key] == null
                                                                                    ? classNames(classes.cell, classes.cell_medium)
                                                                                    : String(m[key]).toLowerCase().indexOf(this.state.searchword.toLowerCase()) == -1
                                                                                        ? classNames(classes.cell, classes.cell_medium)
                                                                                        : classNames(classes.cell, classes.cell_medium, classes.cell_searched)
                                                                            }
                                                                        >
                                                                            {m[key]}
                                                                        </TableCell>
                                                                    )
                                                                } else if (this.props.modelProps[key]["type"] == "date") {
                                                                    return (
                                                                        <TableCell
                                                                            align="left"
                                                                            className={
                                                                                this.state.searchword == '' || m[key] == null
                                                                                    ? classNames(classes.cell)
                                                                                    : String(m[key]).toLowerCase().indexOf(this.state.searchword.toLowerCase()) == -1
                                                                                        ? classNames(classes.cell)
                                                                                        : classNames(classes.cell, classes.cell_searched)
                                                                            }
                                                                        >
                                                                            {StrTool.createStrYYYY_MM_DD(m[key])}
                                                                        </TableCell>
                                                                    )
                                                                } else {
                                                                    return (<TableCell
                                                                        align="left"
                                                                        className={
                                                                            this.state.searchword == '' || m[key] == null
                                                                                ? classNames(classes.cell)
                                                                                : String(m[key]).toLowerCase().indexOf(this.state.searchword.toLowerCase()) == -1
                                                                                    ? classNames(classes.cell)
                                                                                    : classNames(classes.cell, classes.cell_searched)
                                                                        }
                                                                    >
                                                                        {m[key]}
                                                                    </TableCell>)
                                                                }
                                                            }
                                                        }
                                                    })}
                                                    <TableCell
                                                        align="left"
                                                        className={classNames(classes.cell, classes.cell_short, )}
                                                    >
                                                        <SimpleCrudModalUpdate
                                                            modelProps={this.props.modelProps}
                                                            modelName={this.props.modelName} 
                                                            modelValue={m}/>
                                                    </TableCell>
                                                    <TableCell
                                                        align="right"
                                                        className={classNames(classes.cell, classes.cell_short)}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            if (window.confirm("deleting data id : " + m['id'])) {
                                                                this.props.submitDelete(this.props.socket, this.props.modelName, this.state.page, this.state.numData, m.id, this.props.logininfo.id)
                                                            }
                                                        }}>
                                                        <DeleteForeverIcon className={classNames(classes.cell_clickable)}/>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        } else {

                                            return null;
                                        }


                                    })}


                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </Grid >
            </Grid >

        );
    }
}


const mapStateToProps = (state) => {
    return {
        modelList: state.model,
        socket: state.webSocket.socket,
        logininfo: state.auth.logininfo

    }
}

const mapDispatchToProps = (dispatch) => {

    return {

        setupWebSocket: (access_token) => {
            dispatch(WebSocketAction.setupWebSocket(access_token))
        },
        getModelList: (modelName, page, numData, access_token) => {
            dispatch(ModelAction.apiGet(modelName, page, numData, [], access_token))
        },
        getModelCount: (modelName, access_token) => {
            dispatch(ModelAction.apiGetCount(modelName, access_token))
        },
        submitDelete: (socket, modelName, page, numData, id, access_token) => {
            dispatch(ModelAction.apiDelete(socket, modelName, page, numData, id, access_token))
        },
        exportJson: (modelNameSingolare, access_token) => {
            dispatch(ModelAction.apiExportJson(modelNameSingolare, access_token))
        },
        importJson: (socket, modelName, file, access_token) => {
            dispatch(ModelAction.apiImportJson(socket, modelName, file, access_token))
        },
        cleanImportJson: (socket, modelName, file, access_token) => {
            dispatch(ModelAction.apiCleanImportJson(socket, modelName, file, access_token))
        },
        exportExcel: (modelName, access_token) => {
            dispatch(ModelAction.apiExportExcel(modelName, access_token))
        },
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(SimpleCrud));
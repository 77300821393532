

const defaultState = {
    Stipendi_From_Presenza: null
}

export const stipendi = (state = defaultState, action) => {

    console.log(action.type);
    switch (action.type) {
        case 'Stipendi_From_Presenza':  //Stipendi画面からpresenzaを取得するときに使っている
            return {
                ...state,
                Stipendi_From_Presenza: action.value
            }
        default: return state
    }
}   
import axios from 'axios';
import * as LoopbackCall from '../../util/LoopbackCall';
import * as StrTool from '../../util/StrTool';


export const download_pdf = (pagamentoId, access_token, cb) => {
    return (dispatch) => {
        axios.post(LoopbackCall.url + `Ricevuta/download_pdf`,
            null,
            {
                params: {
                    pagamentoId: pagamentoId,
                    access_token: access_token
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/pdf'
                },
                responseType: 'arraybuffer'
            }).then((response) => {

                if (response.status == 200) {
                    console.log("download_pdf成功", response.data);
                    console.log(response.headers);


                    var responseHeader = response.headers;

                    console.log(responseHeader["content-type"].split(";")[1].replace('filename=', ''));

                    var filename = responseHeader["content-type"].split(";")[1].replace('filename=', '');

                    //Content-Type: application/pdf;filename=M_pacchettoSocio_37_FrancoRossi.pdf


                    var FileSaver = require('file-saver');
                    FileSaver.saveAs(
                        new Blob(
                            [response.data],
                            { type: "application/pdf" }),
                        filename
                    );

                    cb(null)
                    /*
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    */
                }

            }).catch((error) => {
                
                if (error.response.status === 401) {
                    console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                    dispatch({
                        type: "ACCESSTOKEN_EXPIRED"
                    })
                }

                console.log("download_pdf失敗", error)
                cb(error)
            })
    }

}



export const numberingRicevuta = (pagamentoId, access_token, cb) => {

    return (dispatch) => {
        axios.post(LoopbackCall.url + `Ricevuta/numberingRicevuta`,
            null,
            {
                params: {
                    PagamentoId: pagamentoId,
                    access_token: access_token
                },
            }).then((response) => {

                if (response.status == 200) {
                    console.log("numberingRicevuta成功", response.data);
                    cb(response.data.id, response.data.filename);
                }

            }).catch((error) => {

                
                if (error.response.status === 401) {
                    console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                    dispatch({
                        type: "ACCESSTOKEN_EXPIRED"
                    })
                }
                console.log("numberingRicevuta失敗", error)
            })
    }
}

export const updateDataDiInvioRicevutaOrResult = (ricevutaId, error, access_token, socket, currentPage, currentNumData) => {
    return (dispatch) => {
        axios.post(LoopbackCall.url + `Ricevuta/updateDataDiInvioRicevutaOrResult`,
            null,
            {
                params: {
                    RicevutaId: ricevutaId,
                    Error: error,
                    access_token: access_token
                },
            }).then((response) => {

                if (response.status == 200) {
                    console.log("updateDataDiInvioRicevutaOrResult", response.data);

                    socket.send(JSON.stringify({
                        type: 'updateModel',
                        modelName: 'Ricevuta',
                        currentPage: currentPage ? currentPage : 1,
                        currentNumData: currentNumData ? currentNumData : 200
                    }));




                }

            }).catch((error) => {

                
                if (error.response.status === 401) {
                    console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                    dispatch({
                        type: "ACCESSTOKEN_EXPIRED"
                    })
                }
                console.log("updateDataDiInvioRicevutaOrResult失敗", error)
            })
    }
}


export const createAndSendPdf = (pagamentoId, access_token, cb) => {

    return (dispatch) => {

        axios.post(LoopbackCall.url + `Ricevuta/createAndSendPdf`,
            null,
            {
                params: {
                    PagamentoId: pagamentoId,
                    access_token: access_token
                },
            }).then((response) => {

                console.log(response)
                cb(response.status, response)


            }).catch((error) => {

                
                if (error.response.status === 401) {
                    console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                    dispatch({
                        type: "ACCESSTOKEN_EXPIRED"
                    })
                }

                cb('error', error)
            })
    }
}




export const createRicevuta = (pagamentoId, access_token, cb) => {
    return (dispatch) => {
        axios.post(LoopbackCall.url + `Ricevuta/createPdf`,
            null,
            {
                params: {
                    PagamentoId: pagamentoId,
                    access_token: access_token
                },
            }).then((response) => {
                if (response.status == 200) {
                    console.log("createRicevuta成功", response.data);
                    cb(response.data.id, response.data.filename);
                    dispatch({ type: "CREATE_RICEVUTA_COMPLETE" })
                }
            }).catch((error) => {

                
                if (error.response.status === 401) {
                    console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                    dispatch({
                        type: "ACCESSTOKEN_EXPIRED"
                    })
                }
                console.log("createRicevuta失敗", error)
            })
    }
}

export const getRicevutaById = (RicevutaId, access_token, cb) => {

    return (dispatch) => {

        axios.get(LoopbackCall.url + `Ricevuta/` + RicevutaId
            , { params: { access_token: access_token } })
            .then((response) => {
                if (response.status == 200) {
                    cb(response.data.Pdf)
                }
            }).catch((error) => {

                
                if (error.response.status === 401) {
                    console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                    dispatch({
                        type: "ACCESSTOKEN_EXPIRED"
                    })
                }
                console.log("getRicevutaById ERR", error)
            })

    }
}


export const createRicevutaFromStream = (pagamentoId) => {

    return (dispatch) => {


        axios.post(LoopbackCall.url + `Ricevuta/createPdfStream`,
            null,
            {
                params: {
                    'PagamentoId': pagamentoId,
                }
            }).then((response) => {


                if (response.status == 200) {


                    var FileSaver = require('file-saver');

                    FileSaver.saveAs(
                        new Blob(
                            [response.data],
                            { type: "application/pdf" }),
                        "aaa.pdf"
                    );

                }



            }).catch((error) => {

                
                if (error.response.status === 401) {
                    console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                    dispatch({
                        type: "ACCESSTOKEN_EXPIRED"
                    })
                }
                console.log("createRicevuta失敗", error)
            })


    }



}


export const sendEmail = (RicevutaId, access_token, cb) => {

    return (dispatch) => {

        axios.get(LoopbackCall.url + `Ricevuta/` + RicevutaId
            , { params: { access_token: access_token } })
            .then((response) => {
                if (response.status == 200) {
                    cb(response.data.Pdf)
                }
            }).catch((error) => {

                
                if (error.response.status === 401) {
                    console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                    dispatch({
                        type: "ACCESSTOKEN_EXPIRED"
                    })
                }
                console.log("getRicevutaById ERR", error)
            })

    }
}



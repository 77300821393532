import React, { Component } from 'react';

import classNames from 'classnames';
import { withStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import SimpleCrud from '../../common/SimpleCrud'
import InputJapaneseWithRuby from '../../common/InputJapaneseWithRuby'




const styles = theme => ({

    root: {
        flexGrow: 1,
        margin: 20
    },
    paper: {
        height: 100,
        width: 800,
        margin: 10,
        padding: 10,
        '&:hover': {
            background: "#CACACA"
        }
    },
    control: {
        padding: theme.spacing(2),
    }
});

const modelProps = {

    //一覧に表示するデータの数
    "DefaultNum": 500,

    "id": {
        "type": "string"
    },
    "Category": {
        "type": "select",
        "option": [
            {
                "label":"pacchettoSocio",
                "val":"pacchettoSocio"
            },
            {
                "label":"quotaCsen",
                "val":"quotaCsen"
            },
            {
                "label":"Consulenza",
                "val":"Consulenza"
            },
        ],
        "inputRequired" : true 
    },
    "Seq": {
        "type": "number",
        "inputRequired" : true 
    },
    "Pdf": {
        "type": "string",
        "defaultValue": "Carta",
        "inputRequired" : true 
    },
    "DataDiInvioRicevuta": {
        "type": "string"
    },
    "SocioId": {
        "type": "number"
    },
    "PagamentoId": {
        "type": "number"
    }
}

class Ricevuta extends Component {

    constructor(props) {
        super(props);

    }



    render() {

        const { classes } = this.props;

        return (
            <SimpleCrud
                modelName="Ricevuta"
                modelNameSingolare="Ricevuta"
                modelProps={modelProps}
            />
        );
    }
}


const mapStateToProps = (state) => {
    return {

    }

}

const mapDispatchToProps = (dispatch) => {

    return {


    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Ricevuta));
import React, { Component } from 'react';

import Card from '@material-ui/core/Card';
import classNames from 'classnames'
import Grid from '@material-ui/core/Grid';
import { withStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import { connect } from 'react-redux';
import { createMuiTheme } from '@material-ui/core'
import * as AuthAction from '../../store/actions/AuthAction';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link as RouterLink } from 'react-router-dom';


const styles = theme => ({

  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  table: {
    minWidth: 650,
  },
  tableHead: {
    background: 'linear-gradient(45deg, #8C8C8C, #BDBDBD)'
  },

  row: {
    '&:hover': {
      background: "#CACACA"
    }
  },
  cell: {
    '&:hover': {
      background: "#b9b9b9"
    },
  },
  modal: {
    display: 'block',
    position: 'absolute',
    overflow: 'scroll',
  },
  modalpaper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: 900,
  },
  buttonback: {
    backgroundColor: "#b9b9b9",
    border: '0px solid #000',
    boxShadow: theme.shadows[0],
    padding: theme.spacing(1.5, 1.5, 1.5),

  },
  formControlS: {
    margin: theme.spacing(1),
    width: 120,
  },
  formControlL: {
    margin: theme.spacing(1),
    width: 800
  },
  grammar: {
    width: 800
  },
  grammarPreview: {
    width: 800
  },
  cell_short: {
    fontSize: "10px",
    width: 50,
  },
  cell_medium: {
    fontSize: "10px",
    width: 100,
  },
  cell_clickable: {
    cursor: "pointer"
  },
  gridItem: {
  },
  button: {
    background: 'white',
    borderRadius: 3,
    border: 0,
    color: 'black',
    padding: '0 20px',
    '&:hover': {
      background: "#b9b9b9"
    },
  },
  colorYellow: {
    background: "#FFEA83"
  },
  colorRed: {
    background: "#FF9983"
  },
  colorGreen: {
    background: "#83FFD8"
  },
  card: {
    width: 400,
    //height: 500,
    padding: 10,
    marginTop: 150,
    background: 'white'
  },
  backgroundgradient: {
    background: 'linear-gradient(45deg, #1646AE, #6E6E6E)',

  }

});

const customTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#bfbfbf',
      light: '#969696',
      dark: '#616060',
    },
    secondary: {
      main: '#616060',
    },
  },
})


class ChangePassword extends Component {

  constructor(props) {
    super(props);

    this.state = {
      newpassword: '',
      newpasswordconfirm: '',
      passwordChangeAttempt: false,
      passwordChangeResult: false
    }

  }

  componentDidMount() {



  }





  render() {

    const { classes } = this.props;


    return (

      <Box >
        <Grid container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Card className={classes.card}>
            <Grid container justify="center"  >
              <Grid item xs={12}  >
                <h4>Change Password</h4>
              </Grid>
              <Grid item xs={12}  >
                <FormControl className={classes.formControlM}>
                  <TextField
                    type='password'
                    label='new password'
                    value={this.state.newpassword}
                    onChange={(e) => {
                      e.preventDefault();
                      this.setState({ newpassword: e.target.value })
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}  >
                <FormControl className={classes.formControlM}>
                  <TextField
                    type='password'
                    label='confirm new password '
                    value={this.state.newpasswordconfirm}
                    onChange={(e) => {
                      e.preventDefault();
                      this.setState({ newpasswordconfirm: e.target.value })
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}  >
                <h5> </h5>
              </Grid>
              <Grid item xs={12} >
                {this.state.newpassword.length < 8 &&
                  this.state.newpasswordconfirm.length < 8
                  ? <div>
                    <Button variant="contained" disabled>Change Password</Button>
                    <p><font color="#F50057">please set password * minimum 8 letters</font></p>
                  </div>
                  : this.state.newpassword == this.state.newpasswordconfirm
                    ? this.state.passwordChangeResult && this.state.passwordChangeAttempt
                      ? <div>
                        <Button variant="contained" disabled>Change Password</Button>
                        <p><font color="#F50057">Password has been changed!</font></p>
                      </div>

                      : <Button
                        variant="contained"
                        color="secondary"
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({ passwordChangeAttempt: true });
                          this.props.setNewPassword(
                            this.props.email,
                            this.state.newpassword,
                            (result) => {
                              this.setState({ passwordChangeResult: result })
                            }
                          )

                        }}>Change Password</Button>
                    : <div>
                      <Button variant="contained" disabled>Change Password</Button>
                      <p><font color="#F50057">password doesn't match</font></p>
                    </div>
                }



              </Grid>



            </Grid >
          </Card>
        </Grid>

      </Box>

    );
  }
}



const mapStateToProps = (state) => {
  return {
    modelList: state.model,
    socket: state.webSocket.socket,
    logininfo: state.auth.logininfo

  }
}

const mapDispatchToProps = (dispatch) => {

  return {


    setNewPassword: (email, password, cb) => {
      dispatch(AuthAction.setNewPassword(email, password, cb))
    },

  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(ChangePassword));
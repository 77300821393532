import React, { Component } from 'react';
import { TabScrollButton } from '@material-ui/core';

class RubyText extends Component {

    render() {

        return (
            <p className="inlineText" style={{ verticalAlign: 'bottom' }} >
                {this.props.rubyArr.map((ob) => {
                    if (ob.ruby === "") {
                        if (ob.text === "") {
                            return "";
                        } else {
                            return (<ruby >{ob.text}<rt >&nbsp;  </rt></ruby>);
                        }
                    } else {
                        return (<ruby >{ob.text}<rp >（</rp><rt >{ob.ruby}</rt><rp>）</rp></ruby>);
                    }
                })}
            </p>
        );
    }
}

export default RubyText;
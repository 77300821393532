import axios from 'axios';
import * as LoopbackCall from '../../util/LoopbackCall';
import * as StrTool from '../../util/StrTool';


export const getSociMensiliBySocioIdAnnoAccademico = (SocioId, AnnoAccademico,access_token) => {

    //{"where":{"SocioId":1 , "AnnoAccademico" : "2020-2021"}}

    return (dispatch) => {
        axios.get(LoopbackCall.url + `SocioMensile`, {
            params: {
                filter: {
                    where: {
                        SocioId: SocioId,
                        AnnoAccademico: AnnoAccademico
                    }
                },
                access_token: access_token
            }
        }).then((response) => {
            if (response.status == 200) {

                console.log("getSociMensiliBySocioIdAnnoAccademico成功");

                if (response.data.length == 1) {
                    dispatch({
                        type: "GET_BY_SOCIOID_SociMensili",
                        value: response.data[0]
                    })
                }
            }
        }).catch((error) => {

            
            if (error.response.status === 401) {
                console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                dispatch({
                    type: "ACCESSTOKEN_EXPIRED"
                })
            }
            console.log("getSociMensiliBySocioIdAnnoAccademico失敗", error)
        })
    }
}



export const transactionMessageClose = () => {

    return (dispatch) => {
        dispatch({ type: "PAGAMENTO_COMPLETE_MESSAGECLOSE" })
    }

}


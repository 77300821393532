

const defaultState = {
    sociCache: {},
    sociMensiliCache: {}
}

export const cache = (state = defaultState, action) => {

    console.log(action.type);
    switch (action.type) {
        case 'GET_BY_ID_Socio':

            var obj = state.sociCache;
            console.log("obj 1 =>", obj)

            obj[action.value.id] = action.value;

            console.log("obj 2 =>", obj)
            return { ...state, sociCache: obj }


        default: return state
    }
}


export const sociMensiliCache = (state = {}, action) => {

    console.log(action.type);
    switch (action.type) {

        case 'GET_BY_SOCIOID_SociMensili':

            var obj = {};
            obj[action.value.SocioId] = action.value;
            return { ...state, ...obj }

        default: return state
    }
}




export const sociPagamentoCache = (state = {}, action) => {

    console.log(action.type);
    switch (action.type) {

        case 'GET_BY_SOCIOID_Pagamento':

            var objPagamento = {};
            var objSocio = {}

            if (action.value.length != 0) {
                action.value.forEach((data) => {
                    objPagamento[data.id] = data;
                })

                objSocio[action.value[0].SocioId] = objPagamento;
            }
            return { ...state, ...objSocio }

        default: return state
    }
}

///////////////////////////////////////////////////////////////
//支払い登録後に詳細を表示するためのキャッシュ
export const pagamentoCache = (state = {}, action) => {

    console.log(action.type);
    switch (action.type) {

        case 'GET_BY_ID_Pagamento':

            var objPagamento = {};

            if (action.value != null) {
                console.log('action.value',action.value)
                console.log('action.value[id]',action.value['id'])
                console.log('action.value.id',action.value.id)
                objPagamento[action.value.id] = action.value;
            }

            console.log('aaa',objPagamento)
            return { ...state, ...objPagamento }

        default: return state
    }
}
import React, { Component } from 'react';

import classNames from 'classnames';
import { withStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import SimpleCrud from '../../common/SimpleCrud'




const styles = theme => ({

    root: {
        flexGrow: 1,
        margin: 20
    },
    paper: {
        height: 100,
        width: 800,
        margin: 10,
        padding: 10,
        '&:hover': {
            background: "#CACACA"
        }
    },
    control: {
        padding: theme.spacing(2),
    }
});

const modelProps = {

    //一覧に表示するデータの数
    "DefaultNum": 500,

    "NomeSocio": {
        "label":"Nome",
        "type": "string",
        "inputRequired" : true 
    },
    "Sezione": {
        "type": "select",
        "option": [
            {
                "label":"MODENA",
                "val":"MODENA"
            },
    //        {
    //            "label":"REGGIO",
    //            "val":"REGGIO"
    //        },
    //        {
    //            "label":"BELLARIA",
    //            "val":"BELLARIA"
    //        },
    //        {
    //            "label":"RIMINI",
    //            "val":"RIMINI"
    //        },
            {
                "label":"SANVITO",
                "val":"SANVITO"
            },
        ],
        "inputRequired" : true 
    },
    "AdultoBambino": {
        "type": "select",
        "option": [
            {
                "label":"Adulto",
                "val":"A"
            },
            {
                "label":"Ragazzo",
                "val":"R"
            },
            {
                "label":"Bambino",
                "val":"B"
            }
        ],
        "inputRequired" : true 
    },
    "CodiceFiscale": {
        "type": "string"
    },
    "NumeroDiTelefono": {
        "type": "string"
    },
    "VistaMedica": {
        "type": "select",
        "option": [
            {
                "label":"Si",
                "val":"Si"
            },
            {
                "label":"No",
                "val":"No"
            }
        ]
    },
    "Email": {
        "type": "string"
    },
    "DataDiNascita": {
        "type": "date"
    },
    "LuogoDiNascita": {
        "type": "string"
    },
    "ComuneDiResidenza": {
        "type": "string"
    },
    "Via": {
        "type": "string"
    },
    "NumeroCivico": {
        "type": "string"
    },
    "DataDiRichiestaAmmissioneSocio": {
        "type": "date"
    },
    "DataDiAccettaziooneSocio": {
        "type": "date"
    },
    "DataDiDimesso": {
        "type": "date"
    },
    "id": {
        "type": "string"
    },

}

class Soci extends Component {

    constructor(props) {
        super(props);

    }



    render() {

        const { classes } = this.props;

        return (
            <SimpleCrud
                modelName="Socio"
                modelNameSingolare="Socio"
                modelProps={modelProps}
            />




        );
    }
}


const mapStateToProps = (state) => {
    return {

    }

}

const mapDispatchToProps = (dispatch) => {

    return {


    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Soci));
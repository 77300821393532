import React, { Component } from 'react';

import classNames from 'classnames';
import { withStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import SimpleCrud from '../../common/SimpleCrud'
import InputJapaneseWithRuby from '../../common/InputJapaneseWithRuby'




const styles = theme => ({

    root: {
        flexGrow: 1,
        margin: 20
    },
    paper: {
        height: 100,
        width: 800,
        margin: 10,
        padding: 10,
        '&:hover': {
            background: "#CACACA"
        }
    },
    control: {
        padding: theme.spacing(2),
    }
});

const modelProps = {
    "id": {
        "type": "string"
    },
    "SocioId": {
        "type": "number"
    },
    "AnnoAccademico": {
        "type": "string"
    },
    "Contratto": {
        "type": "string"
    },
    "QuotaAikikaiImporto": {
        "type": "number"
    },
    "QuotaAikikaiPagamentoId": {
        "type": "number"
    },
    "QuotaAikikaiDescrizione": {
        "type": "string"
    },
    "QuotaCsenImporto": {
        "type": "number"
    },
    "QuotaCsenPagamentoId": {
        "type": "number"
    },
    "QuotaCsenDescrizione": {
        "type": "string"
    },
    "OttobreImporto": {
        "type": "number"
    },
    "OttobrePagamentoId": {
        "type": "number"
    },
    "OttobreDescrizione": {
        "type": "string"
    },
    "NovembreImporto": {
        "type": "number"
    },
    "NovembrePagamentoId": {
        "type": "number"
    },
    "NovembreDescrizione": {
        "type": "string"
    },
    "DicembreImporto": {
        "type": "number"
    },
    "DicembrePagamentoId": {
        "type": "number"
    },
    "DicembreDescrizione": {
        "type": "string"
    },
    "GennaioImporto": {
        "type": "number"
    },
    "GennaioPagamentoId": {
        "type": "number"
    },
    "GennaioDescrizione": {
        "type": "string"
    },
    "FebbraioImporto": {
        "type": "number"
    },
    "FebbraioPagamentoId": {
        "type": "number"
    },
    "FebbraioDescrizione": {
        "type": "string"
    },
    "MarzoImporto": {
        "type": "number"
    },
    "MarzoPagamentoId": {
        "type": "number"
    },
    "MarzoDescrizione": {
        "type": "string"
    },
    "AprileImporto": {
        "type": "number"
    },
    "AprilePagamentoId": {
        "type": "number"
    },
    "AprileDescrizione": {
        "type": "string"
    },
    "MaggioImporto": {
        "type": "number"
    },
    "MaggioPagamentoId": {
        "type": "number"
    },
    "MaggioDescrizione": {
        "type": "string"
    },
    "GiugnoImporto": {
        "type": "number"
    },
    "GiugnoPagamentoId": {
        "type": "number"
    },
    "GiugnoDescrizione": {
        "type": "string"
    },
    "LuglioImporto": {
        "type": "number"
    },
    "LuglioPagamentoId": {
        "type": "number"
    },
    "LuglioDescrizione": {
        "type": "string"
    },
    "AgostoImporto": {
        "type": "number"
    },
    "AgostoPagamentoId": {
        "type": "number"
    },
    "AgostoDescrizione": {
        "type": "string"
    },
    "SettembreImporto": {
        "type": "number"
    },
    "SettembrePagamentoId": {
        "type": "number"
    },
    "SettembreDescrizione": {
        "type": "string"
    }
}

class SociMensile extends Component {

    constructor(props) {
        super(props);

    }



    render() {

        const { classes } = this.props;

        return (
            <SimpleCrud
                modelName="SocioMensile"
                modelNameSingolare="SocioMensile"
                modelProps={modelProps}
            />
        );
    }
}


const mapStateToProps = (state) => {
    return {

    }

}

const mapDispatchToProps = (dispatch) => {

    return {


    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(SociMensile));
import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import { connect } from 'react-redux';
import classNames from 'classnames'
import { createMuiTheme } from '@material-ui/core'
import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import GridMensile from './GridMensile';
import { addMonths } from 'date-fns';
import Typography from '@material-ui/core/Typography';
import ModalDataSocio from './ModalDataSocio';
import SociMensileDashboard from './SociMensileSimple';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import DraftsIcon from '@material-ui/icons/Drafts';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import AccountCircle from '@material-ui/icons/AccountCircle';
import SearchIcon from '@material-ui/icons/Search';
import SociSearcher from './SociSearcher';
import * as ModelAction from '../../store/actions/ModelAction';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import SociMensileSimple from './SociMensileSimple'
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import * as PagamentoAction from '../../store/actions/PagamentoAction'
import * as SocioMensileAction from '../../store/actions/SocioMensileAction'
import * as RicevutaAction from '../../store/actions/RicevutaAction'
import * as DateTool from '../../util/DateTool'
import CloseIcon from '@material-ui/icons/Close';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import LinearProgress from '@material-ui/core/LinearProgress';

import Button from '@material-ui/core/Button';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const styles = theme => ({

    margin: {
        margin: theme.spacing(1),
    },
    sociList: {
        width: 210
    },
    paper: {
        width: 1200,
        height: 650,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3)
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttonEmettereRicevuta: {
        backgroundColor: '#ffd700',
        height: 60,
        width: 350,
    },
    buttonDownload: {
        backgroundColor: '#86FFC6',
        height: 60,
        width: 250,
    },
    buttonSendEmail: {
        backgroundColor: '#FFA85C',
        height: 60,
        width: 400,
    }

});


//Altro　支払いトランザクション完了後に表示するモーダル画面

class PagamentoAltroDetailModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            PasswordForRicevuta: null,
            ricevutaFileName: null,
            ricevutaId: null,
            downloadResult: null,
            downloadingProgress: false,
            inviato: false,
            emailResult: null,
            sendingProgress: false
        };
    }

    componentDidMount() {

    }

    handleInputPasswordForRicevuta = (e) => {
        this.setState({ PasswordForRicevuta: e.target.value })
    }


    render() {

        const { classes } = this.props;



        return (

            <div>

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={this.props.openModal}
                    onClose={() => {
                        this.props.setOpenModalFalse();
                        this.props.transactionMessageClose();
                    }}
                    closeAfterTransition
                    disableBackdropClick
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.props.openModal}>
                        <div className={classes.paper} >

                            <Grid
                                container
                                direction="column"
                                justify="space-evenly"
                                alignItems="flex-start"
                            >

                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-end"
                                    alignItems="center"
                                >
                                    <IconButton
                                        aria-label="delete"
                                        onClick={() => {
                                            this.props.setOpenModalFalse();
                                            this.props.transactionMessageClose();
                                            window.location.reload(false);
                                        }} >
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>


                                <h2 id="transition-modal-title">Pagamento Archiviato</h2>

                                {this.props.pagamentoCache[this.props.pagamentoId]

                                    ? <TableContainer component={Paper}>
                                        <Table className={classes.table} aria-label="simple table">
                                            <TableBody>
                                                <TableRow >
                                                    <TableCell align="left">Socio</TableCell>
                                                    <TableCell align="left">{this.props.name}</TableCell>
                                                </TableRow>
                                                <TableRow >
                                                    <TableCell align="left">DataDiPagamento</TableCell>
                                                    <TableCell align="left">{DateTool.createDateStringWithoutTimeFromJSONDate(this.props.pagamentoCache[this.props.pagamentoId]['DataDiPagamento'])}</TableCell>
                                                </TableRow>
                                                <TableRow >
                                                    <TableCell align="left">Importo</TableCell>
                                                    <TableCell align="left">€{this.props.pagamentoCache[this.props.pagamentoId]['Importo']}</TableCell>
                                                </TableRow>
                                                <TableRow >
                                                    <TableCell align="left">MetodoDiPagamento</TableCell>
                                                    <TableCell align="left">{this.props.pagamentoCache[this.props.pagamentoId]['MetodoDiPagamento']}</TableCell>
                                                </TableRow>
                                                <TableRow >
                                                    <TableCell align="left">Descrizione</TableCell>
                                                    <TableCell align="left">{this.props.pagamentoCache[this.props.pagamentoId]['Descrizione1']}</TableCell>
                                                </TableRow>
                                                <TableRow >
                                                    <TableCell align="left"></TableCell>
                                                    <TableCell align="left">{this.props.pagamentoCache[this.props.pagamentoId]['Descrizione2']}</TableCell>
                                                </TableRow>
                                                <TableRow >
                                                    <TableCell align="left"></TableCell>
                                                    <TableCell align="left">{this.props.pagamentoCache[this.props.pagamentoId]['Descrizione3']}</TableCell>
                                                </TableRow>

                                            </TableBody>
                                        </Table>
                                    </TableContainer>


                                    : null

                                }
                                <p />
                                <p />


                                <Grid
                                    container
                                    direction="row"
                                    justify="space-between"
                                    alignItems="flex-start"
                                >


                                    <div>
                                        <Button
                                            disabled={this.state.ricevutaId ? true : false}
                                            className={classes.buttonEmettereRicevuta}
                                            variant="contained"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.props.numberingRicevuta(
                                                    this.props.pagamentoId,
                                                    this.props.logininfo.id,
                                                    (RicevutaId, filename) => {
                                                        this.setState({ ricevutaId: RicevutaId })
                                                        this.setState({ ricevutaFileName: filename })
                                                    });
                                            }}>Emissione Ricevuta</Button>

                                        <p />

                                        {this.state.ricevutaId
                                            ? <div><AttachFileIcon />{this.state.ricevutaFileName}</div>
                                            : null}
                                    </div>


                                    <div>
                                        <Button
                                            disabled={this.state.ricevutaId ? false : true}
                                            className={classes.buttonDownload}
                                            variant="contained"
                                            onClick={(e) => {

                                                this.setState({ downloadResult: null })
                                                this.setState({ downloadingProgress: true })
                                                e.preventDefault();
                                                this.props.download_pdf(this.props.pagamentoId, this.props.logininfo.id,
                                                    (error) => {
                                                        if (error == null) {
                                                            this.setState({ downloadResult: 'downloaded' })
                                                        } else {
                                                            this.setState({ downloadResult: 'error' })
                                                        }
                                                        this.setState({ downloadingProgress: false })
                                                    })
                                            }}>Download</Button>

                                        <p />
                                        {this.state.downloadingProgress
                                            ? <LinearProgress />
                                            : this.state.downloadResult
                                                ? <div>{this.state.downloadResult}</div>
                                                : null}
                                    </div>


                                    <div>
                                        {
                                            this.props.socioOrEsterno && this.props.socioOrEsterno['Email']
                                                ? <Button
                                                    disabled={this.state.ricevutaId
                                                        ? this.state.inviato
                                                            ? true
                                                            : false
                                                        : true
                                                    }
                                                    className={classes.buttonSendEmail}
                                                    variant="contained"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.setState({ sendingProgress: true })
                                                        this.props.createAndSendPdf(
                                                            this.props.pagamentoId,
                                                            this.props.logininfo.id,
                                                            (status, response) => {

                                                                console.log('status=>=>', status)
                                                                this.setState({ sendingProgress: false })

                                                                if (status == 200) {
                                                                    this.setState({ emailResult: 'inviato' })
                                                                    this.setState({ inviato: true })
                                                                    this.props.updateDataDiInvioRicevutaOrResult(this.state.ricevutaId, null, this.props.logininfo.id, this.props.socket)

                                                                } else {
                                                                    this.setState({ emailResult: 'error! please send manually' })
                                                                    this.props.updateDataDiInvioRicevutaOrResult(null, 'error! please send manually', this.props.logininfo.id, this.props.socket)
                                                                }
                                                            });
                                                    }}><span>Inviare a : </span><u>{this.props.socioOrEsterno ? this.props.socioOrEsterno['Email'] : null}</u></Button>

                                                : <Button
                                                    disabled={true}
                                                    className={classes.buttonEmettereRicevuta}
                                                    variant="contained"
                                                >email not registered</Button>
                                        }
                                        <p />

                                        {this.state.sendingProgress
                                            ? <LinearProgress />
                                            : this.state.emailResult
                                                ? <div>{this.state.emailResult}</div>
                                                : null}
                                    </div>

                                </Grid>

                            </Grid>
                        </div>
                    </Fade>
                </Modal>
            </div>
        )
    }
}



const mapStateToProps = (state) => {
    return {
        //sociCache: state.cache.sociCache,
        socket: state.webSocket.socket,
        //sociMensiliCache: state.sociMensiliCache,
        //sociPagamentoCache: state.sociPagamentoCache,
        pagamentoCache: state.pagamentoCache,
        logininfo: state.auth.logininfo

    }
}

const mapDispatchToProps = (dispatch) => {

    return {
        getSociById: (id, access_token) => {
            dispatch(ModelAction.apiGetById('Socio', id, access_token))
        },
        transactionMessageClose: () => {
            dispatch(PagamentoAction.transactionMessageClose())
        },

        numberingRicevuta: (pagamentoId, access_token, cb) => {
            dispatch(RicevutaAction.numberingRicevuta(pagamentoId, access_token, cb))
        },

        createAndSendPdf: (pagamentoId, access_token, cb) => {
            dispatch(RicevutaAction.createAndSendPdf(pagamentoId, access_token, cb))
        },
        updateDataDiInvioRicevutaOrResult: (ricevutaId, error, access_token, socket, page, numData) => {
            dispatch(RicevutaAction.updateDataDiInvioRicevutaOrResult(ricevutaId, error, access_token, socket, page, numData))
        },

        download_pdf: (pagamentoId, access_token, cb) => {
            dispatch(RicevutaAction.download_pdf(pagamentoId, access_token, cb))
        },




        createRicevuta: (pagamentoId, access_token, cb) => {
            dispatch(RicevutaAction.createRicevuta(pagamentoId, access_token, cb))
        },
        createRicevutaFromStream: (pagamentoId) => {
            dispatch(RicevutaAction.createRicevutaFromStream(pagamentoId))
        },
        getRicevutaByIdWithNewWindow: (RicevutaId, access_token) => {
            dispatch(RicevutaAction.getRicevutaById(RicevutaId, access_token, (Pdf) => {
                window.open('http://localhost:2000/ricevuta/' + Pdf);
            }))
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(PagamentoAltroDetailModal));
import React, { Component } from 'react';

import classNames from 'classnames'
import Grid from '@material-ui/core/Grid';
import { withStyles } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import * as WebSocketAction from '../../store/actions/WebSocketAction';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import * as ModelAction from '../../store/actions/ModelAction'
import { connect } from 'react-redux';
import InputJapaneseWithRuby from '../common/InputJapaneseWithRuby';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { createMuiTheme } from '@material-ui/core'
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import * as DateTool from '../../util/DateTool';
import * as StrTool from '../../util/StrTool';
import Pagination from '@material-ui/lab/Pagination';
import MailIcon from '@material-ui/icons/Mail';
import * as RicevutaAction from '../../store/actions/RicevutaAction'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import LinearProgress from '@material-ui/core/LinearProgress';


const styles = theme => ({

    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    table: {
        minWidth: 650,
    },
    tableHead: {
        background: 'linear-gradient(45deg, #8C8C8C, #BDBDBD)'
    },

    row: {
        '&:hover': {
            background: "#CACACA"
        }
    },
    cell: {
        '&:hover': {
            background: "#b9b9b9"
        },
    },
    modal: {
        display: 'block',
        position: 'absolute',
        overflow: 'scroll',
    },
    modalpaper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: 900,
    },
    buttonback: {
        backgroundColor: "#b9b9b9",
        border: '0px solid #000',
        boxShadow: theme.shadows[0],
        padding: theme.spacing(1.5, 1.5, 1.5),

    },
    formControlS: {
        margin: theme.spacing(1),
        width: 120,
    },
    formControlL: {
        margin: theme.spacing(1),
        width: 800
    },
    grammar: {
        width: 800
    },
    grammarPreview: {
        width: 800
    },
    cell_short: {
        fontSize: "10px",
        width: 50,
    },
    cell_medium: {
        fontSize: "10px",
        width: 100,
    },
    cell_clickable: {
        cursor: "pointer"
    },
    gridItem: {
    },
    button: {
        background: 'white',
        borderRadius: 3,
        border: 0,
        color: 'black',
        padding: '0 20px',
        '&:hover': {
            background: "#b9b9b9"
        },
    },
    colorYellow: {
        background: "#FFEA83"
    },
    colorRed: {
        background: "#FF9983"
    },
    colorGreen: {
        background: "#83FFD8"
    }
});

const customTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#bfbfbf',
            light: '#969696',
            dark: '#616060',
        },
        secondary: {
            main: '#616060',
        },
    },
})


const modelProps = {
    "id": {
        "type": "string"
    },
    "Category": {
        "type": "string"
    },
    "Seq": {
        "type": "number"
    },
    "Pdf": {
        "type": "string"
    },
    "DataDiInvioRicevuta": {
        "type": "string"
    },
    "SocioId": {
        "type": "number"
    },
    "PagamentoId": {
        "type": "number"
    }
}


const modelName = "Ricevuta"
const modelNameSingolare = "Ricevuta"

var defaultFormValue = {};

class Ricevuta extends Component {

    constructor(props) {
        super(props);

        Object.keys(modelProps).forEach((k) => {

            if (k != "id") {
                switch (modelProps[k].type) {
                    case "number":
                        defaultFormValue[k] = 0;
                        return;
                    case "string":
                        defaultFormValue[k] = "";
                        return;
                    case "date":
                        defaultFormValue[k] = DateTool.createJSONDateWithoutTime(new Date());
                        return;
                    case "time":
                        defaultFormValue[k] = DateTool.createJSONDateWithoutTime(new Date());
                        return;

                }
            }
        })

        this.state = null;
        this.state = { formValue: defaultFormValue, page: 1, numData: 200, downloadingProgressRicevutaIdArr: [], sendingProgressRicevutaIdArr: [] };

    }

    componentDidMount() {
        this.props.getModelCount(modelName, this.props.logininfo.id)
        this.props.getModelList(modelName, this.state.page, this.state.numData, this.props.logininfo.id);
        this.props.setupWebSocket(this.props.logininfo.id);
    }


    handlCreateModalOpen = () => {
        this.setState({
            openCreateModal: true,
            formValue: Object.create(defaultFormValue)
        })
    };

    handlUpdateModalOpen = (model) => {
        console.log("handlUpdateModalOpen", model)

        this.setState({
            openUpdateModal: true,
            formValue: model

        })
    };

    handleModalClose = () => {
        this.setState({ openCreateModal: false, openUpdateModal: false })
    };

    onChangeInputNumData = (e) => {
        e.preventDefault();

        var num = e.target.value;

        if (num > 500) {
            num = 500;
        }

        this.setState({ numData: num });

        if (num == null || num == "") {
            this.props.getModelList(modelName, this.state.page, 1, this.props.logininfo.id);
        } else {
            this.props.getModelList(modelName, this.state.page, num, this.props.logininfo.id);
        }

    }

    onChangePagination = (page) => {
        this.setState({ page: page });
        this.props.getModelList(modelName, page, this.state.numData, this.props.logininfo.id);
    }


    onChangeInputCommon = (e, key) => {
        e.preventDefault();
        var newFormValue = this.state.formValue;
        newFormValue[key] = e.target.value;
        this.setState({ formValue: newFormValue });
    }

    handleDateTimeChange = (datetime, key) => {
        var newFormValue = this.state.formValue;
        newFormValue[key] = DateTool.createJSONDateWithoutTime(datetime);
        //newFormValue[key] = datetime;
        this.setState({ formValue: newFormValue });
    }


    render() {

        const { classes } = this.props;



        var keys = Object.keys(modelProps);

        return (
            <Grid
                container
                direction="row"
                justify="space-around"
                alignItems="center"
            >
                <Grid item xs={12}  >
                    <Typography variant="h2" component="h2">
                        <Box fontSize="h15.fontsize" m={1} fontStyle="oblique">{modelName}</Box>
                    </Typography>
                </Grid>

                <Grid item xs={12}  >
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                    >
                        <Pagination 
                            onChange={(e, page) => { e.preventDefault(); this.onChangePagination(page) }}
                            page={this.state.page}
                            count={this.props.modelList
                                ? Math.ceil(this.props.modelList[modelName + "_count"] / this.state.numData)
                                : 3}
                        />
                        <TextField
                            id="standard-basic"
                            label="number of datas"
                            value={this.state.numData}
                            onChange={(e) => { this.onChangeInputNumData(e) }}
                        />
                        <Typography variant="h20" component="h20">total:{this.props.modelList[modelName + "_count"]}</Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} >
                    <div class="card shadow mb-4">
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="a dense table">
                                <TableHead className={classes.tableHead}>
                                    <TableRow>
                                        <TableCell align="left">id</TableCell>
                                        <TableCell align="left">Category</TableCell>
                                        <TableCell align="left">Seq</TableCell>
                                        <TableCell align="left">Pdf</TableCell>
                                        <TableCell align="left">Mail</TableCell>
                                        <TableCell align="left">(error)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.props.modelList[modelName].map((m) => {
                                        return (
                                            <TableRow key={m.id} className={classes.row}>
                                                <TableCell
                                                    align="left" className={classNames(classes.cell, classes.cell_short)} >
                                                    {m['id']}
                                                </TableCell>
                                                <TableCell
                                                    align="left" className={classNames(classes.cell)} >
                                                    {m['Category']}
                                                </TableCell>
                                                <TableCell
                                                    align="left" className={classNames(classes.cell, classes.cell_short)} >
                                                    {m['Seq']}
                                                </TableCell>
                                                {this.state.downloadingProgressRicevutaIdArr.indexOf(m['id']) != -1
                                                    ? <TableCell align="left" className={classNames(classes.cell)}>
                                                        <LinearProgress />
                                                    </TableCell>
                                                    : <TableCell
                                                        align="left" className={m['PagamentoId'] ? classNames(classes.cell, classes.cell_clickable) : classNames(classes.cell)}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            if (m['PagamentoId'] != null) {
                                                                var downloadingProgressRicevutaIdArr = JSON.parse(JSON.stringify(this.state.downloadingProgressRicevutaIdArr));

                                                                if (downloadingProgressRicevutaIdArr.indexOf(m['id']) == -1) {
                                                                    downloadingProgressRicevutaIdArr.push(m['id'])
                                                                }

                                                                this.setState({ downloadingProgressRicevutaIdArr: downloadingProgressRicevutaIdArr })
                                                                this.props.download_pdf(
                                                                    m['PagamentoId'],
                                                                    this.props.logininfo.id,
                                                                    (error) => {

                                                                        var downloadingProgressRicevutaIdArr2 = JSON.parse(JSON.stringify(this.state.downloadingProgressRicevutaIdArr));

                                                                        if (downloadingProgressRicevutaIdArr2.indexOf(m['id']) != -1) {
                                                                            downloadingProgressRicevutaIdArr2.splice(downloadingProgressRicevutaIdArr2.indexOf(m['id']), 1)
                                                                        }

                                                                        this.setState({ downloadingProgressRicevutaIdArr: downloadingProgressRicevutaIdArr2 })

                                                                        var sendingProgressRicevutaIdArr2 = JSON.parse(JSON.stringify(this.state.sendingProgressRicevutaIdArr));

                                                                        if (sendingProgressRicevutaIdArr2.indexOf(m['id']) != -1) {
                                                                            sendingProgressRicevutaIdArr2.splice(sendingProgressRicevutaIdArr2.indexOf(m['id']), 1)
                                                                        }

                                                                        if (error) {
                                                                            window.alert("error while downloading");
                                                                        }

                                                                    });
                                                            }



                                                        }}>
                                                        {m['Pdf']}
                                                        {m['PagamentoId']
                                                            ? <ArrowDownwardIcon />
                                                            : null}
                                                    </TableCell>



                                                }


                                                {m['PagamentoId'] && m['SocioId']
                                                    ? this.state.sendingProgressRicevutaIdArr.indexOf(m['id']) != -1
                                                        ? <TableCell align="left" className={classNames(classes.cell)}>
                                                            <LinearProgress />
                                                        </TableCell>
                                                        : m['DataDiInvioRicevuta']
                                                            ? <TableCell align="left" className={classNames(classes.cell, classes.colorGreen)}>
                                                                inviato il {m['DataDiInvioRicevuta']}
                                                            </TableCell>
                                                            : m['Socio'] && m['Socio']['Email']
                                                                ? <TableCell align="left"
                                                                    className={classNames(classes.cell, classes.cell_clickable, classes.colorYellow)}
                                                                    onClick={(e) => {

                                                                        var sendingProgressRicevutaIdArr = JSON.parse(JSON.stringify(this.state.sendingProgressRicevutaIdArr));

                                                                        if (sendingProgressRicevutaIdArr.indexOf(m['id']) == -1) {
                                                                            sendingProgressRicevutaIdArr.push(m['id'])
                                                                        }


                                                                        this.setState({ sendingProgressRicevutaIdArr: sendingProgressRicevutaIdArr })
                                                                        this.props.createAndSendPdf(
                                                                            m['PagamentoId'],
                                                                            this.props.logininfo.id,
                                                                            (status, response) => {

                                                                                console.log('status=>=>', status)

                                                                                var sendingProgressRicevutaIdArr2 = JSON.parse(JSON.stringify(this.state.sendingProgressRicevutaIdArr));

                                                                                if (sendingProgressRicevutaIdArr2.indexOf(m['id']) != -1) {
                                                                                    sendingProgressRicevutaIdArr2.splice(sendingProgressRicevutaIdArr2.indexOf(m['id']), 1)
                                                                                }



                                                                                if (status == 200) {
                                                                                    this.props.updateDataDiInvioRicevutaOrResult(m['id'], null, this.props.logininfo.id, this.props.socket, this.state.page, this.state.numData,)

                                                                                } else {

                                                                                    this.props.updateDataDiInvioRicevutaOrResult(null, 'error! please send manually', this.props.logininfo.id, this.props.socket, this.state.page, this.state.numData)
                                                                                }

                                                                                this.setState({ sendingProgressRicevutaIdArr: sendingProgressRicevutaIdArr2 })


                                                                            });

                                                                    }}>
                                                                    inviare a : <MailIcon /> {m['Socio']['Email']}
                                                                </TableCell>
                                                                : <TableCell align="left" className={classNames(classes.cell, classes.colorRed)}>
                                                                    email not registered
                                                        </TableCell>
                                                    : <TableCell align="left" className={classNames(classes.cell)}>
                                                        -----
                                                </TableCell>
                                                }

                                                <TableCell
                                                    align="left" className={classNames(classes.cell)}>

                                                </TableCell>

                                                
                                            </TableRow>
                                        )
                                    })}


                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </Grid >
            </Grid >
        );
    }
}



const mapStateToProps = (state) => {
    return {
        modelList: state.model,
        socket: state.webSocket.socket,
        logininfo: state.auth.logininfo

    }
}

const mapDispatchToProps = (dispatch) => {

    return {
        createAndSendPdf: (pagamentoId, access_token, cb) => {
            dispatch(RicevutaAction.createAndSendPdf(pagamentoId, access_token, cb))
        },
        updateDataDiInvioRicevutaOrResult: (ricevutaId, error, access_token, socket) => {
            dispatch(RicevutaAction.updateDataDiInvioRicevutaOrResult(ricevutaId, error, access_token, socket))
        },
        download_pdf: (pagamentoId, access_token, cb) => {
            dispatch(RicevutaAction.download_pdf(pagamentoId, access_token, cb))
        },


        //以下SimpleCrudModalからのコピー

        setupWebSocket: (access_token) => {
            dispatch(WebSocketAction.setupWebSocket(access_token))
        },
        getModelList: (modelName, page, numData, access_token) => {
            dispatch(ModelAction.apiGet(modelName, page, numData, ['Socio'], access_token))
        },
        getModelCount: (modelName, access_token) => {
            dispatch(ModelAction.apiGetCount(modelName, access_token))
        },
        submitCreate: (socket, modelName, page, numData, formValue, access_token) => {
            dispatch(ModelAction.apiPost(socket, modelName, page, numData, formValue, access_token))
        },
        submitUpdate: (socket, modelName, page, numData, formValue, access_token) => {
            dispatch(ModelAction.apiPut(socket, modelName, page, numData, formValue, access_token))
        },
        submitDelete: (socket, modelName, page, numData, id, access_token) => {
            dispatch(ModelAction.apiDelete(socket, modelName, page, numData, id, access_token))
        },
        exportJson: (modelNameSingolare, access_token) => {
            dispatch(ModelAction.apiExportJson(modelNameSingolare, access_token))
        },
        importJson: (socket, modelName, file, access_token) => {
            dispatch(ModelAction.apiImportJson(socket, modelName, file, access_token))
        },
        exportExcel: (modelName, access_token) => {
            dispatch(ModelAction.apiExportExcel(modelName, access_token))
        },
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Ricevuta));
import React, { Component } from 'react';

import classNames from 'classnames';
import { withStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import InputJapaneseWithRuby from '../../common/InputJapaneseWithRuby'
import * as WebSocketAction from '../../../store/actions/WebSocketAction';
import * as ModelAction from '../../../store/actions/ModelAction'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { MmsSharp } from '@material-ui/icons';
import Divider from '@material-ui/core/Divider';


const styles = theme => ({

    paper: {
        height: 100,
        width: 800,
        margin: 10,
        padding: 10,
        '&:hover': {
            background: "#CACACA"
        }
    },
    control: {
        padding: theme.spacing(2),
    }
});


class Dashboard extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.getModelList('Pagamento', [], this.props.logininfo.id);
        this.props.setupWebSocket(this.props.logininfo.id);
    }





    render() {

        const { classes } = this.props;



        //var month = [9, 10, 11, 12, 1, 2, 3, 4, 5, 6, 7, 8]  [8, 7, 6, 5, 4, 3, 2, 1, 12, 11, 10, 9]
        var month = [8, 7, 6, 5, 4, 3, 2, 1, 12, 11, 10, 9]
        

        return (
            <div>
                <h1>DashBoard</h1>
                {this.props.AnnoAccademico != null &&
                    this.props.modelList != null &&
                    this.props.modelList['Pagamento'] != null &&
                    this.props.modelList['Pagamento'].length > 0
                    ? month.map((mm) => {

                        console.log('出力月mm',mm)

                        var sum = {};

                        //2021-2022
                        var anno_start = this.props.AnnoAccademico.substr(0, 4);
                        console.log('anno_start', anno_start)



                        var a = 0;
                        if (mm == 9 || mm == 10 || mm == 11 || mm == 12) {
                            a = anno_start;
                        } else {
                            a = Number(anno_start) + 1;
                        }

                        var minDate = new Date(a,mm -1  ,1);
                      
                        var maxDate = new Date(minDate.getTime());
                        maxDate.setMonth(minDate.getMonth() + 1)
                        maxDate.setMilliseconds(maxDate.getMilliseconds() - 1)
                        
                        console.log('minDate', minDate)
                        console.log('maxDate', maxDate)

                        this.props.modelList['Pagamento'].map((pagamento, index) => {


                            var payDate = new Date(pagamento.DataDiPagamento)

                       

                            if (minDate.getTime() <= payDate.getTime() &&
                                payDate.getTime() <= maxDate.getTime()) {

                                if (sum[pagamento['MetodoDiPagamento']] == null) {

                                    var obj = {}
                                    obj[pagamento['Category']] = pagamento['Importo']
                                    sum[pagamento['MetodoDiPagamento']] = obj

                                } else {

                                    if (sum[pagamento['MetodoDiPagamento']][pagamento['Category']] == null) {
                                        sum[pagamento['MetodoDiPagamento']][pagamento['Category']] = pagamento['Importo']
                                    } else {
                                        var currentSum = sum[pagamento['MetodoDiPagamento']][pagamento['Category']]
                                        sum[pagamento['MetodoDiPagamento']][pagamento['Category']] = currentSum + pagamento['Importo']
                                    }
                                }


                            }


                            //if (index == this.props.modelList['Pagamento'].length - 1) {



                            //}




                        }




                        )

                        const rows = Object.keys(sum)

                        var cols = [];
                        rows.forEach((rowName) => {
                            Object.keys(sum[rowName]).forEach((key) => {
                                if (cols.indexOf(key) == -1) {
                                    cols.push(key)
                                }
                            })
                        })

                        console.log(cols)

                        var month = '';

                        switch (mm) {
                            case 1:
                                month = 'Gennaio';
                                break;
                            case 2:
                                month = 'Febbraio';
                                break;
                            case 3:
                                month = 'Marzo';
                                break;
                            case 4:
                                month = 'Aprile';
                                break;
                            case 5:
                                month = 'Maggio';
                                break;
                            case 6:
                                month = 'Giugno';
                                break;
                            case 7:
                                month = 'Luglio';
                                break;
                            case 8:
                                month = 'Agosto';
                                break;
                            case 9:
                                month = 'Settembre';
                                break;
                            case 10:
                                month = 'Ottobre';
                                break;
                            case 11:
                                month = 'Novembre';
                                break;
                            case 12:
                                month = 'Dicembre';
                                break;

                        }



                        if (cols.length > 0) {
                            return (
                                <div key={mm}>
                                    <br />
                                    <TableContainer component={Paper} key={month}>
                                        <h3> Somma Entrate {month} </h3>
                                        <Table size="small"  >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell></TableCell>
                                                    {cols.map((col) => {
                                                        return <TableCell key={col}>{col}</TableCell>
                                                    })}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rows.map((row) => (
                                                    <TableRow key={row}>
                                                        <TableCell component="th" scope="row">
                                                            {row}
                                                        </TableCell>
                                                        {cols.map((col) => {
                                                            return <TableCell key={col}>{sum[row][col] == null ? '0€' : sum[row][col] + '€'}</TableCell>
                                                        })}

                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                        <Divider />
                                    </TableContainer>
                                </div>
                            )
                        }





                        /*

                        this.props.modelList['Pagamento'].map((pagamento, index) => {

                            console.log(index)

                            if (sum[pagamento['MetodoDiPagamento']] == null) {

                                var obj = {}
                                obj[pagamento['Category']] = pagamento['Importo']
                                sum[pagamento['MetodoDiPagamento']] = obj

                            } else {

                                if (sum[pagamento['MetodoDiPagamento']][pagamento['Category']] == null) {
                                    sum[pagamento['MetodoDiPagamento']][pagamento['Category']] = pagamento['Importo']
                                } else {
                                    var currentSum = sum[pagamento['MetodoDiPagamento']][pagamento['Category']]
                                    sum[pagamento['MetodoDiPagamento']][pagamento['Category']] = currentSum + pagamento['Importo']
                                }
                            }

                            console.log(sum)


                            if (index == this.props.modelList['Pagamento'].length - 1) {

                                const rows = Object.keys(sum)

                                var cols = [];
                                rows.forEach((rowName) => {
                                    Object.keys(sum[rowName]).forEach((key) => {
                                        if (cols.indexOf(key) == -1) {
                                            cols.push(key)
                                        }
                                    })
                                })

                                return (
                                    <TableContainer component={Paper}>
                                        <h3>Somma Entrate</h3>
                                        <Table size="small" >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell></TableCell>
                                                    {cols.map((col) => {
                                                        return <TableCell>{col}</TableCell>
                                                    })}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rows.map((row) => (
                                                    <TableRow key={row.name}>
                                                        <TableCell component="th" scope="row">
                                                            {row}
                                                        </TableCell>
                                                        {cols.map((col) => {
                                                            return <TableCell>{sum[row][col] == null ? '0€' : sum[row][col] + '€'}</TableCell>
                                                        })}

                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )

                            }


                        }

                       */

                    }
                    )


                    : null

                }
            </div>

        );
    }
}


const mapStateToProps = (state) => {
    return {

        modelList: state.model,
        socket: state.webSocket.socket,
        logininfo: state.auth.logininfo,
        AnnoAccademico: state.model.AnnoAccademico,

    }

}

const mapDispatchToProps = (dispatch) => {

    return {
        setupWebSocket: (access_token) => {
            dispatch(WebSocketAction.setupWebSocket(access_token))
        },
        getModelList: (modelName, arrInclude, access_token) => {
            dispatch(ModelAction.apiGetALL(modelName, arrInclude, access_token))
        }


    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Dashboard));